<template>
	<section id="join-us">
		<TopBanner>
			<img src="../../assets/join-us/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/join-us/title.png" class="page-title" width="361">
		<section1 id="section1" />
		<section2 id="section2" />
		<section3 id="section3" />
		<section4 id="section4" />
	</section>
</template>

<script>
import section1 from "../../components/join-us/section1.vue";
import section2 from "../../components/join-us/section2.vue";
import section3 from "../../components/join-us/section3.vue";
import section4 from "../../components/join-us/section4.vue";
export default {
	components: {
		section1,
		section2,
		section3,
		section4,
	},
	data() {
		return {
			dialogVisible: false,
		}
	}
}
</script>