<template>
	<section>
		<el-card v-loading="loading">
			<template slot="header"><b>故事類別</b></template>
			<table>
				<colgroup>
					<col>
					<col style="width:100%;">
				</colgroup>
				<tbody v-for="(arr, key, i) in ruleForm" :key="key + i">
					<template v-if="!arr.length">
						<tr>
							<th rowspan="2">{{key | mapCategoryLabel}}</th>
						</tr>
					</template>
					<template v-for="(category, j) in arr">
						<tr :key="category">
							<th v-if="!j" :rowspan="arr.length + 1">{{key | mapCategoryLabel}}</th>
							<td>
								<el-button type="text" @click="handleUpdateCategory(key, category)">{{category}}</el-button>
							</td>
							<td>
								<el-button icon="el-icon-delete" circle type="danger" size="mini" @click="handleRemoveCategory(key, category)" />
							</td>
						</tr>
					</template>
					<tr>
						<th colspan="2">
							<div class="center">
								<el-button icon="el-icon-plus" circle type="success" size="mini" @click="handleAddCategory(key);" />
							</div>
						</th>
					</tr>
				</tbody>
			</table>
		</el-card>
		<br>
		<div class="center">
			<el-button type="info" @click="handleReset()" :disabled="!statusChanged" :loading="loading">回 復</el-button>
			<el-button type="primary" @click="handleSubmit()" :disabled="!statusChanged" :loading="loading">儲 存</el-button>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import preventLeaveMixin from "../../mixin/prevent-leave";
import Categories from "../../database/models/categories";
export default {
	mixins: [preventLeaveMixin],
	data() {
		return {
			ruleForm: new Categories,
			loading: true,
		}
	},
	computed: {
		...mapState("category", [
			"_categories",
		]),
	},
	filters: {
		mapCategoryLabel(val) {
			return {
				people: "社會進步（People）",
				prosperity: "地方共融（Prosperity）",
				planet: "環境保衛（Planet）",
			}[val] || val;
		}
	},
	methods: {
		...mapActions("category", [
			"_watchCategories",
			"_updateCategories",
		]),
		async handleSubmit() {
			try {
				this.loading = true;
				await this._updateCategories(this.ruleForm);
				this.statusChanged = false;
				this.$message.success("success");
			} catch (error) {
				this.$message.error(error && error.message || "error");
			}
			this.loading = false;
		},
		handleReset() {
			this.ruleForm = this._categories.clone();
			this.statusChanged = false;
		},
		handleAddCategory(key) {
			this.$prompt("請輸入類別名稱", "新增", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "info"
			}).then(({ value }) => {
				if (value) {
					this.ruleForm.addCategory(key, value);
					this.statusChanged = true;
				} else throw new Error("請輸入文字")
			}).catch(this.handleError);
		},
		handleRemoveCategory(key, value) {
			this.$confirm(`確定刪除「${value}」？`, "刪除", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				if (value) this.ruleForm.removeCategory(key, value);
				this.statusChanged = true;
			}).catch(this.handleError);
		},
		handleUpdateCategory(key, inputValue) {
			this.$prompt(`請輸入類別名稱`, "修改", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "info",
				inputValue
			}).then(({ value }) => {
				if (value) {
					if (key) this.ruleForm.updateCategory(key, inputValue, value);
					this.statusChanged = true;
				}
			}).catch(this.handleError);
		},
		handleError(error) {
			if (typeof error === "object") this.$message({
				message: error.message,
				type: "error",
			});
			console.log(error);
		},
	},
	async created() {
		await this._watchCategories();
		this.ruleForm = this._categories.clone();
		this.loading = false;
	},
}
</script>

<style lang="scss" scoped>
th {
	white-space: nowrap;
	text-align: left;
}
tbody:nth-child(even) {
	background: #f5f7fa;
}
th,
td {
	padding: 5px;
	border: 1px solid #ebeef5;
}
.el-button--text {
	padding: 0;
	font-size: inherit;
}
</style>