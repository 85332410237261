<template>
	<section id="dfc-challenge" bg-color="white2">
		<TopBanner>
			<img src="../../assets/dfc-challenge/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/dfc-challenge/title.png" class="page-title" width="450">

		<section1 id="section1" />
		<section2 id="section2" />
		<section3 id="section3" />
		<section4 id="section4" />
		<section5 id="section5" />
		<section6 id="section6" />
		<section7 id="section7" />
		<section8 id="section8" />
		<section9 id="section9" />

	</section>
</template>

<script>
import section1 from "../../components/dfc-challenge/section1.vue";
import section2 from "../../components/dfc-challenge/section2.vue";
import section3 from "../../components/dfc-challenge/section3.vue";
import section4 from "../../components/dfc-challenge/section4.vue";
import section5 from "../../components/dfc-challenge/section5.vue";
import section6 from '../../components/dfc-challenge/section6.vue';
import section7 from "../../components/dfc-challenge/section7.vue";
import section8 from "../../components/dfc-challenge/section8.vue";
import section9 from "../../components/dfc-challenge/section9.vue";

export default {
	components: {
		section1,
		section2,
		section3,
		section4,
		section5,
		section6,
		section7,
		section8,
		section9,
	},
}
</script>