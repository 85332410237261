<template>
	<div class="tabs">
		<div class="row-grid" align-x="center" gutter-x="10" gutter-y="10">
			<div v-for="(item, i) in list" class="center rwd-m-3 rwd-s-6" :key="i">
				<DfcButton :theme="activeColor" :plain="currentIndex != i" :key="i" round @click="handleClick(i)">{{item.title}}</DfcButton>
			</div>
		</div>
		<hr>
		<transition name="el-fade-in">
			<p v-show="show">{{list[currentIndex].text}}</p>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		list: Array,
		activeColor: String
	},
	data() {
		return {
			show: true,
			currentIndex: 0
		}
	},
	methods: {
		handleClick(i) {
			if (this.currentIndex != i) this.show = false;
			setTimeout(() => {
				this.currentIndex = i;
				this.show = true;
			}, 200);
		}
	}
}
</script>

<style lang="scss" scoped>
hr {
	margin: 1.5em 0;
}
.dfc-button {
	border: none !important;
	background: transparent;
}
</style>