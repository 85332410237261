<template>
	<ContentBlock clip style="background:#f7b159;">
		<h1 class="center">歷屆回饋/學校亮點</h1>
		<br />
		<br />
		<Siema
			ref="siema"
			:data="list"
			:options="{ loop: true }"
			indicator
			:control="$root.rwd > 1"
		>
			<template v-slot="{ slide }">
				<Card :data="slide" />
			</template>
			<template #prev>
				<button class="arrow left" @click="prev()" />
			</template>
			<template #next>
				<button class="arrow right" @click="next()" />
			</template>
		</Siema>
	</ContentBlock>
</template>

<script>
import Siema from "../Siema.vue";
import Card from "./Card.vue";
export default {
	components: {
		Card,
		Siema,
	},
	data() {
		return {
			list: [
				{
					img: require("../../assets/dfc-school/圖3_桃園市霄裡國小.jpg"),
					header:
						"桃園市立霄裡國小（第一屆）\n共備與陪伴期程：2019 年 3月至 2020 年 3 月",
					description:
						"「何其有幸，霄裡國小能成為第一所與協會透過合作共備的模式，完成 12 年國教校訂課程的學校。由衷感謝執行長芯瑋與長寧總監，提供專業的課程諮詢與經驗傳承，讓學校的老師能在課程發展社群中，交織出完美的「霄裡 DFC 探究課程」。十年樹木，百年樹人，感謝協助協助我們建構可長可久校訂課程，讓孩子具備改變世界的力量與勇氣，能有自信的說：YES, I CAN!」",
					who: "桃園市霄裡國小 李士豪主任",
					links: [
						{
							text: "NHK World 日本放送協會報導 - Changing Education 改變教育",
							url: "https://youtu.be/phs_I5Z7IRo",
						},
						{
							text:
								"從桃園霄裡農村走向國際 中原設院USR與霄裡國小成果展現永續關懷精神",
							url:
								"https://tw.news.yahoo.com/從桃園霄裡農村走向國際-中原設院usr成果展現永續關懷精神-035700808.html",
						},
						{
							text: "霄裡國小玉山圖書館啟用，用愛心推動閱讀教育",
							url:
								"https://news.tycg.gov.tw/home.jsp?id=2&parentpath=0&mcustomize=news_view.jsp&dataserno=201911290005&aplistdn=ou=news,ou=chinese,ou=ap_root,o=tycg,c=tw&toolsflag=Y",
						},
						{
							text: "霄裡國小70週年校慶 打造生態雙語亮點學校",
							url:
								"https://www.taiwanhot.net/news/916954/%E9%9C%84%E8%A3%A1%E5%9C%8B%E5%B0%8F70%E9%80%B1%E5%B9%B4%E6%A0%A1%E6%85%B6+%E6%89%93%E9%80%A0%E7%94%9F%E6%85%8B%E9%9B%99%E8%AA%9E%E4%BA%AE%E9%BB%9E%E5%AD%B8%E6%A0%A1",
						},
						{
							text: "霄裡國小啟用水循環縮時攝影 成為桃園「最水學校」",
							url: "https://news.ltn.com.tw/news/Taoyuan/breakingnews/3665317",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖4_花蓮縣國風國中.jpg"),
					header:
						"花蓮縣國風國中（第一屆）\n共備與陪伴期程：2019 年 8 月至 2020 年 8 月",
					description:
						"「新課程的研擬，的確是讓老師們充滿忐忑。但經由協會講師群的經驗分享，啟發我們找到和孩子們可以一起成長的教學方向。蘇格拉底曾說：『每個人身上都有太陽，主要是如何讓它發光。』感謝 DFC 的陪伴，閃亮我們的熱情！」",
					who: "花蓮縣國風國中 周穎達教務主任",
					links: [
						{
							text: "創新教學融入師生共學 花蓮國風國中獲KDP認證標竿獎",
							url: "https://news.ltn.com.tw/news/life/breakingnews/3339006",
						},
						{
							text: "花蓮縣小論文競賽 國風國中勇奪雙金",
							url:
								"https://www.facebook.com/154448378012170/posts/2154374708019517/",
						},
						{
							text: "國風國中科探有成 將代表花蓮參加全國科展",
							url: "https://www.ner.gov.tw/news/60910fddb67f6000070be0d8/",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖5_桃園市文化國小.jpg"),
					header:
						"桃園縣文化國小（第二屆）\n共備與陪伴期程：2020 年 10  月至 2022 年 4 月",
					description:
						"「你們有沒有相信一門課，孩子們會醒過來、活過來，然後他會想用他的力量去改變世界。我發現燃起了孩子們的學習動機，點亮了他們的眼睛之後．他們學習動能會無限，一直一直的延伸。我覺得這樣就是21世紀老師，最專屬的幸福，看到孩子的眼睛亮起來，不段地學習。我們老師可能可以從說書人，變成聽孩子說自己故事的人。」",
					who: "桃園縣文化國小 羅淑華主任",
					links: [
						{
							text: "跟著NER有聲閱讀趣 桃園文化國小用閱讀培養自主學習力",
							url:
								"https://tw.news.yahoo.com/%E8%B7%9F%E8%91%97ner%E6%9C%89%E8%81%B2%E9%96%B1%E8%AE%80%E8%B6%A3-%E6%A1%83%E5%9C%92%E6%96%87%E5%8C%96%E5%9C%8B%E5%B0%8F%E7%94%A8%E9%96%B1%E8%AE%80%E5%9F%B9%E9%A4%8A%E8%87%AA%E4%B8%BB%E5%AD%B8%E7%BF%92%E5%8A%9B-080231443.html",
						},
						{
							text: "「尋找一份綠色的愛」桃園平鎮區文化國小很藝術！",
							url: "https://nextedu.biz/article/616",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖6_高雄市大灣國中.jpg"),
					header:
						"高雄市大灣國中（第二屆）\n共備與陪伴期程：2020 年 9 月至 2021 年 6 月",
					description:
						"「我們的樣子決定孩子的樣子，如何把自己的課程改變，我覺得那是一個關鍵，誰說老師上課不可以充滿互動，所以開啟話題，是現在老師一個很顧重要的概念，如何當一個可以開啟話題的老師，讓孩子願意跟暢心地分享，開始引導他去思考，順應這個潮流，讓孩子開始有感覺。」",
					who: "高雄市大灣國中 沈宥廷主任",
					links: [
						{
							text: "大灣國中2020獲選教育部PISA創造性思維研究基地學校",
							url: "https://class.kh.edu.tw/16097/bulletin/msg_view/6",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖7_宜蘭縣東澳國小.jpg"),
					header: "宜蘭縣東澳國小 / 第三屆",
					description:
						"在教育現場中最珍貴的就是「讓孩子相信自己能做到的比想像中還多」，DFC 課程對於孩子最大的影響是，他們天馬行空的創意有地方發揮，同時，也透過老師細緻的引導及搭建鷹架來收斂孩子的想法，孩子會看見自己的創意是有用的、能夠回應身邊問題的，因此讓他們更有自信、更願意表達、更願意展開後續行動。而對於老師來說，也收穫到對孩子更多面向的認識，更是在一起探究、一起行動的過程中，成為彼此學習的好夥伴。",
					who: "第三屆 DFC 種子學校 東澳國小 共備教師群",
					links: [
						{
							text: "東澳國小實驗教育微電影",
							url: "https://www.youtube.com/watch?v=d7GbwXvX4r8",
						},
						{
							text: "宜蘭東澳國小觀議課紀錄",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-宜蘭東澳國小觀議課紀錄-第三屆種子學校計畫-1b59985ad421",
						},
					],
					others: [
						"DFC 學習法課程成為校訂課程",
						"配合藝師入校的彈性課程，結合傳統編織課，全年級執行 DFC 課程",
						"完成低中高年級的校園制服設計原型",
					],
				},
				{
					img: require("../../assets/dfc-school/圖8_竹北市竹北國小.jpg"),
					header: "竹北市竹北國小/第三屆(進行中)",
					subtitle:
						"以「全球議題，在地行動」為校訂課程，引領學生發揮創意落實行動。",
					description:
						"新竹縣竹北市竹北國小，以「{{全球議題，在地行動}}」為題發展校訂課程，希望以 SDGs 議題為媒介，透過 DFC 學習法的課程設計脈絡帶領老師{{以終為始的設計課程}}。透過「認識家鄉」議題，並不同年段以不同層次的課程設計，帶領孩子用不同角度出發來了解家鄉。此次的校訂課程中，高年級學生將用「設計桌遊」與「漂鳥活動」來探究竹北這個移居城市的美好。目前協會仍持續陪伴中，預計在112年完成校訂課程設計與實施。",
					who: "",
					links: [
						{
							text: "精彩回顧｜竹北國小入校共備紀錄（二）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-竹北國小共備入校共備紀錄-二-第三屆種子學校計畫-dc29c88dc9f0",
						},
						{
							text: "精彩回顧｜竹北國小入校共備紀錄（三）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-竹北國小入校共備紀錄-三-第三屆種子學校計畫-3dce30ff2583",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖9_桃園市西門國小.jpg"),
					header: "桃園市西門國小/第四屆",
					subtitle:
						"透過 DFC 學習法探究校園內有感議題_培養學生同理心、創造力與表達能力",
					description:
						"西門國小位處在繁華熱鬧、交通四通八達的桃園市區，周邊有許多重要的文化資源，為一所文化社區學校。從 111 學年度起，學校將以 DFC 學習法發展校訂課程，並同時結合校內外資源，發展西門的特色課程。參與的教師以學生有感議題為始，帶領學生操作，期望透過 DFC 學習法 ，學生能有自信表達能力、具有同理心，並能夠{{學會主動覺察，以創造力解決問題}}。目前協會仍持續陪伴該校中，預計在 112 年完成校訂課程設計與實施。",
					who: "",
					links: [
						{
							text: "精彩回顧｜桃園西門國小入校共備紀錄（一）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-桃園西門國小入校共備紀錄-第四屆種子學校計畫-一-10f0dbec7ddd",
						},
						{
							text: "精彩回顧｜桃園西門國小入校共備紀錄（二）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-桃園西門國小入校共備紀錄-第四屆種子學校計畫-二-385a30c9902f",
						},
						{
							text: "精彩回顧｜桃園西門國小入校共備紀錄（三）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-桃園西門國小入校共備紀錄-第四屆種子學校計畫-三-88003f63ca27",
						},
					],
				},
				{
					img: require("../../assets/dfc-school/圖10_桃園市內壢國中.jpg"),
					header: "桃園市內壢國中/第四屆",
					subtitle:
						"DFC 學習法結合閱讀推動課程_培養學生主動積極，強化閱讀體驗，對多元文化之同理與共學",
					description:
						"桃園市內壢國中願景為「健康、希望、快樂」，提倡品格力、適性揚才、智慧校園、綠色永續為目標。深耕閱讀為其特色教學之一，本次共備以結合其閱讀課程為基礎，發展 DFC 學習法的校訂課程，並結合該校重點{{「多元文化」閱讀課程}}，透過 DFC 學習法，將其{{書本閱讀內容化為實際的行動}}，累積孩子體驗多元文化的經驗，將所學運用在現實情境中，培養孩子們主動積極互動交流，了解多元文化在內壢的發展。目前協會仍持續陪伴該校中，預計在 112 年完成校訂課程設計與實施。",
					who: "",
					links: [
						{
							text: "精彩回顧｜內壢國中入校共備紀錄（一）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-內壢國中入校共備紀錄-一-第四屆種子學校計畫-c7f883b74b1",
						},
						{
							text: "精彩回顧｜內壢國中入校共備紀錄（二）",
							url:
								"https://medium.com/design-for-change-taiwan/精彩回顧-桃園內壢國中入校共備紀錄-二-第四屆種子學校計畫-41744ff09c4d",
						},
					],
				},
			],
		};
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
};
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	--var-indicator-color: black;
	.right,
	.left {
		top: 10% !important;
	}
}
</style>
