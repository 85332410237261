<template></template>

<script>
export default {
	props: {
		data: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			top: "",	// fixed position
		}
	},
	computed: {
		fixed() {
			return this.top ? true : false;
		},
	},
	mounted() {
		// 向下滾動後，按鈕 fixed 在上方
		this.$nextTick(() => {
			const el = this.$el;
			let menuPositionOnEnterPage = null;	// 剛進入頁面時，menu最一開始的位置
			let originalTop;	// 動態 menu 的原始位置
			let top;	// el頂部與滾動視窗頂部的距離
			let bottom;	// el底部與滾動視窗頂部的距離
			window.addEventListener("scroll", e => {
				const { scrollY } = window;
				const fixedMenuHeight = document.getElementById("fixed-menu").offsetHeight;	// 頂部 menu 的高度
				top = Math.floor(el.getBoundingClientRect().top);
				bottom = Math.floor(el.getBoundingClientRect().bottom);
				if (menuPositionOnEnterPage === null) menuPositionOnEnterPage = top;
				if (!originalTop) {
					// 當進入頁面時 menu 是在頁面頂部的「上方」，並往「下滑」經過 sticky-menu 時
					// 或
					// 當進入頁面時 menu 是在頁面頂部的「下方」，並往「上滑」經過 sticky-menu 時
					if ((menuPositionOnEnterPage >= 0 && top <= fixedMenuHeight) || (menuPositionOnEnterPage < 0 && top >= fixedMenuHeight)) {
						originalTop = scrollY;
					}
				}
				if (!this.top && bottom <= fixedMenuHeight) {
					this.top = `${fixedMenuHeight}px`;
				} else if (this.top && originalTop > scrollY) {
					this.top = "";
				}
			});
		});
	}
}
</script>

<style lang="scss">
[class*="sticky-menu"] {
	width: 100%;
	font-weight: 500;

	.anchor {
		color: black;
		border: 0.5px solid #dc3d3c;
		border-radius: 0;
		transition: background 0.3s;
		text-align: center;
		padding: 0.5em 1em;
		font-size: inherit;
		line-height: 1.2;

		@mixin active {
			background: $red;
			color: white;
		}
		&.active {
			@include active;
		}
		@media (hover: hover) {
			&:hover {
				@include active;
			}
		}
	}

	.static {
		max-width: 1500px;
		margin: auto;
	}

	.sticky {
		position: fixed;
		background: #fff;
		box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.3);
		width: 100%;
		top: 0;
		left: 0;

		.anchor {
			flex: 1;
		}
	}

	&:not(.fixed) {
		.sticky {
			transition: 0.5s, top 0s 0.5s;
			transform: translateY(-100%);
			z-index: 98;
			opacity: 0;
		}
	}

	&.fixed {
		.sticky {
			transition: 0.5s, top 0s;
			animation: slide-in-z-index 0.5s both;
			transform: translateY(0);
			opacity: 1;
		}
	}
}

@keyframes slide-in-z-index {
	0% {
		z-index: 98;
	}
	99% {
		z-index: 98;
	}
	100% {
		z-index: 100;
	}
}
</style>