<template>
	<div class="timeline__node">
		<p v-if="data.redTitle" style="color:#f46852;margin-bottom: 0;">{{data.redTitle}}</p>
		<pre>{{data.title1}}</pre>
		<div v-if="data.marks" class="mark-box">
			<span v-for="mark in data.marks" class="mark">{{ mark }}</span>
		</div>
		<!-- <pre>{{data.title2}}</pre> -->
		<p>
			<small>
				<pre v-html="data.content"></pre>
			</small>
		</p>
	</div>
</template>

<script>
export default {
	props: ["data"],
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped>
.timeline__node {
	// border: 1px solid #f46852;
	padding: 7% 3%;
	font-size: 1.1em;

	h1,
	p {
		margin-top: 0;
	}
}
.mark-box{
	display: flex;
	flex-wrap: wrap;
}
.mark{
	background-color: #F46852;
	color: #fff;
	border-radius: 50px;
	padding: 2px 10px;
	font-size: smaller;
	margin: 2.5px 5px 2.5px 0;
}
@media screen and (min-width: 768px) {
	.mark-box{
		margin: 5px 0;
		display: block;
	}
	.mark{
		margin: 0 5px 0 0;
	}
	.mark:last-child{
		margin: 0;
	}
}
</style>
