<template>
	<section id="copyright">
		<div id="banner" bg-color="white1">
			<TopBanner>
				<img src="../../assets/copyright/banner.jpg" width="100%">
			</TopBanner>
			<img src="../../assets/copyright/title.png" class="page-title" width="600">
			<br>
			<br>
			<StickyMenu :data="list">
				<div slot-scope="{item, scroll}" class="rwd-m-3 rwd-s-4" style="padding:1em;">
					<a :href="`#${item.target.id}`" :class="{ col: $root.rwd < 3, row: $root.rwd >= 3, active: item.active }" :align-x="$root.rwd < 3 ? 'center' : ''" align-y="center" @click.prevent="scroll(item.target)">
						<img :src="item.icon">
						<span>{{item.text}}</span>
					</a>
				</div>
			</StickyMenu>
			<br>
			<br>
		</div>
		<div id="banner-clip-border" bg-color="white1" />

		<ContentBlock id="1">
			<h1>DFC 標誌授權方法</h1>
			<p>社團法人臺灣童心創意行動協會、種子師「陪」計畫、Design for Change 及 Design for Change, Taiwan 的所有標示、徽標、網頁、截圖或其它專用標誌均受適用的商標法、版權法和其它智慧財產權法的保護。如果您要在您的網站、廣告、文章或書籍中使用任何上述標誌，或要在任何其它地方顯示這些標誌，都必須首先獲得社團法人臺灣童心創意行動協會（DFC 臺灣）的授權。若有任何需求，歡迎您<el-button type="text" @click="dialogVisible = true">聯絡我們</el-button>。
				<br>本聲明資料參考來源：1.DFC WORLD 2.<el-link href="http://creativecommons.tw/explore" target="_blank" type="primary">臺灣創用 CC 計畫</el-link>
			</p>
		</ContentBlock>

		<el-dialog :visible="dialogVisible" :width="$root.rwd < 2 ? '600px' : '100%'" :show-close="false" center destroy-on-close>
			<template slot="title">
				<img src="../../assets/icon-lens.svg" width="100" style="margin:auto;">
				<h1>聯絡我們</h1>
				<p style="margin:0;">我們很樂意回答您的問題，歡迎您填妥表格，我們將盡速回覆您， 或電洽、 email 給我們，將會有專人為您服務。</p>
			</template>
			<contact-us-form @cancel="dialogVisible = false" @done="dialogVisible = false" />
		</el-dialog>

		<ContentBlock id="2" bg-color="white1" clip>
			<h1>全球孩童創意行動挑戰歷屆作品（故事）授權方式</h1>
			<p>所有參與社團法人臺灣童心創意行動協會（後稱乙方）舉辦之「全球孩童創意行動挑戰」（Design For Change Challenge, 後簡稱 DFC 挑戰）之參與者，其完成提交之故事成果與相關素材，均代表本人及其法定代理人（後稱甲方）同意以下事項：
			<ol>
				<li>甲方參加 DFC 挑戰中繳交之有關語文、圖形、攝影、視聽、錄音，及其他得受著作權利保護之作品， 同意以非專屬 (non-exclusive)、不限制目的 (application to any purpose)、容許再授權 (sublicensable)，不可撤回 (irrevocable) 之方式，授權乙方使用，以擴大活動成果公眾應用效益之範圍。</li>
				<li>授權標的：本同意書述及之授權標的，係指甲方於 DFC 挑戰所產生，並提供予乙方之各類素材中得受著作權利保護之客體，倘提交資料中包含純粹紀錄性與數據性之資訊，雖非授權標的範圍所及，然因不受著作權利保護，其後乙方及其他第三人亦得自由使用。</li>
				<li>本授權標的受中華民國著作權法及基於互惠原則生效之相關國際協議之保障，將以原作者或再授權人之名義，採供不特定多數人皆可合法近用之公眾授權方式釋出，例如創用 CC 授權條款 (Creative Commons License) 之各項組合，提供予公眾使用。</li>
				<li>本授權為非專屬授權，甲方對上述之授權標的仍擁有相關法律上可主張之合法權利，所涉標的嗣後仍可依甲方之安排自行向外發布，或進行其他授權方式之運用；乙方依此契約取得之再授權地位，後續仍得以容許再授權方式提供授權予其他第三人，而若乙方以再授權方式提供相關授權標的之利用，此再授權標的應以適當方式，清楚註明原始貢獻來源為甲方，以表彰甲方於開放素材予公眾使用上的付出與貢獻。</li>
				<li>如未涉及著作人格之污衊，甲方同意不向乙方及其後續授權對象，行使著作人格權。</li>
			</ol>
			<h2>法律文字說明</h2>
			<ul>
				<li>非專屬授權 (non-exclusive license)
					<br>從字義上來了解「專屬／非專屬」授權的關係時，可以合理的與「獨佔／非獨佔」這兩個字詞做一定程度的連結，專屬授權屬於一種封閉、排外的獨佔關係，針對特定人或是特定背景做出授權行為，並排斥其他「非被授權人」使用該作品，但非專屬授權則可向不特定多數人進行授權動作，類似非獨占的概念，故著作權利人在過往的授權關係中，雖已約定將特定作品在一定期間內將一部份的權利以非專屬的方式授權給對方，但在其後的授權關係中，仍然可以將該等權利另外授權給他人進行使用。
				</li>
				<li>不限制目的 (application to any purpose)
					<br>當代的公眾授權模式，皆以不限制任何使用目的之方式將資料與素材釋出，以求資料與素材提供後，能產生最大的二次創作效果。並依著作權法第三十七條第一項之規定，著作授權利用之內容、利用方法，依當事人之約定；其約定不明之部分，推定為未授權，故此處明訂不限制目的之授權內容，以避免爭議。另，不限制使用目的，並不意指使用者得進行任何違法目的之使用，各國法律條款優先於行政命令與契約之適用，故不限制目的之授權方式，並不會造成鼓勵違法使用的結果。
				</li>
				<li>容許再授權 (sublicensable)
					<br>被授權人將自己本身為原授權人允許的權利，依著作權法第三十七條第一項規定再行向下授權，其為一種「附屬性、次級性」的授權，故被授權人能夠授與後手的授權範圍，不得逾越原始權利人提供給他的範圍。
				</li>
				<li>不可撤回 (irrevocable)
					<br>代表此一公眾授權的態樣，無法由原始權利人在嗣後依己意逕予撤回。由於當代的公眾授權模式允許使用者可以自由地使用與散布該著作，因此若原權利人意圖撤回該作品原先的公眾授權狀態時，該作品可能已經被廣泛地散布到全球各地，所以其撤回所影響到的使用者範圍將是全球性的，此外許多使用者現實上，亦沒有客觀的方法能得知原作品的授權條件已被撤回，故當前多數的公眾授權條款，皆明訂此一授權狀態不可事後予以撤回；但若原授權關係為非專屬授權的模式時，當原始權利人有意更改其作品後續的授權模式，則可以將作品改以新版的授權方式釋出，此時新版作品即能改用新的授權方式，而原始舊版作品則延續舊版釋出時的授權方式。
				</li>
			</ul>
		</ContentBlock>

		<ContentBlock id="3">
			<h1>DFC 挑戰中所有使用內容須符合</h1>
			<h2>創用CC之姓名標示-非商業性-相同方式分享</h2>
			<img src="../../assets/copyright/icon-cc.png" width="120">
			<br>
			<p>本授權條款允許使用者重製、散布、傳輸以及修改著作，但不得為商業目的之使用。若使用者修改該著作時，僅得依本授權條款或與本授權條款類似者來散布該衍生作品。使用時必須按照著作人指定的方式表彰其姓名。
				<br>只要遵守授權條款規定，授權人不能撤回你使用本素材的自由。
				<br>惟需遵照下列條件：
				<br>・姓名標示 — 您必須按照著作人或授權人所指定的方式，表彰其姓名。
				<br>・非商業性表示 — 您不得因獲取商業利益或私人金錢報酬為主要目的來利用作品。
				<br>・相同方式分享 — 若您變更、變形或修改本著作，則僅能依同樣的授權條款來散布該衍生作品。
				<br>・不得增加額外限制 — 你不能增設法律條款或科技措施，來限制別人依授權條款本已許可的作為。
			</p>
			<h2>聲明</h2>
			<p>・當你使用本素材中屬於公眾領域的元素，或當法律有例外或限制條款允許你的使用，則你不需要遵守本授權條款。
				<br>・未提供保證。本授權條款未必能完全提供，你預期用途所需要的所有許可。例如：形象權，隱私權、著作人格權等其他權利，可能限制你如何使用本素材。
			</p>
			<p>以上轉自創用CC，你可以透過 <el-link href="http://creativecommons.tw/explore" target="_blank" type="primary">臺灣創用CC計畫</el-link> 或 <el-link href="https://ctld.ntu.edu.tw/fd/teaching_resource/page1-1_detail.php?bgid=4&gid=28&nid=517" target="_blank" type="primary">國立臺灣大學教學發展中心-數位教材著作權探討</el-link>：淺談創用 CC 授權機制，進一步了解創用 CC。</p>
		</ContentBlock>
	</section>
</template>

<script>
import StickyMenu from "../../components/StickyMenu.vue";
import ContactUsForm from "../../components/ContactUsForm.vue";
export default {
	components: {
		StickyMenu,
		ContactUsForm,
	},
	data() {
		return {
			dialogVisible: false,
			list: [
				{
					icon: require("@/assets/copyright/anchor1.png"),
					target: "#1",
					text: "我想使用 DFC 標誌"
				},
				{
					icon: require("@/assets/copyright/anchor2.png"),
					target: "#2",
					text: "我的故事如何授權給 DFC 臺灣"
				},
				{
					icon: require("@/assets/copyright/anchor3.png"),
					target: "#3",
					text: "我的故事內容應符合的創用 CC 規範"
				}
			],
		}
	},
}
</script>

<style lang="scss" scoped>
#copyright {
	line-height: 2;
	#banner-clip-border {
		padding-top: 3em;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1em));
		@media screen and (min-width: 768px) {
			clip-path: polygon(
				0 0,
				100% 0,
				100% calc(100% - 1.5em),
				60% 100%,
				20% calc(100% - 1.5em),
				0 100%
			);
		}
	}
	h1 {
		text-align: center;
	}
	h2 {
		@include left-line-title;
		color: $red;
	}
	li {
		margin-bottom: 2em;
	}
	.sticky-menu {
		background: #fff;

		a {
			width: 100%;
			height: 100%;
			line-height: 1.5;
			color: black;
			border: 1px solid #dc3d3c;
			padding: 1em;
			transition: background 0.3s;
			border-radius: 6px;
			text-align: center;

			@mixin active {
				background: $red;
				color: white;
			}
			&.active {
				@include active;
			}
			@media (hover: hover) {
				&:hover {
					@include active;
				}
			}

			img {
				width: 6em;
				margin: 0 0 0.5em;
			}

			@media screen and (min-width: 768px) {
				font-size: 1.2em;
			}

			@media screen and (max-width: 425px) {
				border-radius: 100px;
				padding: 0.5em 1em;

				img {
					margin: 0 1em;
					width: 2em;
				}
			}
		}
	}
}
</style>