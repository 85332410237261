<template>
	<ContentBlock>
		<h1 style="margin-top:0; text-align:center;">期程及參加資格</h1>
		<br>
		<el-card style="max-width:500px; margin:auto;">
			<h2 style="margin:0;" class="center">幼兒園至高中職學童</h2>
			<el-divider />
			<p class="center" style="font-size:1.2em; text-align:center; align-items:baseline;">2025年<b>03</b>月<b>14</b>日&nbsp;<b>15:00</b>&nbsp;收件截止</p>
			<p>資料審核通過的挑戰故事將於 2025 年 5 月收到&nbsp;<span>DFC 挑戰國際證書</span>及&nbsp;<span>DFC&nbsp;挑戰專屬紀念徽章</span>。</p>
			<br>
			<div class="center">
				<DfcButton class="rwd-s-8" theme="darkblue" round arrow href="https://www.dfcchallenge.org/" target="_blank">註冊/登入挑戰平台</DfcButton>
			</div>
			<br>
		</el-card>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					icon: require("@/assets/dfc-challenge/icon1.png"),
					title: "學習單",
					content: "以文字記錄 DFC 四步驟歷程\nDFC 四步驟照片上傳每個步驟一張最具代表的照片吧！建議完整表達主題，對焦清晰。",
				},
				{
					icon: require("@/assets/dfc-challenge/icon2.png"),
					title: "故事授權資料",
					content: "將你的故事授權給我們，我們會將你的故事分享給更多人知道，請填寫家長同意書及故事授權書。",
				},
				{
					icon: require("@/assets/dfc-challenge/icon3.png"),
					title: "國際證書-姓名羅馬拼音",
					content: "由於我們的國際 DFC 挑戰參與證書為國際授權核發，因此需要每位參與者的姓名羅馬拼音（與護照上相同為佳）",
				},
				{
					icon: require("@/assets/dfc-challenge/icon4.png"),
					title: "影片或投影片",
					content: "記錄你們解決問題的過程，並與我們分享，請提供檔案下載連結喔！",
				},
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.el-card {
	border-radius: 0 0 8px 8px;
	border: 1px solid $darkblue;
	color: $darkblue;
	flex: 1;
	margin: 1em;
	min-width: 250px;
	max-width: 100%;
	&::before {
		content: "";
		display: block;
		height: 0.625em;
		width: 100%;
		background: $darkblue;
	}
	b,
	span {
		color: $red;
	}
	b {
		font-size: 1.5em;
	}
}
</style>