<template>
	<table class="rwd-table" :class="{ fixed }">
		<template v-for="(row, i) in chunkedList">
			<tr :key="i">
				<template v-for="(col, j) in row">
					<td class="cell" :key="j">
						<slot :cell="col">{{col}}</slot>
					</td>
				</template>
			</tr>
		</template>
	</table>
</template>

<script>
import _ from "lodash";
export default {
	props: {
		data: {
			type: Array,
			required: true
		},
		columns: {
			type: Array,
			default: () => [3, 2, 1]
		},
		fixed: Boolean,
	},
	computed: {
		chunkedList() {
			const arr = _.cloneDeep(this.data);
			const chunk_size = Math.ceil(arr.length / this.columns[this.$root.rwd - 1]);
			return this.swapArray(arr.chunk(chunk_size));
		}
	},
	methods: {
		swapArray(arr = []) {
			return _.zip.apply(_, arr);
		}
	}
}
</script>

<style lang="scss" scoped>
.fixed {
	table-layout: fixed;
	width: 100%;
}
</style>