<template>
	<section id="home">
		<banner />
		<section1 id="section1" />
		<section2 id="section2" />
		<section3 id="section3" />
		<section4 id="section4" />
		<section5 id="section5" />
		<section6 id="section6" />
		<section7 id="section7" />
		<section8 id="section8" />
		<section9 id="section9" />
	</section>
</template>

<script>
import section1 from "../../components/home/section1.vue";
import section2 from "../../components/home/section2.vue";
import section3 from "../../components/home/section3.vue";
import section4 from "../../components/home/section4.vue";
import section5 from "../../components/home/section5.vue";
import section6 from "../../components/home/section6.vue";
import section7 from "../../components/home/section7.vue";
import section8 from "../../components/home/section8.vue";
import section9 from "../../components/home/section9.vue";
export default {
	components: {
		Banner: () => import("../../components/home/Banner.vue"),
		section1,
		section2,
		section3,
		section4,
		section5,
		section6,
		section7,
		section8,
		section9,
	}
}
</script>

<style lang="scss" scoped>
#home {
	@media screen and (max-width: 768px) {
		text-align: center;
	}
}
</style>