<template>
	<section>
		<h1 style="margin:1em; text-align:center;">最新消息</h1>
		<br>
		<div class="row" align-x="between">
			<el-button type="primary" :loading="loading" @click="dialogVisible = true">新增</el-button>
			<div class="row" align-y="bottom" gutter="10">
				<div class="row" align-y="center">
					<el-select v-model="monthPicked" placeholder="選擇月份">
						<el-option v-for="(option, i) in _monthPickerOptions" :key="i" :label="option" :value="option" />
					</el-select>
					<el-button @click="handleSearch()" type="primary" plain icon="el-icon-search">搜尋</el-button>
				</div>
				<el-button @click="handleSearchDraft()" type="info" plain size="mini" :disabled="!cursor">搜尋全部草稿</el-button>
			</div>
		</div>
		<br>
		<el-card>
			<el-table :data="_newsList" v-loading="loading" stripe>
				<el-table-column type="index" width="50" />
				<el-table-column label="標題" prop="title" />
				<el-table-column label="日期" prop="date">
					<template slot-scope="{row}">{{row.date | dateFilter}}</template>
				</el-table-column>
				<el-table-column label="上次更新" prop="update_time">
					<template slot-scope="{row}">{{row.update_time | datetimeFilter}}</template>
				</el-table-column>
				<el-table-column label="狀態" prop="published" align="center">
					<template slot-scope="{row}">
						<el-tag :type="row.published && 'success' || 'info'" size="small">{{row.published ? "發布" : "草稿"}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column width="70" fixed="right">
					<template slot-scope="{row}">
						<el-button @click="$router.push(`news/${row.id}`)" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</el-table-column>
				<el-table-column label="快速操作" width="120" fixed="right" align="center">
					<template slot-scope="{row}">
						<el-button @click="handleChangeStatus(row)" :type="row.published && 'info' || 'success'" size="mini" round>{{mapStatusText(row.published)}}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<el-dialog title="新增項目" :visible="dialogVisible" @close="cancelCreate()">
			<el-form ref="ruleForm" :model="ruleForm" label-position="left" label-width="5em">
				<el-form-item label="標題" prop="title" :rules="{ required:true, message:'標題' }">
					<el-input v-model="ruleForm.title" />
				</el-form-item>
				<el-form-item label="日期" prop="date" :rules="{ required:true, message:'請指定日期' }">
					<el-date-picker v-model="ruleForm.date" value-format="timestamp" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="cancelCreate()">取 消</el-button>
				<el-button type="primary" @click="handleCreate()" :loading="loading">確 定</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	data() {
		return {
			loading: true,
			monthPicked: "",
			dialogVisible: false,
			ruleForm: new Object,
			cursor: 1,
		}
	},
	computed: {
		...mapState("news", [
			"_monthPickerOptions",
			"_newsList",
		]),
	},
	methods: {
		...mapActions("news", [
			"_watchMonthPickerOptions",
			"_getListByMonth",
			"_getListByPublishedStatus",
			"_createData",
			"_changeStatue",
		]),
		async handleSearch() {
			if (this.monthPicked) {
				this.loading = true;
				await this._getListByMonth(this.monthPicked);
				this.cursor = 1;
				this.loading = false;
			} else {
				this.$message.error("請選擇時間")
			}
		},
		async handleSearchDraft() {
			this.loading = true;
			const { cursor } = await this._getListByPublishedStatus({
				status: false,
				cursor: this.cursor,
			});
			this.cursor = cursor;
			this.loading = false;
		},
		async handleCreate() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.loading = true;
					const id = await this._createData(this.ruleForm);
					this.$router.push("/manage/news/" + id);
					this.loading = false;
				}
			})
		},
		cancelCreate() {
			this.ruleForm = new Object;
			this.dialogVisible = false;
			this.$refs["ruleForm"].resetFields()
		},
		handleChangeStatus(data) {
			this.$confirm(`確定要將${data.title}「${this.mapStatusText(data.published)}」？`, { type: "warning" })
				.then(async () => {
					this.loading = true;
					try {
						await this._changeStatue({
							id: data.id,
							published: !data.published
						});
						data.published = !data.published;
					} catch (error) {
						this.$message.error(error.message);
					}
					this.loading = false;
				});
		},
		mapStatusText(val) {
			return val ? "存為草稿" : "發布";
		},
	},
	async created() {
		await this._watchMonthPickerOptions();
		if (this._monthPickerOptions.length) {
			this.monthPicked = this._monthPickerOptions[0];
			await this._getListByMonth(this.monthPicked);
		}
		this.loading = false;
	}
}
</script>