<template>
	<ContentBlock>
		<StickyMenu2 v-model="typeOptions" @change="handleChange()">
			<template slot-scope="{item, callback}">
				<div class="rwd-s-4 rwd-xs-6 center" style="padding: 0 10px;">
					<el-button @click="callback()" :class="{active: item.active}" round style="width:100%;">{{item.text}}</el-button>
				</div>
			</template>
		</StickyMenu2>
		<br>
		<br>
		<hr style="background:#282829; height:1px;">
		<br>
		<transition name="el-fade-in">
			<component :is="currentComponent" :key="currentComponent" />
		</transition>
	</ContentBlock>
</template>

<script>
import StickyMenu2 from "../StickyMenu2.vue";
import section2_1 from "./section2_1.vue";
import section2_2 from "./section2_2.vue";
export default {
	components: {
		StickyMenu2,
		section2_1,
		section2_2,
	},
	data() {
		return {
			currentComponent: "section2_1",
			typeOptions: [
				{
					component: "section2_1",
					text: "DFC 種子教師"
				},
				{
					component: "section2_2",
					text: "DFC 校園大使"
				}
			],
		}
	},
	watch: {
		currentComponent() {
			const fixedMenu = document.getElementById("fixed-menu");
			scrollTo({ top: this.$el.offsetTop - fixedMenu.offsetHeight });
		},
	},
	methods: {
		handleChange() {
			const item = this.typeOptions.find(({ active }) => active);
			this.currentComponent = item.component;
		}
	},
	created() {
		this.typeOptions.forEach(item => {
			this.$set(item, "active", item.component == this.currentComponent);
		})
	}
}
</script>

<style lang="scss" scoped>
.sticky-menu2 {
	.el-button {
		font-size: 1em;
		background: transparent;
		color: $red;
		border-color: $red;
		margin: 0;
		@mixin active {
			background: $red;
			color: white;
		}
		&.active {
			@include active;
		}
		@media (hover: hover) {
			&:hover {
				@include active;
			}
		}
	}
}
</style>