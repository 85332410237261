<template>
	<section id="media-center">
		<TopBanner>
			<img src="../../assets/media-center/banner.jpg">
		</TopBanner>
		<img src="../../assets/media-center/title.png" class="page-title" width="361">

		<section1 />

		<ContentBlock style="padding-top:0; margin-top:-3em; line-height:2;">
			<h1 class="center">採訪邀約聯繫</h1>
			<br>
			<div class="center">
				<DfcButton class="rwd-m-4 rwd-s-6" theme="yellow" round arrow href="https://form.jotform.me/82461826186463" target="_blank">採訪邀約</DfcButton>
			</div>
			<!-- <p>感謝各位媒體朋友對於 Design for Change, Taiwan 的關注，我們很樂意與您分享 DFC 的理念以及孩子們改變世界的故事，但是由於協會接收的採訪邀約漸漸變多，需要請您先協助我們填寫以下表單， 並提供我們採訪綱要，便於讓我們理解貴單位的採訪需求，我們會在上班時間儘快與您聯繫，若等候過久，也請您來電不吝提醒我們<el-link type="danger" href="tel:02-2368-1310">（02-2368-1310）</el-link>。
				<br>
				<br>同時，如果您對孩子的 DFC 故事有興趣的話，我們也可引介您採訪有參加 DFC 挑戰的老師和孩子們，他們才是真正的主角唷！
				<br>除突發新聞事件之外，讓我們邀請您於預計採訪日期前10～15個工作天提出採訪邀約，我們會更有充裕的時間調整受訪人力。
				<br>另外，我們也希望能夠於採訪露出後，麻煩貴單位郵寄一份成果，給我們留作紀錄（電子檔案、紙本資料等...）
				<br>
				<br>謝謝您 :)
			</p> -->
		</ContentBlock>
	</section>
</template>

<script>
import section1 from "../../components/media-center/section1.vue";
import DfcButton from "../../components/DfcButton.vue";
export default {
	components: {
    section1,
    DfcButton
},
}
</script>

<style lang="scss" scoped>
</style>