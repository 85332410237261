<template>
	<div class="upload" style="line-height:1.5;">
		<slot name="hint">
			<el-alert title="溫馨提醒" type="info" show-icon>如果欲取代原本項目，請確認「檔案名稱」一樣，這樣可以節省雲端空間唷！</el-alert>
		</slot>
		<br>
		<el-upload ref="el-upload" action="" :auto-upload="false" :file-list="fileList" :http-request="customHttpRequest" :before-upload="handleBeforeUpload" :on-success="handleSuccess" :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePreview" :accept="accept" :multiple="multiple" :limit="limit" :disabled="disabled" :list-type="listType" :drag="drag" :show-file-list="showFileList">
			<slot name="trigger" slot="trigger">
				<el-button size="small" type="info" :disabled="!selectable">選擇文件</el-button>
			</slot>
			<slot name="upload-trigger" :uploader="submitUpload">
				<el-button size="small" type="primary" @click="submitUpload" :disabled="!statusChanged" style="margin-left:10px;">上傳至雲端</el-button>
			</slot>
			<slot name="tip" slot="tip"></slot>
		</el-upload>
		<slot />
		<el-dialog :visible="dialogVisible" @close="dialogVisible = false" append-to-body>
			<img :src="previewUrl" width="100%">
		</el-dialog>
	</div>
</template>

<script>
import storage from "../database/modules/storage.js";
export default {
	props: {
		data: {
			type: Array,
			required: true
		},
		"media-folder": String,
		"size-limit": {
			type: Number,
			default: 5
		},
		multiple: Boolean,
		limit: Number,
		disabled: Boolean,
		accept: String,
		drag: Boolean,
		"list-type": String,
		"show-file-list": {
			type: Boolean,
			default: () => true
		},
	},
	model: {
		prop: "data",
		event: "change"
	},
	data() {
		return {
			previewUrl: "",
			dialogVisible: false,
			statusChanged: false,
		}
	},
	computed: {
		fileList: {
			get() {
				return this.data && Array.isArray(this.data) ? this.data : new Array;
			},
			set(val) {
				this.$emit("change", val);
			}
		},
		isAllComplete() {
			return this.fileList.every(({ status }) => status == "success");
		},
		selectable() {
			return this.limit && this.fileList.length < this.limit || true;
		}
	},
	methods: {
		customHttpRequest({ file, onError, onProgress, onSuccess }) {
			storage.uploadFile({
				path: `${this.mediaFolder}/${file.name}`,
				file,
				progress: percentage => {
					ProgressEvent.percent = percentage;
					onProgress(ProgressEvent);
				},
				error: onError,
				complete: onSuccess,
			});
		},
		handleBeforeUpload(file) {
			if (file.size / 1024 / 1024 > this.sizeLimit) {
				this.$message.error(`檔案需要小於 ${this.sizeLimit} ＭＢ`);
				return false;
			}
			return true;
		},
		submitUpload() {
			this.$refs["el-upload"].submit();
		},
		handleChange(file, fileList) {
			this.statusChanged = true;
			this.$emit("change", fileList);
		},
		handleRemove(file, fileList) {
			this.$emit("change", fileList);
			this.$emit("remove", fileList);
		},
		handlePreview({ url } = new Object) {
			this.previewUrl = url;
			this.dialogVisible = true;
		},
		handleSuccess(response, file, fileList) {
			file.url = response;
			if (this.isAllComplete) {
				this.$emit("complete", fileList);
				this.statusChanged = false;
			}
		},
	}
}
</script>