import firebase from "../../database/libs/firebase";
import "firebase/firestore";
import _ from "lodash";

const db = firebase.firestore();
const _ref = db.collection("speech-workshop");

const converter = {
	toFirestore: function (data) {
		const {
			speech,
			mindset_workshop,
			feeling_workshop,
			imagination_workshop,
			customized_workshop,
		} = data;
		const split_word = " ~ ";
		if (speech) _.assign(data, { speech: speech.map(item => item.join(split_word)) });
		if (mindset_workshop) _.assign(data, { mindset_workshop: mindset_workshop.map(item => item.join(split_word)) });
		if (feeling_workshop) _.assign(data, { feeling_workshop: feeling_workshop.map(item => item.join(split_word)) });
		if (imagination_workshop) _.assign(data, { imagination_workshop: imagination_workshop.map(item => item.join(split_word)) });
		if (customized_workshop) _.assign(data, { customized_workshop: customized_workshop.map(item => item.join(split_word)) });
		return data;
	},
	fromFirestore: function (snapshot, options) {
		const split_word = " ~ ";
		const data = snapshot.data(options);
		const {
			speech,
			mindset_workshop,
			feeling_workshop,
			imagination_workshop,
			customized_workshop,
		} = data;
		return {
			...data,
			speech: speech && speech
				.map(item => item.split(split_word) || new Array),
			mindset_workshop: mindset_workshop && mindset_workshop
				.map(item => item.split(split_word) || new Array),
			feeling_workshop: feeling_workshop && feeling_workshop
				.map(item => item.split(split_word) || new Array),
			imagination_workshop: imagination_workshop && imagination_workshop
				.map(item => item.split(split_word) || new Array),
			customized_workshop: customized_workshop && customized_workshop
				.map(item => item.split(split_word) || new Array),
		}
	}
}

export default {
	namespaced: true,
	state: {
		_invitationFormOptions: {
			speech: new Array,
			mindset_workshop: new Array,
			feeling_workshop: new Array,
			imagination_workshop: new Array,
			customized_workshop: new Array,
		},
	},
	actions: {
		_onDataChange({ state }, doc) {
			return new Promise((resolve, reject) => {
				_ref.doc(doc)
					.withConverter(converter)
					.onSnapshot(snapshot => {
						if (snapshot.exists) state[`_${doc.toCamelCase()}`] = snapshot.data();
						resolve(snapshot.data());
					}, err => {
						console.log({ err });
						reject(err);
					});
			})
		},
		_getData({ state }, doc) {
			return new Promise((resolve, reject) => {
				_ref.doc(doc)
					.withConverter(converter)
					.get()
					.then(snapshot => {
						if (snapshot.exists) state[`_${doc.toCamelCase()}`] = snapshot.data();
						resolve(snapshot.data());
					})
					.catch(err => reject(err));
			});
		},
		_updateData({ }, { doc, data }) {
			return new Promise((resolve, reject) => {
				_ref.doc(doc)
					.withConverter(converter)
					.set(data, { merge: true })
					.then(() => resolve(data))
					.catch(err => reject(err));
			});
		}
	},
}