<template>
	<ContentBlock id="section6">
		<p style="text-align:center; font-size:1.3em; line-height:1.5;">若貴企業或團體有其他合作的想法或建議，
			<br>歡迎您寫信給我們，我們將有專人與您聯繫。
			<br>我們期待與您一起共創可能的合作方式！
		</p>
		<br>
		<div class="center">
			<DfcButton theme="red" round style="font-size:1.2em;" @click="dialogVisible = true">歡迎來信<br>service@dfctaiwan.org</DfcButton>
		</div>
		<br>
		<br>
		<br>
		<el-dialog :visible="dialogVisible" :width="$root.rwd < 2 ? '600px' : '100%'" :show-close="false" center destroy-on-close>
			<template slot="title">
				<img src="../../assets/icon-lens.svg" width="100" style="margin:auto;">
				<h1>問題與反應</h1>
				<p style="margin:0;">我們很樂意回答您的問題，歡迎您填妥表格，我們將盡速回覆您， 或電洽、 email 給我們，將會有專人為您服務。</p>
			</template>
			<contact-us-form @cancel="dialogVisible = false" @done="dialogVisible = false" />
		</el-dialog>
	</ContentBlock>
</template>

<script>
import ContactUsForm from "../../components/ContactUsForm.vue";
export default {
	components: {
		ContactUsForm,
	},
	data() {
		return {
			dialogVisible: false,
		}
	}
}
</script>