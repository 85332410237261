<template>
	<section class="content-block" :class="{ clip }">
		<div class="slot">
			<slot />
		</div>
	</section>
</template>

<script>
export default {
	props: {
		clip: Boolean
	}
}
</script>

<style lang="scss" scoped>
.content-block {
	position: relative;
	overflow: hidden;
	padding: 5%;

	&.clip {
		clip-path: polygon(
			0 0,
			85% 1em,
			100% 0.7em,
			100% calc(100% - 0.3em),
			85% 100%,
			0 calc(100% - 1em)
		);
		@media screen and (min-width: 768px) {
			clip-path: polygon(
				0 1em,
				21% 0,
				68% 1.5em,
				90% 0.3em,
				100% 1.5em,
				100% 100%,
				88% calc(100% - 1em),
				69% 100%,
				22% calc(100% - 1.5em),
				0 100%
			);
		}
	}

	.slot {
		max-width: 1010px;
		margin: auto;
		padding: 3em 0;

		> *:first-child {
			margin-top: 0;
		}
	}
}
</style>