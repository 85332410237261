<template>
	<section>
		<h1 style="margin:1em; text-align:center;">BANNER</h1>
		<el-card v-loading="loading">
			<upload v-model="fileList" media-folder="home/banner" accept="image/*" list-type="picture" multiple @remove="statusChanged = true" @complete="statusChanged = true">
				<div slot="tip">只能上傳圖片檔，並且小於５MB</div>
				<br>
				<div class="row" align-x="right">
					<el-button size="small" type="info" :disabled="!statusChanged" @click="handleCancel">取消</el-button>
					<el-button size="small" type="success" :disabled="!statusChanged" @click="handleSave">確認儲存</el-button>
				</div>
			</upload>
		</el-card>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Upload from "../../components/Upload.vue";
export default {
	components: {
		Upload
	},
	data() {
		return {
			loading: true,
			statusChanged: false,
			fileList: new Array,
		}
	},
	computed: {
		...mapState("home", [
			"_banner",
		]),
	},
	methods: {
		...mapActions("home", [
			"_onDataChange",
			"_updateData",
		]),
		async handleSave() {
			this.loading = true;
			try {
				await this._updateData({
					doc: "banner",
					data: {
						list: this.fileList
							.map(item => _.pick(item, ["name", "url"]))
					}
				});
				this.statusChanged = false;
			} catch (error) {
				this.$message.error(error.message);
			}
			this.loading = false;
		},
		handleCancel() {
			this.statusChanged = false;
			this.fileList = this._banner.list;
		},
	},
	mounted() {
		this._onDataChange("banner")
			.then(val => {
				if (val) this.fileList = this._banner.list;
				if (this.loading) this.loading = false;
			});
	}
}
</script>