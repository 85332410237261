<template>
	<nav class="sticky-menu" :class="{fixed}">
		<!-- fixed 上方的 menu -->
		<div class="sticky" :style="{top}">
			<div v-if="$root.rwd < 3 || list.length < 3" class="row">
				<template v-for="(item, i) in list">
					<a :href="`#${item.target.id}`" :key="i" class="anchor center" :class="{active: item.active}" @click.prevent="handleScroll(item.target)">{{item.text}}</a>
				</template>
			</div>
			<template v-else>
				<div class="collapse-button row" align-x="between" align-y="center" @click="visible = !visible">
					<span>{{currentTarget && currentTarget.text}}</span>
					<i class="el-icon-caret-right" :class="{visible}" />
				</div>
				<el-collapse-transition>
					<div v-show="visible" class="col">
						<template v-for="(item, i) in list">
							<a :href="`#${item.target.id}`" :key="i" class="anchor center" @click.prevent="handleScroll(item.target)">{{item.text}}</a>
						</template>
					</div>
				</el-collapse-transition>
			</template>
		</div>
		<!-- 留在原本位置的 menu -->
		<div class="static row-grid" align-x="center">
			<slot :item="item" :scroll="handleScroll" :index="i" v-for="(item, i) in list">{{item.target}}</slot>
		</div>
	</nav>
</template>

<script>
import stickyMixin from "../mixin/sticky-menu.vue";
export default {
	mixins: [stickyMixin],
	props: {
		data: {
			type: Array,
			required: true,
			validator: arr =>
				arr.every(({ target }) => {
					const valid = target instanceof HTMLElement
						|| /^#[a-zA-Z0-9\-]+$/.test(target)
					if (!valid) console.error(`"${target}" is not valid [id selector] or [HTML DOM]`)
					return valid
				})
		}
	},
	data() {
		return {
			list: new Array,
			visible: false,
		}
	},
	computed: {
		currentTarget() {
			return this.list.find(({ active }) => active);
		}
	},
	methods: {
		handleScroll(target) {
			this.visible = false;
			const el = target instanceof HTMLElement
				? target
				: document.getElementById(target.replace("#", ""))
			const fixedMenu = document.getElementById("fixed-menu");
			if (el) scrollTo({ top: el.offsetTop - fixedMenu.offsetHeight, behavior: "smooth" });
		},
		formatData() {
			this.list = this.data.map(item => ({
				...item,
				target: document.getElementById(item.target.replace("#", ""))
					|| item.target
			}))
		},
		checkVisible() {
			const flag = window.innerHeight / 2;
			window.addEventListener("scroll", e => {
				this.list.forEach(item => {
					const top = item.target.getBoundingClientRect().top;
					const bottom = item.target.getBoundingClientRect().bottom;
					this.$set(item, "active", top < flag && bottom > flag);
				})
			});
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.formatData();
			this.checkVisible()
		});
	}
}
</script>

<style lang="scss" scoped>
.collapse-button {
	background: $red;
	color: #fff;
	padding: 0.5em;

	.el-icon-caret-right {
		transition: 0.5s;
		&.visible {
			transform: rotate(90deg);
		}
	}
}
</style>