import firebase from "../../database/libs/firebase";
import "firebase/firestore";
import _ from "lodash";
import { tz } from "moment-timezone";
import storage from "../../database/modules/storage";

const db = firebase.firestore();
const _newsRef = db.collection("news");
const _newsReferenceRef = db.collection("news-reference");

const converter = {
	toFirestore: function (data) {
		if (!data.create_time) _.assign(data, { create_time: tz("Asia/Taipei").toDate() });
		if (data.date) _.assign(data, { date: tz(data.date, "Asia/Taipei").toDate() });
		_.assign(data, { update_time: tz("Asia/Taipei").toDate() });
		if (data.fileList) data.fileList = data.fileList.map(file => _.pick(file, ["name", "url"]));
		return _.omit(data, ["id"]);
	},
	fromFirestore: function (snapshot, options) {
		const data = snapshot.data(options);
		return {
			...data,
			id: snapshot.id,
			date: data.date && data.date.toDate(),
			create_time: data.create_time && data.create_time.toDate(),
			update_time: data.update_time && data.update_time.toDate(),
		}
	}
}

/**
 * Applys an 3D Array into conditions filter "`where`"
 *
 * e.g.
 * ```
 * [
 *   ["published", "==", false],
 *   ["date", "<", new Date()],
 *   ...
 * ]
 * ```
 * Will be applied as:
 * ```
 * .where("published", "==", false)
 * .where("date", "<", new Date())
 * ...
 * ```
 * @param {firebase.firestore.CollectionReference} ref Firestore CollectionReference
 * @param {Array} query An 3D Array
 */
function mapSearchQuery(ref, queries = []) {
	const q = queries.shift();
	if (queries.length > 0) return mapSearchQuery(ref, queries);
	return q && ref.where(q[0], q[1], q[2]) || ref;
}

export default {
	namespaced: true,
	state: {
		_monthPickerOptions: new Array,
		_monthDocs: new Array,
		_news: new Object,
		_newsList: new Array,
	},
	actions: {
		// 監聽「月選項」列表（e.g. 2020-10, 2020-11...）
		_watchMonthPickerOptions({ state }, callback) {
			return new Promise((resolve, reject) => {
				_newsReferenceRef.doc("date-options")
					.onSnapshot(async doc => {
						const data = doc.data();
						if (doc.exists) {
							if (Object.values(data).some(v => v <= 0)) {
								// 如果有一個月份記錄為 0 則先刪除
								await doc.ref.set(_.pickBy(data, o => o > 0));
							} else {
								state._monthPickerOptions = Object.keys(data).sort().reverse();
							}
						}
						callback && callback(state._monthPickerOptions);
						resolve(state._monthPickerOptions);
					}, err => {
						console.log({ err });
						reject(err);
					});
			});
		},
		// 獲取「單月範圍」列表
		_getListByMonth({ state }, monthID) {
			return new Promise(async (resolve, reject) => {
				const firstDate = tz(monthID, "Asia/Taipei").toDate();
				const lastDate = tz(monthID, "Asia/Taipei").add(1, "months").toDate();
				_newsRef
					.where("date", ">=", firstDate)
					.where("date", "<", lastDate)
					.orderBy("date", "desc")
					.withConverter(converter)
					.get()
					.then(doc => {
						state._newsList = doc.docs.map(d => d.data())
						resolve(state._newsList);
					})
					.catch(err => reject(err));
			});
		},
		// 獲取「發布／草稿」列表
		_getListByPublishedStatus({ state }, { cursor = null, status = true, limit = 10 }) {
			return new Promise(async (resolve, reject) => {
				const ref = cursor && cursor.exists
					? _newsRef.where("published", "==", status).orderBy("date", "desc").startAfter(cursor)
					: _newsRef.where("published", "==", status).orderBy("date", "desc");
				ref
					.withConverter(converter)
					.limit(limit)
					.get()
					.then(snapshots => {
						if (cursor && cursor.exists) {
							snapshots.forEach(doc => {
								state._newsList.push(doc.data());
							});
						} else {
							state._newsList = snapshots.docs.map(doc => doc.data());
						}
						cursor = snapshots.docs[snapshots.size - 1];
						if (snapshots.size < limit) cursor = null;
						resolve({ cursor, list: state._newsList });
					})
					.catch(err => reject(err));
			});
		},
		// 獲取單一資料
		_getData({ state }, id) {
			return new Promise((resolve, reject) => {
				_newsRef.doc(id)
					.withConverter(converter)
					.get()
					.then(doc => {
						if (doc.exists) state._news = doc.data();
						resolve(doc);
					})
					.catch(err => reject(err));
			});
		},
		// 建立單一資料
		_createData({ }, data) {
			return new Promise(async (resolve, reject) => {
				try {
					const { id } = await _newsRef
						.withConverter(converter)
						.add(_.assign(data, { published: true }));

					// 紀錄 date-options
					const monthID = tz(data.date, "Asia/Taipei").format("YYYY-MM");
					_newsReferenceRef
						.doc("date-options")
						.get()
						.then(doc => {
							const updateData = {
								[monthID]: firebase.firestore.FieldValue.increment(1)
							}
							if (doc.exists) doc.ref.update(updateData);
							else doc.ref.set(updateData);
						});

					resolve(id);
				} catch (error) {
					reject(error);
				}
			});
		},
		// 更新單一資料
		_updateData({ state }, data) {
			return new Promise(async (resolve, reject) => {
				try {
					await _newsRef
						.doc(data.id)
						.withConverter(converter)
						.set(data, { merge: true });
					_.assign(state._news, data);

					// 更新 date-options
					const oldMonthID = tz(state._news.date, "Asia/Taipei").format("YYYY-MM");
					const newMonthID = tz(data.date, "Asia/Taipei").format("YYYY-MM");
					if (newMonthID != oldMonthID) {
						await _newsReferenceRef
							.doc("date-options")
							.update({
								[newMonthID]: firebase.firestore.FieldValue.increment(1),
								[oldMonthID]: firebase.firestore.FieldValue.increment(-1),
							});
					}

					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},
		// 轉換狀態（published？“發布”：“草稿”）
		_changeStatue({ }, { id, published }) {
			return new Promise(async (resolve, reject) => {
				_newsRef
					.doc(id)
					.update({ published })
					.then(() => resolve())
					.catch(err => reject(err))
			});
		},
		// 刪除單一資料
		_deleteData({ }, { id, date }) {
			return new Promise(async (resolve, reject) => {
				try {
					await _newsRef
						.doc(id)
						.delete()

					// 更新 date-options
					const monthID = tz(date, "Asia/Taipei").format("YYYY-MM");
					await _newsReferenceRef
						.doc("date-options")
						.update({
							[monthID]: firebase.firestore.FieldValue.increment(-1)
						});

					// 刪除（圖片）檔案
					await storage.deleteFolder(`news/${id}`);

					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},
	},
}