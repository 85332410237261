<template>
	<article class="article-block row-grid" align-x="center" align-y="center" :class="{reverse}">
		<picture>
			<slot name="picture" />
		</picture>
		<div paragraph class="col rwd-m-7" align-y="between" gutter="10">
			<slot name="paragraph" />
		</div>
	</article>
</template>

<script>
export default {
	props: {
		"picture-position": {
			type: String,
			default: () => "left",
			validator: val => ["left", "right"].includes(val)
		}
	},
	computed: {
		reverse() {
			return this.picturePosition == "right";
		}
	}
}
</script>

<style lang="scss" scoped>
article {
	[paragraph] {
		padding: 0 5%;
	}
	picture {
		flex: 1;
		max-width: 500px;
		min-width: 100px;
		@media screen and (max-width: 768px) {
			margin-bottom: 2em;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&.reverse {
		flex-direction: row-reverse;
	}
	&.scaled {
		@media screen and (min-width: 769px) {
			picture {
				transform: scale(1.3) translateX(-10%);
			}
			&.reverse {
				picture {
					transform: scale(1.3) translateX(10%);
				}
			}
		}
	}
}
hr {
	width: 2em;
	height: 3px;
	background: $red;
	margin-left: 0;
	&[green] {
		background: $green;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}
</style>