<template>
	<div>
		<img class="bg-timeline" src="/img/challenge-story/1.png" />
		<img class="bg-timeline" src="/img/challenge-story/2.png" />
		<img class="bg-timeline" src="/img/challenge-story/3.png" />
		<section class="timeline" style="padding: 0">
			<div class="timeline__nodes">
				<template v-for="(item, i) in data">
					<div class="timeline__node_item" ref="timeline__node_item">
						<slot :item="item" :index="i"
							><h3>
								<span>{{ item.id }}</span> {{ item.title }}
							</h3>
							<h4>{{ item.date }}</h4>
							<div v-html="item.content" class="content"></div>
						</slot>
						<img class="info" src="/img/challenge-story/flag.png" />
					</div>
				</template>
				<img
					class="bottom-bg-timeline"
					src="/img/challenge-story/bottom-bg.png"
				/>
				<img
					class="bottom-bg-timeline-mobile"
					src="/img/challenge-story/bottom-bg-mobile.png"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import moment from "moment-timezone";
export default {
	props: {
		data: {
			type: Array,
			required: true,
		},
		timeKey: {
			type: String,
			default: () => "time",
		},
	},
	data() {
		return {
			is_mobile: true,
		};
	},
	computed: {},
	filters: {
		yearFilter(val) {
			return moment(val).tz("Asia/Taipei").format("YYYY");
			// return moment(val).tz("Asia/Taipei").year() - 1911; // 民國年
		},
	},
	methods: {
		initRWD() {
			this.is_mobile = window.innerWidth <= 768;
		},
	},
	created() {
		if (process.client) {
			this.initRWD();
			window.addEventListener("resize", this.initRWD);
		}
	},
};
</script>

<style lang="scss" scoped>
$connect_line: 2.5em; // 左右兩邊與中間連接線之寬度
$divider_size: 4em; // 年份分界節點之寬高
$point_size: 1em; // 左右兩邊對中線的圓點之寬高
.timeline {
	clear: both;
	position: relative;
	margin-top: #{$divider_size * 0.5} !important;

	// 中線
	&::before {
		content: "";
		display: block;
		height: 100%;
		width: 1px;
		background: #e0e0e0;
		position: absolute;
		top: 20px;
		left: 50%;
		height: 99%;
		transform: translateX(-50%);
		@media screen and (max-width: 768px) {
			transform: none;
			left: #{$divider_size * 0.5};
		}
	}

	.timeline__nodes {
		padding-bottom: 35px;
	}

	// 年份節點
	.timeline__node_divider {
		clear: both;
		width: 100%;
		display: block;
		position: relative;

		&:nth-of-type(odd)::after {
			background: #f46852;
		}
		&:nth-of-type(even)::after {
			background: #f7b159;
		}
		&::after {
			content: attr(data-year);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: $divider_size;
			height: $divider_size;
			border-radius: 100%;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: 768px) {
				left: 0;
				transform: translateY(-50%);
			}
		}
	}

	// 節點
	.timeline__node_item {
		position: relative;
		width: calc(50% - #{$connect_line});

		// &:not(:last-child) {
		// 	margin-bottom: 20px;
		// }

		// 左邊
		&:nth-of-type(even) {
			float: left;
			clear: left;
			text-align: right;

			// 左邊連接線
			&::before {
				left: 100%;
			}
			// 左邊對中線的圓點
			&::after {
				left: calc(100% + #{$connect_line - $point_size * 0.5});
			}
		}

		// 右邊
		&:nth-of-type(odd) {
			float: right;
			clear: right;
			text-align: left;

			// 右邊連接線
			&::before {
				right: 100%;
			}
			// 右邊對中線的圓點
			&::after {
				right: calc(100% + #{$connect_line - $point_size * 0.5});
			}
		}

		@media screen and (max-width: 768px) {
			width: calc(100% - #{$connect_line});
			float: right !important;
			text-align: left !important;

			// 全部對中線的連接線
			&::before {
				left: unset !important;
				right: 100% !important;
				width: #{$connect_line - ($divider_size * 0.5)};
			}
			// 全部對中線的圓點
			&::after {
				left: unset !important;
				right: calc(
					100% + #{$connect_line - $divider_size * 0.5 - $point_size *
						0.5}
				) !important;
			}
		}
	}

	// 最後清除 float
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}
.timeline {
	height: 1280px;
	h3 {
		color: #f46852;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 0;
		margin-top: 0;
		span {
			font-size: 33px;
		}
	}
	h4 {
		color: #f46852;
		font-weight: 500;
		font-size: 18px;
		margin-top: 0;
	}
	.content {
		color: #373737;
		font-weight: 500;
		font-size: 16px;
	}
	.info {
		position: absolute;
		width: 68px;
		left: -73px;
		top: 0;
	}
}
.timeline__node_item:nth-child(even) {
	.content {
		margin-left: auto;
	}
	.info {
		right: -73px;
		left: auto;
	}
}
.timeline__node_item:nth-child(2) {
	position: relative;
	top: 180px;
	.content {
		max-width: 320px;
	}
}
.timeline__node_item:nth-child(3) {
	position: relative;
	top: 500px;
}
.timeline__node_item:nth-child(4) {
	position: relative;
	top: 450px;
}
.bg-timeline {
	width: 225px;
	position: absolute;
	&:nth-child(1) {
		top: 140px;
		left: -35px;
	}
	&:nth-child(2) {
		top: 520px;
		right: -30px;
	}
	&:nth-child(3) {
		bottom: -40px;
		left: -25px;
	}
}
.bottom-bg-timeline {
	max-width: 644px;
	position: absolute;
	bottom: -10px;
	transform: translateX(-50%);
	left: 50%;
	background-color: #fff;
}
.bottom-bg-timeline-mobile {
	display: none;
}

@media screen and (max-width: 1200px) {
	.bg-timeline {
		display: none;
	}
	.timeline {
		height: 1300px;
	}
}

@media screen and (max-width: 768px) {
	.timeline::before {
		height: 80%;
	}
	.timeline{
		height: auto;
	}
	.timeline__node_item {
		padding-left: 2em;
		position: relative;
		top: 0 !important;
		margin-bottom: 20px;
		h3,
		h4 {
			text-align: start;
		}
		.content {
			margin-left: 0 !important;
		}
	}
	.info {
		right: auto !important;
		left: -40px !important;
	}
	.bottom-bg-timeline {
		display: none;
	}
	.bottom-bg-timeline-mobile {
		display: block;
		width: 100%;
		max-width: 450px;
		margin: auto;
	}
}
</style>
