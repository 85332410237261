<template>
	<section>
		<el-card v-loading="loading">
			<template slot="header"><b>標籤</b></template>
			<transition-group name="list">
				<span
					v-for="(tag, i) in list"
					:key="`${tag.value}-${i}`"
					class="list-item"
				>
					<transition name="list" mode="out-in">
						<el-input
							v-if="currentEditableIndex == i"
							v-model="inputValue"
							:ref="`tag-input${i}`"
							@keyup.enter.native="handleInputConfirm(i)"
							@keyup.esc.native="handleCancel()"
							placeholder="Enter確定（或Esc取消）"
						>
							<div slot="prepend" class="center">
								顏色
								<el-color-picker
									v-model="inputColor"
									color-format="hex"
									:predefine="predefineColors"
									size="mini"
								/>
							</div>
							<el-button slot="append" @click="handleInputConfirm(i)"
								>確認</el-button
							>
						</el-input>
						<div
							v-else
							class="tag"
							@click="showInput(i)"
							:style="{ background: tag.color }"
						>
							<span class="reversed-color-text">{{ tag.value }}</span>
							<el-button
								@click.stop="handleRemove(i)"
								circle
								icon="el-icon-close"
							/>
						</div>
					</transition>
				</span>
			</transition-group>
			<el-input
				v-if="inputVisible"
				v-model="inputValue"
				ref="tag-input"
				@keyup.enter.native="handleInputConfirm()"
				@keyup.esc.native="handleCancel()"
				placeholder="Enter確定（或Esc取消）"
			>
				<div slot="prepend" class="center">
					顏色
					<el-color-picker
						v-model="inputColor"
						color-format="hex"
						:predefine="predefineColors"
						size="mini"
					/>
				</div>
				<el-button slot="append" @click="handleInputConfirm()">確認</el-button>
			</el-input>
			<el-button v-else @click="showInput()">+ 新增</el-button>
		</el-card>
		<br />
		<div class="center">
			<el-button
				type="info"
				@click="handleReset()"
				:disabled="!statusChanged"
				:loading="loading"
				>回 復</el-button
			>
			<el-button
				type="primary"
				@click="handleSubmit()"
				:disabled="!statusChanged"
				:loading="loading"
				>儲 存</el-button
			>
		</div>
	</section>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapActions, mapState } from "vuex";
import preventLeaveMixin from "../../mixin/prevent-leave";
export default {
	mixins: [preventLeaveMixin],
	data() {
		return {
			list: new Array(),
			loading: true,
			inputVisible: false,
			inputValue: "",
			inputColor: "",
			currentEditableIndex: undefined,
			predefineColors: [
				"#bfedd2",
				"#fbeeb8",
				"#f8cac6",
				"#eccafa",
				"#c2e0f4",
				"#2dc26b",
				"#f1c40f",
				"#e03e2d",
				"#b96ad9",
				"#3598db",
				"#169179",
				"#e67e23",
				"#ba372a",
				"#843fa1",
				"#236fa1",
				"#ffffff",
				"#ecf0f1",
				"#ced4d9",
				"#95a5a6",
				"#7e8c8d",
				"#34495e",
				"#000000",
			],
		};
	},
	computed: {
		...mapState("tag", ["_tags"]),
	},
	methods: {
		...mapActions("tag", ["_watchTags", "_updateTags"]),
		showInput(i) {
			if (i + 1) {
				this.inputValue = this.list[i].value;
				this.inputColor = this.list[i].color;
				this.currentEditableIndex = i;
				this.$nextTick(() =>
					setTimeout(
						() => this.$refs["tag-input" + i][0].$refs.input.focus(),
						500
					)
				);
			} else {
				this.inputVisible = true;
				this.$nextTick(() =>
					setTimeout(() => this.$refs["tag-input"].$refs.input.focus(), 500)
				);
			}
		},
		handleRemove(i) {
			this.list.splice(i, 1);
			this.statusChanged = true;
		},
		handleCancel() {
			this.currentEditableIndex = undefined;
			this.inputVisible = false;
			this.inputValue = "";
			this.inputColor = "";
		},
		handleInputConfirm(i) {
			try {
				if (this.inputValue) {
					if (
						this.list.find(
							({ value }, index) => index != i && value == this.inputValue
						)
					)
						throw new Error(this.inputValue + "已重複");
					if (i + 1) {
						this.list[i].color = this.inputColor;
						this.list[i].value = this.inputValue;
					} else
						this.list.push({
							value: this.inputValue,
							color: this.inputColor,
						});
					this.statusChanged = true;
				}
				this.handleCancel();
			} catch (error) {
				this.$message.error(error.message);
			}
		},
		async handleSubmit() {
			this.loading = true;
			try {
				await this._updateTags(this.list);
				this.statusChanged = false;
				this.$message.success("success");
			} catch (error) {
				this.$message.error((error && error.message) || "error");
			}
			this.loading = false;
		},
		handleReset() {
			this.list = cloneDeep(this._tags);
			this.statusChanged = false;
		},
	},
	async mounted() {
		await this._watchTags();
		this.handleReset();
		this.loading = false;
	},
};
</script>

<style lang="scss" scoped>
.tag {
	margin-right: 10px;

	.el-button {
		padding: 0;
	}
}
.el-button {
	height: auto;
	display: inline-block;
	margin-left: 10px;
}
.el-color-picker {
	margin-left: 5px;
}
.el-input {
	width: auto;
	margin-right: 10px;
}
.list-item {
	display: inline-block;
}
.list-enter-active,
.list-leave-active {
	transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
	opacity: 0;
	transform: translateY(30px);
}
</style>
