<template>
	<section>
		<el-select
			:value="params.year"
			placeholder="選擇屆數"
			@change="handleSessionChange"
		>
			<template v-for="option in yearOptions">
				<el-option
					:value="option.year"
					:label="`${option.year}（${option.label}）`"
					:key="option.year"
				>
					{{ `${option.year}（${option.label}）` }}
				</el-option>
			</template>
		</el-select>
		<table
			v-loading="loading"
			element-loading-background="rgba(234, 234, 234, 0.8)"
		>
			<tr>
				<!-- <th></th> -->
				<th>屆</th>
				<th>故事編號</th>
				<th>故事名稱</th>
				<th>類別</th>
				<th>輔導員</th>
				<th>小推手</th>
				<th></th>
			</tr>
			<template v-for="item in paginationList">
				<tr :key="year + item.id">
					<!-- <td>
						<el-tooltip content="點擊放大" placement="left">
							<el-image class="center" :src="item.thumb" :preview-src-list="[item.thumb]" fit="cover" lazy>
								<i slot="error" class="el-icon-picture-outline"></i>
							</el-image>
						</el-tooltip>
					</td> -->
					<td>第 {{ item.year | mapSessionLabel }} 屆</td>
					<td>{{ item.account }}</td>
					<td>{{ item.name }}</td>
					<td>
						<pre>{{ item.sdgs.join("\n") }}</pre>
					</td>
					<td>
						<pre>{{ item.counselors.join("\n") }}</pre>
					</td>
					<td>
						<pre>{{ item.team_members.join("\n") }}</pre>
					</td>
					<td>
						<router-link :to="`/manage/story/${year}/${item.id}`">
							<el-button icon="el-icon-edit" type="text"
								>查看</el-button
							>
						</router-link>
					</td>
				</tr>
			</template>
		</table>

		<br />
		<div class="center">
			<el-pagination
				:current-page="currentPage"
				:page-size="pageSize"
				background
				layout="prev, pager, next"
				:total="_stories.length"
				@current-change="handleChangePage"
			/>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { tz } from "moment-timezone";
import yearOptions from "@/assets/year-options.json";
import SDGsOptions from "@/assets/SDGs.json";
export default {
	props: ["year"],
	data() {
		return {
			loading: false,
			dialogVisible: false,
			searchText: "",
			pageSize: 10,
			SDGsOptions,
			selectSDGs: "",
			yearOptions,
		};
	},
	computed: {
		...mapState("story", ["_stories"]),
		params() {
			return {
				year: this.year,
			};
		},
		queries() {
			return this.selectSDGs
				? [["sdgs", "array-contains", this.selectSDGs]]
				: [];
		},
		list() {
			return this.searchText
				? this._stories.filter(
						(q) =>
							q.name
								.toLowerCase()
								.includes(this.searchText.toLowerCase()) ||
							q.id
								.toLowerCase()
								.includes(this.searchText.toLowerCase())
				  )
				: this._stories;
		},
		paginationList() {
			return this.list.slice(
				(this.currentPage - 1) * this.pageSize,
				this.currentPage * this.pageSize
			);
		},
		currentPage() {
			const { page } = this.$route.query;
			return (page && Number(page)) || 1;
		},
	},
	filters: {
		mapSessionLabel(year) {
			const y = /[0-9]/g.test(year)
				? Number(year)
				: tz("Asia/Taipei").year();
			// 跳過 2019
			return y - 2010 + (y > 2019 ? 0 : 1);
		},
	},
	methods: {
		...mapActions("story", ["_getStories"]),
		async handleGetStories(params) {
			this.loading = true;
			await this._getStories(params);
			this.loading = false;
		},
		handleChangePage(page) {
			if (this.currentPage != page) {
				this.$router.push("?page=" + page);
			}
		},
		handleSessionChange(year) {
			if (year.toString() !== this.year) {
				this.$router.push(`/manage/story/${year}`);
			}
		},
	},
	created() {
		this.handleGetStories(this.params);
	},
	async beforeRouteUpdate(to, from, next) {
		if (from.path != to.path) await this.handleGetStories(to.params);
		next();
	},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
	text-align: left;
	tr:nth-child(even) {
		background: rgba(225, 225, 225, 0.8);
	}
	th,
	td {
		padding: 5px;
	}
	td {
		font-size: small;
	}
	.el-button {
		padding: 0;
	}
}
.el-image {
	width: 80px;
	height: 60px;
}
</style>
