import anime from "animejs"

export function scaleAnime(els) {
	const animeOpt = {
		duration: 800,
		elasticity: 600,
		opacity: {
			value: [0, 1],
			duration: 600,
			easing: "linear"
		},
		scaleX: {
			value: [0.4, 1]
		},
		scaleY: {
			value: [0.6, 1],
			duration: 1000
		}
	};

	const stateAnime = anime({
		targets: els,
		delay: 300,
		...animeOpt
	});

	stateAnime.restart();
}
