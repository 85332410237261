import Moment from "moment-timezone";
import Vue from "vue";
// import Numeral from "numeral";

// 轉換 timestamp 格式
Vue.filter("datetimeFilter", function (val) {
  return val && Moment(val).tz("Asia/Taipei").format("YYYY-MM-DD，HH:mm");
});

// 轉換 timestamp 格式
Vue.filter("dateFilter", function (val) {
  return val && Moment(val).tz("Asia/Taipei").format("YYYY-MM-DD");
});

// 轉換 timestamp 格式
Vue.filter("monthFilter", function (val) {
  return val && Moment(val).tz("Asia/Taipei").format("YYYY 年 MM 月");
});

// 轉換 timestamp 格式
Vue.filter("yearFilter", function (val) {
  return val && Moment(val).tz("Asia/Taipei").format("YYYY");
});

// // 數字轉換
// function number(val) {
// 	if (/^[-+]{0,1}[0-9]+\.{0,1}[0-9]*$/.test(val)) {	// 判斷是否為數字
// 		let float = val && val.toString().split(".")[1] || "";
// 		if (float && float.length) {
// 			float = Array.from(float || new Array)
// 				.reduce((a, b) => a + "0", float.length ? "." : "")
// 		}
// 		return Numeral(val).format("0,0" + float);
// 	}
// 	return val;
// }

// Vue.filter("numberFilter", number);

// // 轉換貨幣字串
// Vue.filter("currencyFilter", function (val) {
// 	return "NT$ " + number(val);
// })

// 轉換 YouTube 網址為 Embed code
Vue.filter("youtubeFilter", function (url) {
  const matches = url?.match(
    // /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    /(?:youtube\.com\/(?:watch\?.*?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  );
  return matches ? "https://youtube.com/embed/" + matches[1] : "";
});
