import firebase from "../../database/libs/firebase";
import "firebase/firestore";
import { tz } from "moment-timezone";
import _ from "lodash";

const db = firebase.firestore();
const _ref = db.collection("media-center");

const converter = {
	toFirestore: function (data) {
		const clone = _.cloneDeep(data);
		if (!clone.create_time) clone.create_time = tz("Asia/Taipei").toDate();
		if (clone.date) clone.date = tz(clone.date, "Asia/Taipei").toDate();
		clone.update_time = tz("Asia/Taipei").toDate();
		return _.omit(clone, ["id"]);
	},
	fromFirestore: function (snapshot, options) {
		const data = snapshot.data(options);
		return {
			...data,
			id: snapshot.id,
			date: data.date && data.date.toDate() || undefined,
			create_time: data.create_time && data.create_time.toDate() || undefined,
			update_time: data.update_time && data.update_time.toDate() || undefined,
		}
	}
}

export default {
	namespaced: true,
	state: {
		_categories: {
			team_concept: "團隊／理念",
			teaching_learning: "教與學",
			direct_activity: "活動直擊",
		},
		_team_concept_list: new Array,
		_teaching_learning_list: new Array,
		_direct_activity_list: new Array,
	},
	actions: {
		// 建立單一資料
		_createData({ state }, data) {
			return new Promise(async (resolve, reject) => {
				_ref
					.withConverter(converter)
					.add(data)
					.then(snapshot => {
						state[`_${data.category}_list`].unshift(data);
						resolve(snapshot.id);
					})
					.catch(err => reject(err))
			});
		},
		// 獲取列表
		_getListByCategory({ state }, { cursor = null, category = "", limit = 10 }) {
			return new Promise(async (resolve, reject) => {
				const ref = cursor && cursor.exists
					? _ref.where("category", "==", category).orderBy("date", "desc").startAfter(cursor)
					: _ref.where("category", "==", category).orderBy("date", "desc");
				ref
					.withConverter(converter)
					.limit(limit)
					.get()
					.then(snapshots => {
						if (cursor && cursor.exists) {
							snapshots.forEach(doc => {
								state[`_${category}_list`].push(doc.data());
							});
						} else {
							state[`_${category}_list`] = snapshots.docs.map(doc => doc.data());
						}
						cursor = snapshots.docs[snapshots.size - 1];
						if (snapshots.size < limit) cursor = null;
						resolve({ cursor, list: state[`_${category}_list`] });
					})
					.catch(err => reject(err));
			});
		},
		// 更新單一資料
		_updateData({ state }, data) {
			return new Promise(async (resolve, reject) => {
				try {
					await _ref
						.doc(data.id)
						.withConverter(converter)
						.set(data, { merge: true });
					_.assign(
						_.find(state[`_${data.category}_list`], ["id", data.id]),
						data
					);
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},
		// 更新單一資料
		_deleteDate({ state }, { id, category }) {
			return new Promise(async (resolve, reject) => {
				try {
					await _ref
						.doc(id)
						.delete();
					state[`_${category}_list`]
						.slice(_.findIndex(state[`_${category}_list`], ["id", id]), 1);
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},
	},
}