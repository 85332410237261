<template>
	<ContentBlock>
		<h1 class="center">贊助夥伴</h1>
		<br />
		<el-card :body-style="{ paddingLeft: 0, paddingRight: 0 }">
			<RwdTable :list="list" color="red">
				<template slot-scope="{ row }">
					<div
						class="rwd-m-6 col"
						align-x="center"
						align-y="center"
						gutter="10"
						:style="{
							cursor: row.link ? 'pointer' : '',
						}"
						@click="handleLink(row.link)"
					>
						<picture>
							<img
								:src="row.logo"
								:height="row.height || 50"
								style="object-fit: contain"
							/>
						</picture>
						<small v-if="row.caption" class="text_orange">
							{{ row.caption }}
						</small>
					</div>
				</template>
			</RwdTable>
		</el-card>
		<br />
		<br />
		<div class="center">
			<DfcButton
				class="rwd-m-4 rwd-s-6"
				theme="red"
				round
				arrow
				to="/thank-you"
				target="_blank"
				>感謝名單</DfcButton
			>
		</div>
	</ContentBlock>
</template>

<script>
import RwdTable from "./RwdTable.vue";
export default {
	components: {
		RwdTable,
	},
	data() {
		return {
			list: [
				`${new Date().getFullYear()} 贊助支持`,
				[
					{
						logo: require("@/assets/partners/sponsor/chiang_education_foundation.png"),
						height: 50,
					},
					{
						caption: "專案支持 DFC 種子學校計畫",
						logo: require("@/assets/partners/sponsor/guofu.png"),
						height: 50,
					},
					{
						logo: require("@/assets/partners/sponsor/玉晶光電.jpg"),
						height: 100,
					},
					{
						caption: "支持DFC 挑戰與分享季",
						logo: require("@/assets/partners/sponsor/FSP.png"),
						height: 40,
					},
					{
						logo: require("@/assets/partners/sponsor/yins_education_foundation.png"),
						height: 70,
					},
					{
						caption: "支持教具推廣專案",
						logo: require("@/assets/partners/sponsor/南港扶輪社.png"),
						height: 90,
					},
					{
						caption: "支持DFC適性教育研討會",
						logo: require("@/assets/partners/sponsor/磐成設計磐成之友.png"),
						height: 70,
					},
					{
						logo: require("@/assets/partners/sponsor/INNA ORGANIC 童顏有機.jpg"),
						height: 120,
					},
					{
						logo: require("@/assets/partners/sponsor/品學堂.jpg"),
						height: 90,
					},
					{
						logo: require("@/assets/partners/sponsor/LiPHONE.png"),
						height: 60,
					},
					{
						caption: "支持DFC分享季及全球年會專案",
						logo: require("@/assets/partners/sponsor/芙蓉扶輪社.png"),
						height: 70,
					},
					{
						logo: require("@/assets/partners/sponsor/台南企業文化藝術基金會.png"),
						height: 150,
					},
					{
						logo: require("@/assets/partners/sponsor/隆中向上教育基金會.png"),
						height: 120,
					},
					{
						logo: require("@/assets/partners/sponsor/CORNING台灣康寧.jpg"),
						height: 120,
					},
					{
						logo: require("@/assets/partners/sponsor/智邦公益館.png"),
						height: 70,
					},
					{
						logo: require("@/assets/partners/sponsor/采瓷.png"),
						height: 100,
						link: "http://www.tsaitzu.com",
					},
					{
						logo: require("@/assets/partners/sponsor/台北石橋扶輪社.jpg"),
						height: 140,
					},
				],
				"共同推動",
				[
					{
						logo: require("@/assets/partners/sponsor/hanlin.png"),
						height: 40,
					},
					{
						logo: require("@/assets/partners/sponsor/飛達旅遊.png"),
						height: 90,
					},
					{
						logo: require("@/assets/partners/sponsor/One-Forty.png"),
						height: 110,
					},
					{
						logo: require("@/assets/partners/sponsor/TFT為台灣而教.png"),
						height: 70,
					},
				],
				"知識協力",
				[
					{
						caption: "協會為台灣公益團體自律聯盟的成員",
						logo: require("@/assets/partners/sponsor/taiwan_npo_self-regulation_alliance.png"),
						height: 45,
					},
					{
						logo: require("@/assets/partners/sponsor/social_enterprise_insights.png"),
						height: 60,
					},
				],
				"媒體協力",
				[
					{
						logo: require("@/assets/partners/sponsor/ubrand.png"),
						height: 60,
					},
				],
				"公益品項合作",
				[
					{
						logo: require("@/assets/partners/sponsor/be_together.png"),
						height: 70,
					},
					{
						logo: require("@/assets/partners/sponsor/water_refill_ma.png"),
						height: 80,
					},
					{
						logo: require("@/assets/partners/sponsor/Beyond_Fitness.png"),
						height: 100,
					},
				],
			],
		};
	},
	methods: {
		handleLink(link) {
			if (link) window.open(link, "_blank");
		},
	},
};
</script>

<style lang="scss" scoped>
.el-card {
	padding: 10px;
	.text_orange {
		color: #f7b159;
	}
	.text_gray {
		color: #868686;
	}
}
</style>
