<template>
	<el-container style="width:100%; height:100%;">
		<el-main>
			<el-form :model="ruleForm" :rules="rules">
				<el-form-item>
					<h1 class="center">後台登入</h1>
				</el-form-item>
				<el-form-item prop="account">
					<el-input v-model="ruleForm.account" placeholder="帳號" />
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="ruleForm.password" placeholder="密碼" type="password" @keyup.enter.native="handleLogin" />
				</el-form-item>
				<el-form-item>
					<div class="center">
						<el-button type="primary" :loading="loading" @click="handleLogin">登入</el-button>
					</div>
				</el-form-item>
				<el-form-item v-if="errorMessage">
					<el-alert :title="errorMessage" type="error" :closable="false" />
				</el-form-item>
			</el-form>
		</el-main>
	</el-container>
</template>

<script>
import database from "../database/modules/auth";
export default {
	data() {
		return {
			loading: false,
			ruleForm: {
				account: "",
				password: "",
			},
			rules: {
				account: [
					{ required: true, message: "必填", trigger: "change" },
					{ type: "email", message: "請確認 email 格式", trigger: "change" }
				],
				password: [{ required: true, message: "必填" },]
			},
			errorMessage: ""
		}
	},
	methods: {
		async handleLogin() {
			try {
				this.loading = true;
				await database.signInWithEmailAndPassword(this.ruleForm.account, this.ruleForm.password)
				this.$router.replace("/manage");
			} catch (error) {
				this.errorMessage = error.message;
				this.$message.error(error.message);
			}
			this.loading = false;
		}
	},
	mounted() {
		document.body.style = `height:100%;`;
	},
	beforeDestroy() {
		document.body.style = "";
	},
}
</script>

<style lang="scss" scoped>
.el-header {
	background: #b3c0d1;
}
.el-main {
	background: #e9eef3;
	padding-top: 10%;
}
.el-form {
	max-width: 400px;
	margin: auto;
}
</style>