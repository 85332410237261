<template>
	<section class="comming-soon">
		<div ref="scene1" class="center">
			<img data-depth="0.5" src="../assets/comming-soon.png" width="400">
		</div>
		<div ref="scene2" class="center">
			<h1 data-depth="0.2">敬請等待...</h1>
		</div>
	</section>
</template>

<script>
import Parallax from "parallax-js";
export default {
	mounted() {
		new Parallax(this.$refs["scene1"]);
		new Parallax(this.$refs["scene2"]);
	}
}
</script>

<style lang="scss" scoped>
.comming-soon {
	padding: 10%;
	padding-top: 5%;
}
</style>