<template>
	<section>
		<contact-us />
	</section>
</template>

<script>
import ContactUs from "../components/guideline/contact-us.vue";
export default {
	components: {
		ContactUs,
	},
}
</script>