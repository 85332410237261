import Vue from "vue";

// Element UI
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-TW";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { locale });

import TopBanner from "../components/layout/TopBanner.vue";
Vue.component("TopBanner", TopBanner);

import ContentBlock from "../components/layout/ContentBlock.vue";
Vue.component("ContentBlock", ContentBlock);

import Ratio from "../components/Ratio.vue";
Vue.component("Ratio", Ratio);

import DfcButton from "../components/DfcButton.vue";
Vue.component("DfcButton", DfcButton);