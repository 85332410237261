<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules">
		<el-form-item prop="from_name" label="姓名">
			<el-input v-model="ruleForm.from_name" placeholder="姓名" />
		</el-form-item>
		<el-form-item prop="from_email" label="電子信箱">
			<el-input v-model="ruleForm.from_email" placeholder="電子信箱" />
		</el-form-item>
		<el-form-item prop="phone" label="聯絡電話">
			<el-input v-model="ruleForm.phone" placeholder="聯絡電話" />
		</el-form-item>
		<el-form-item prop="body" label="訊息">
			<el-input v-model="ruleForm.body" placeholder="請留言給我們" type="textarea" :autosize="{minRows:5}" />
		</el-form-item>
		<el-form-item>
			<div class="center">
				<el-button type="info" round @click="cancel()" :disabled="loading">取 消</el-button>
				<el-button type="primary" round @click="submit()" :loading="loading">寄 出</el-button>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
import smtp from "../libs/smtp";
export default {
	data() {
		return {
			loading: false,
			ruleForm: new Object,
			rules: {
				from_name: { required: true, message: "必填", trigger: "blur" },
				from_email: [
					{ required: true, message: "必填", trigger: "blur" },
					{ type: "email", message: "檢查格式", trigger: "blur" },
				],
				phone: { required: true, message: "必填", trigger: "blur" },
				body: { required: true, message: "必填", trigger: "blur" },
			},
		}
	},
	methods: {
		submit() {
			this.$refs["ruleForm"].validate(async (valid, error) => {
				if (valid) {
					try {
						this.loading = true;
						const message = await smtp({
							...this.ruleForm,
							body: `
							<table>
								<tr>
									<th style="text-align:right;vertical-align:top;white-space:nowrap;">姓名：</th>
									<td style="text-align:left;vertical-align:top;width:100%">${this.ruleForm.from_name}</td>
								</tr>
								<tr>
									<th style="text-align:right;vertical-align:top;white-space:nowrap;">電子信箱：</th>
									<td style="text-align:left;vertical-align:top;width:100%">${this.ruleForm.from_email}</td>
								</tr>
								<tr>
									<th style="text-align:right;vertical-align:top;white-space:nowrap;">聯絡電話：</th>
									<td style="text-align:left;vertical-align:top;width:100%">${this.ruleForm.phone}</td>
								</tr>
								<tr>
									<th style="text-align:right;vertical-align:top;white-space:nowrap;">訊息：</th>
									<td style="text-align:left;vertical-align:top;width:100%">
										<pre style="margin:0;font:inherit;">${this.ruleForm.body}</pre>
									</td>
								</tr>
							</table>`.trim(),
							subject: "[DFC 官網] 問題與反應",
						});
						this.$message.success(message || "成功寄出");
						this.$emit("done");
					} catch (error) {
						this.$message.error("寄送失敗，請再試一次，或者聯絡 service@dfctaiwan.org")
					}
					this.loading = false;
				} else {
					this.$message.error("請確認必填欄位都填寫完畢");
				}
			});
		},
		cancel() {
			if (!this.loading) this.$emit("cancel");
		}
	}
}
</script>