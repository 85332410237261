import Vue from "vue"
import VueRouter from "vue-router"
import { tz } from "moment-timezone";
import DefaultLayout from "./views/layout-default.vue"
import ManageLayout from "./views/layout-manage.vue"
import ErrorPage from "./views/404.vue"
import Login from "./views/Login.vue"
import Guideline from "./views/Guideline.vue"

import Home from "./views/default/Home.vue";
import About from "./views/default/About.vue";
import TeacherList from "./views/default/TeacherList.vue";
import DfcTeacher from "./views/default/DfcTeacher.vue";
import DfcSchool from "./views/default/DfcSchool.vue";
import News from "./views/default/News.vue";
import Publications from "./views/default/Publications.vue";
import Resources from "./views/default/Resources.vue";
import Service from "./views/default/Service.vue";
import Privacy from "./views/default/Privacy.vue";
import Copyright from "./views/default/Copyright.vue";
import DfcChallenge from "./views/default/DfcChallenge.vue";
import VisitRiverside from "./views/default/VisitRiverside.vue";
import ThankYou from "./views/default/ThankYou.vue";
import SpeechWorkshop from "./views/default/SpeechWorkshop.vue";
import JoinUs from "./views/default/JoinUs.vue";
import MediaCenter from "./views/default/MediaCenter.vue";
import Stories from "./views/default/Stories.vue";
import Story from "./views/default/Story.vue";
import Partners from "./views/default/Partners.vue";
import Test from "./views/default/Test.vue";

import ManageHome from "./views/manage/Home.vue";
import ManageNews from "./views/manage/News.vue";
import ManageNewsItem from "./views/manage/NewsItem.vue";
import ManageAbout from "./views/manage/About.vue";
import ManageSpeechWorkshop from "./views/manage/SpeechWorkshop.vue";
import ManageMediaCenter from "./views/manage/MediaCenter.vue";
import ManageStories from "./views/manage/Stories.vue";
import ManageStory from "./views/manage/Story.vue";
import ManageStoryCategory from "./views/manage/StoryCategory.vue";

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		return new Promise(resolve => {
			const timeout = to.hash ? 1000 : 300;
			setTimeout(() => {
				if (to.hash) {
					const el = document.getElementById(to.hash.replace("#", ""));
					const fixedMenu = document.getElementById("fixed-menu");
					resolve({ x: 0, y: el.offsetTop - fixedMenu.offsetHeight, behavior: "smooth" });
				} else if (savedPosition) {
					resolve(savedPosition);
				} else {
					resolve({ x: 0, y: 0 });
				}
			}, timeout);
		})
	},
	routes: [
		{
			path: "/404",
			component: ErrorPage,
			name: "404",
		},
		{
			path: "/login",
			component: Login,
			name: "login",
		},
		{
			path: "/manage",
			component: ManageLayout,
			meta: {
				permission: true
			},
			children: [
				{
					path: "",
					redirect: "home",
					name: "manage",
				},
				{
					path: "home",
					component: ManageHome,
				},
				{
					path: "news",
					component: ManageNews,
				},
				{
					path: "news/:id",
					component: ManageNewsItem,
					props: true,
				},
				{
					path: "about",
					component: ManageAbout,
				},
				{
					path: "speech-workshop",
					component: ManageSpeechWorkshop,
				},
				{
					path: "media-center",
					component: ManageMediaCenter,
				},
				{
					path: "story",
					redirect: "story/" + tz("Asia/Taipei").format("YYYY"),
				},
				{
					path: "story/:year",
					component: ManageStories,
					props: true,
				},
				{
					path: "story/:year/:id",
					component: ManageStory,
					props: true,
				},
				{
					path: "story-category",
					component: ManageStoryCategory,
				},
			]
		},
		{
			path: "/",
			component: DefaultLayout,
			children: [
				{
					path: "",
					component: Home,
				},
				{
					path: "guideline",
					component: Guideline,
					name: "guideline",
				},
				{
					path: "about",
					component: About,
				},
				{
					path: "teacher-list",
					component: TeacherList,
				},
				{
					path: "dfc-teacher",
					component: DfcTeacher,
				},
				{
					path: "dfc-school",
					component: DfcSchool,
				},
				{
					path: "news",
					component: News,
				},
				{
					path: "publications",
					component: Publications,
				},
				{
					path: "resources",
					component: Resources,
				},
				{
					path: "service",
					component: Service,
				},
				{
					path: "privacy",
					component: Privacy,
				},
				{
					path: "copyright",
					component: Copyright,
				},
				{
					path: "dfc-challenge",
					component: DfcChallenge,
				},
				{
					path: "visit-riverside",
					component: VisitRiverside,
				},
				{
					path: "thank-you",
					component: ThankYou,
				},
				{
					path: "speech-workshop",
					component: SpeechWorkshop,
				},
				{
					path: "join-us",
					component: JoinUs,
				},
				{
					path: "media-center",
					component: MediaCenter,
				},
				{
					path: "partners",
					component: Partners,
				},
				{
					path: "story",
					redirect: "story/" + tz("Asia/Taipei").format("YYYY"),
				},
				{
					path: "story/:year",
					component: Stories,
					props: true,
				},
				{
					path: "story/:year/:id",
					component: Story,
					props: true,
				},
				{
					path: "test",
					component: Test,
				},
			]
		},
		{
			path: "*",
			redirect: "/404"
		}
	]
})

export default router
