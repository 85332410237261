import router from "../router";
import Auth from "../database/modules/auth";

router.beforeEach(async (to, from, next) => {
	const { matched, name } = to;
	if (!Auth.user && !await Auth.onAuthStateChanged()) {
		if (matched.some(({ meta }) => meta.permission)) next({ name: "404" });
	}
	else if (name == "login") next({ name: "manage" });
	next();
});