<template>
	<section v-loading="loading" element-loading-background="rgba(255, 242, 236, 0.8)" style="margin:auto; max-width:1200px; padding:3%;">
		<el-page-header @back="$router.push(previousPage)" class="row" align-y="baseline">
			<h1 slot="content" style="text-align:left; line-height: 1;">{{ storyData.name }} {{ storyData.account }}</h1>
		</el-page-header>
		<br>
		<section class="main">
			<table style="border-collapse:separate; border-spacing:5px;">
				<tr>
					<th>故事名稱：</th>
					<td>
						<el-input v-model="storyData.name" />
					</td>
				</tr>
				<tr>
					<th>學校單位：</th>
					<td>
						<el-input v-model="storyData.school" />
					</td>
				</tr>
				<tr>
					<th>輔導員：</th>
					<td>
						<DynamicTag v-model="storyData.counselors" />
					</td>
				</tr>
				<tr>
					<th>小推手：</th>
					<td>
						<DynamicTag v-model="storyData.team_members" />
					</td>
				</tr>
				<tr>
					<th>影片連結：</th>
					<td>
						<el-input v-model="storyData.video" placeholder="影片連結" />
					</td>
				</tr>
				<tr>
					<th>手冊連結：</th>
					<td>
						<el-input v-model="storyData.document" placeholder="手冊連結" />
					</td>
				</tr>
				<tr>
					<th>SDGs：</th>
					<td>
						<el-select v-model="storyData.sdgs" multiple placeholder="SDGs">
							<el-option v-for="item in SDGsOptions" :label="item" :value="item" :key="item" />
						</el-select>
					</td>
				</tr>
			</table>
			<br>
			<div class="row-grid" align-y="top" gutter-x="20" gutter-y="20">
				<div class="row-grid rwd-l-5" align-y="top" gutter-x="10" gutter-y="10">
					<div class="rwd-m-12 rwd-s-6" v-if="storyData.pictures">
						<Siema :data="storyData.pictures" :options="{ loop: true, interval: 5000 }" indicator>
							<template v-slot="{ slide }">
								<Ratio ratio="youtube">
									<el-image class="center" :src="slide" fit="cover">
										<i slot="error" class="el-icon-picture-outline"></i>
									</el-image>
								</Ratio>
							</template>
						</Siema>
					</div>
					<div class="rwd-m-12 rwd-s-6">
						<Ratio ratio="youtube">
							<iframe :src="storyData.video | youtubeFilter" width="100%" height="100%" frameborder="0" allowfullscreen />
						</Ratio>
					</div>
				</div>
				<div class="col rwd-l-7" align-x="left">
					<span class="category-title" style="background:#ef6086;">感受</span>
					<el-input v-model="storyData.description_feeling" type="textarea" :autosize="{ minRows: 5 }" />
					<br>
					<span class="category-title" style="background:#ffc82f;">想像</span>
					<el-input v-model="storyData.description_imagine" type="textarea" :autosize="{ minRows: 5 }" />
					<br>
					<span class="category-title" style="background:#b8d200;">實踐</span>
					<el-input v-model="storyData.description_implementation" type="textarea" :autosize="{ minRows: 5 }" />
					<br>
					<span class="category-title" style="background:#008ed8;">分享</span>
					<el-input v-model="storyData.description_sharing" type="textarea" :autosize="{ minRows: 5 }" />
				</div>
			</div>
			<br>
			<br>
			<br>
			<br>
			<el-button type="success" style="width: 100%;" @click="handleUpdate()">更新</el-button>
		</section>
		<br>
		<br>
		<br>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Siema from "../../components/Siema.vue";
import DynamicTag from "@/components/story/Tag2.vue";
import SDGsOptions from "@/assets/SDGs.json";
export default {
	props: ["year", "id"],
	components: {
		Siema,
		DynamicTag,
	},
	data() {
		return {
			SDGsOptions,
			previousPage: "",
			loading: true,
			storyData: {},
		}
	},
	computed: {
		...mapState("story", [
			"_story",
		]),
		params() {
			return {
				year: this.year,
				id: this.id,
			}
		},
	},
	filters: {
		mapCategoryLabel(val) {
			return {
				people: "社會進步（People）",
				prosperity: "地方共融（Prosperity）",
				planet: "環境保衛（Planet）",
			}[val] || val;
		},
	},
	methods: {
		...mapActions("story", [
			"_getStory",
			"_updateStory",
		]),
		async handleUpdate() {
			this.loading = true;
			try {
				await this._updateStory({
					params: { year: this.year, id: this.id },
					data: {
						name: this.storyData.name,
						school: this.storyData.school,
						counselors: this.storyData.counselors,
						team_members: this.storyData.team_members,
						sdgs: this.storyData.sdgs,
						video: this.storyData.video,
						document: this.storyData.document,
						description_feeling: this.storyData.description_feeling,
						description_imagine: this.storyData.description_imagine,
						description_implementation: this.storyData.description_implementation,
						description_sharing: this.storyData.description_sharing,
					},
				});
				this.$message.success("更新成功");
			} catch (error) {
				console.log({ error });
				this.$message.error("更新失敗");
			}
			this.loading = false
		},
	},
	async created() {
		try {
			this.previousPage = `/manage/story/${this.year}`;
			await this._getStory(this.params);
			this.storyData = this._story;
		} catch (error) {
			console.log({ error });
			this.$message.error(error.message)
		}
		this.loading = false;
	},
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
	.main {
		padding-left: 6em;
	}
}

.el-image {
	width: 100%;
	height: 100%;
}

.siema,
.ratio {
	width: 100%;
}

.category-title {
	color: #fff;
	font-size: 1.3em;
	padding: 5px 1em;

	+pre {
		padding: 2em;
		width: 100%;
	}
}

.el-tag {
	margin: 5px;
}

th {
	text-align: right;
	font-weight: normal;
}
</style>