<template>
	<div style="padding:5%;">
		<media-interview-form />
	</div>
</template>

<script>
import MediaInterviewForm from "../../components/MediaInterviewForm.vue"
export default {
	components: {
		MediaInterviewForm,
	},
	data() {
		return {}
	}
}
</script>