<template>
	<section id="service">
		<TopBanner>
			<img src="../../assets/service/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/service/title.png" class="page-title" width="360">

		<!-- section1 -->
		<ContentBlock>
			<p>DFC 臺灣團隊多年來於教育現場深耕，觀察到許多老師積極地想要改變教育場，努力回應每個孩子不同的學習需求，點燃孩子學習動機。但改變的路總是漫長且充滿挑戰，再有熱忱的老師們也難免感到心有餘而力不足。因此，我們推出「全臺教育工作者的支持系統」，希望成為老師的強力後盾，為每一個孩子創造客製化而難忘的學習體驗。</p>
			<StickyMenu :data="anchors">
				<div slot-scope="{item, scroll}" class="rwd-s-4" style="padding:1em;">
					<a :href="`#${item.target.id}`" :class="{ col: $root.rwd < 3, row: $root.rwd >= 3, active: item.active }" :align-x="$root.rwd < 3 ? 'center' : ''" align-y="center" @click.prevent="scroll(item.target)">
						<img :src="item.icon">
						<span>{{item.text}}</span>
						<small><small>{{item.content}}</small></small>
					</a>
				</div>
			</StickyMenu>
		</ContentBlock>

		<ContentBlock id="1" clip bg-color="white1">
			<header>
				<h1 class="title">DFC 融入教學</h1>
				<p>
					<span>「DFC 跟我的理念很契合！</span>
					<span>但要怎麼用在我的課堂上？」</span>
					<br>只要踏出第一步，就會遇到更多夥伴來互助
				</p>
			</header>
			<tabs :data="list1" color-type="red" />
		</ContentBlock>
		<ContentBlock id="2" clip bg-color="green1">
			<header>
				<h1 class="title">將 DFC 帶入學校</h1>
				<p>
					<span>學校同事們之間的夥伴關係、</span>
					<span>學校和 DFC 之間的夥伴關係，</span>
					<br>由 DFC 負責穿針引線，串起連結。
					<br>來自外部的引導激發創新想像，
					<br>
					<span>一起打造「讓孩子感受到學習的意義，</span>
					<span>並願意主動學習」的教育環境。</span>
				</p>
			</header>
			<tabs :data="list2" color-type="darkblue" />
		</ContentBlock>
		<ContentBlock id="3">
			<header>
				<h1 class="title">教師專業發展與支持</h1>
				<p>
					<span>DFC 的旅程，是一連串探索本質的過程。</span>
					<span>老師們以 DFC 的精神來設計自身所處的教學環境，</span>
					<br>將更有自信引導孩子們一起探索世界。
				</p>
			</header>
			<tabs :data="list3" color-type="red" />
		</ContentBlock>
	</section>
</template>

<script>
import StickyMenu from "../../components/StickyMenu.vue";
import Tabs from "../../components/service/Tabs.vue";

export default {
	components: {
		StickyMenu,
		Tabs
	},
	data() {
		return {
			anchors: [
				{
					icon: require("@/assets/service/anchor1.png"),
					target: "#1",
					text: "DFC 融入教學",
					content: "善用不同教學工具與技巧，參考他人的經驗，更有效率達到教學目標。",
				},
				{
					icon: require("@/assets/service/anchor2.png"),
					target: "#2",
					text: "將 DFC 帶入學校",
					content: "為自己學校找到專屬的特色，與志同道合的夥伴一起努力，塑造學校文化。",
				},
				{
					icon: require("@/assets/service/anchor3.png"),
					target: "#3",
					text: "教師專業發展與支持",
					content: "為自己學校找到專屬的特色，與志同道合的夥伴一起努力，塑造學校文化。",
				}
			],
			list1: [
				{
					icon: "icon1-1.png",
					icon_active: "icon1-1-active.png",
					img: "img1-1.png",
					title: "DFC 挑戰",
					content: "素養是能實踐在生活中的基本應用能力。利用 DFC 挑戰作為教學活動，不但能提升孩子們的學習動機，還能陪伴孩子培養「同理心、創造力、行動力、自信心」！邀請您和學生一起，也能自行組隊，體驗將 DFC 四步驟融入生活中的歷程，入門跨域、跨學科課程設計。DFC 臺灣全面升級陪伴服務系統，提供線上客服、諮詢電話專線、線上報名平台，減輕輔導員們的行政及心理負擔。",
					button: {
						text: "如何報名 DFC 挑戰",
						to: "/dfc-challenge"
					}
				},
				{
					icon: "icon1-2.png",
					icon_active: "icon1-2-active.png",
					img: "img1-2.png",
					title: "DFC 臉書教學社群",
					content: "你不孤單！這是一個開放友善的討論空間，社團中，我們以影片、文章、圖片、課程活動教案分享 DFC 相關知識及經驗。社群成員能夠共享不同主題的 DFC 教案、觀看 DFC 觀課紀錄、交流教學經驗、提問遇到的教學困擾，看到來自各地進行 DFC 教學的老師們齊聚一堂，一起在教育路上同甘共苦，共創更多可能。",
					button: {
						text: "加入 DFC 教學社群",
						href: "https://www.facebook.com/groups/1114591105274908"
					}
				},
				{
					icon: "icon1-3.png",
					icon_active: "icon1-3-active.png",
					img: "img1-3.png",
					title: "DFC 教學資源分享",
					content: "免費的 DFC 相關課程活動教案、教學工具與行政資源都在這裡，歡迎您依需要選擇您覺得適合的素材運用。",
					button: {
						text: "了解更多",
						to: "/resources"
					}
				},
				{
					icon: "icon1-4.png",
					icon_active: "icon1-4-active.png",
					img: "img1-4.png",
					title: "書籍著作",
					content: "透過DFC 臺灣推出分別適合教師、家長、學生的書籍，關於 DFC 課程規劃、DFC 四步驟及設計思考、教育與辦學理念、DFC 挑戰故事案例及學習單，都可以在書中一探究竟。",
					button: {
						text: "了解更多",
						to: "/publications"
					}
				}
			],
			list2: [
				{
					icon: "icon2-1.png",
					img: "img2-1.png",
					title: "演講 / 工作坊",
					content: "「DFC 四步驟是什麼？」演講現場說分明。\n「DFC 課程怎麼帶？」來工作坊試試，先體驗孩子們在課程中經歷的心境，再一起設計具深度且適用的教學活動。\nDFC 臺灣團隊成立至今，講師團隊跑遍全臺 22 縣市，演講超過 600 場，辦理工作坊超過 100 場。現在，就為您的團隊爭取一個機會！",
					button: {
						text: "申請演講／工作坊",
						to: "/speech-workshop"
					}
				},
				{
					icon: "icon2-2.png",
					img: "img2-2.png",
					title: "印度河濱學校參訪連繫協助",
					content: "電影《三個傻瓜》那所神秘、充滿驚奇的學校，原來真實存在！印度河濱學校授權 DFC 臺灣團隊為唯一參訪聯繫窗口，每年開放參訪名額有限，請及早申請。",
					button: {
						text: "了解更多",
						to: "/visit-riverside"
					}
				},
			],
			list3: [
				{
					icon: "icon3-1.png",
					icon_active: "icon3-1-active.png",
					img: "img3-1.png",
					title: "種子教師認證計畫",
					content: "從自發成長到影響力擴散，「DFC 種子教師認證計畫」提供資深輔導員設計思考實作增能與共備講師培訓，協助教師提升專業能力，利用設計思考實現自己理想的教育理念。",
					button: {
						text: "如何成為種子教師",
						to: "/dfc-teacher"
					}
				},
				{
					icon: "icon3-2.png",
					icon_active: "icon3-2-active.png",
					img: "img3-2.png",
					title: "種子學校認證計畫",
					content: "這一切，沒有很容易，但是很值得。DFC 臺灣與學校攜手合作，共同打造以 DFC 為主軸的系列課程。為期數個月的共備、觀課、經驗陪伴分享，DFC 臺灣是學校在 DFC 課程籌備及實施過程中，與您一起面對各種疑難雜症的夥伴。",
					button: {
						text: "如何成為種子學校",
						to: "/dfc-school"
					}
				},
			],
		}
	},

}
</script>

<style lang="scss" scoped>
.sticky-menu {
	a {
		background: #fff;
		width: 100%;
		height: 100%;
		line-height: 1.5;
		color: black;
		border: 1px solid #dc3d3c;
		padding: 1em;
		transition: background 0.3s;
		border-radius: 6px;
		text-align: center;

		img {
			width: 6em;
			margin: 0 0 0.5em;
		}

		span {
			color: $red;
		}

		@mixin active {
			background: $red;
			color: white;
			span {
				color: inherit;
			}
		}
		&.active {
			@include active;
		}
		@media (hover: hover) {
			&:hover {
				@include active;
			}
		}

		@media screen and (min-width: 768px) {
			font-size: 1.2em;
		}

		@media screen and (max-width: 425px) {
			border-radius: 100px;
			padding: 0.5em 1em;

			img {
				margin: 0 1em;
				width: 2em;
			}
			small {
				display: none;
			}
		}
	}
}
header {
	text-align: center;
	line-height: 2;
	h1 {
		margin: 0;
	}
	span {
		display: inline-block;
	}
}
</style>