import Joi from "joi";

/* SmtpJS.com - v3.0.0 */
const Email = { send: function (a) { return new Promise(function (n, e) { a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send"; var t = JSON.stringify(a); Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) { n(e) }) }) }, ajaxPost: function (e, n, t) { var a = Email.createCORSRequest("POST", e); a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () { var e = a.responseText; null != t && t(e) }, a.send(n) }, ajax: function (e, n) { var t = Email.createCORSRequest("GET", e); t.onload = function () { var e = t.responseText; null != n && n(e) }, t.send() }, createCORSRequest: function (e, n) { var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t } };

const schema = Joi.object({
	from_name: Joi.string().error(new Error("姓名")),
	from_email: Joi.string().email({ tlds: { allow: false } }).error(new Error("電子信箱")),
	subject: Joi.string().required().error(new Error("主旨")),
	body: Joi.string().error(new Error("內容")),
	attachments: Joi.array().items({
		name: Joi.string().error(new Error("附件名稱")),
		path: Joi.string().error(new Error("附件路徑")),
	}).error(new Error("附件")),
});
/**
 * @param {String} from_name String of name
 * @param {String} from_email String of email
 * @param {String} subject String of subject
 * @param {String} body String of email body (could be text or HTML)
 * @param {Array} attachments Array of attachments
 */
export default function (options) {
	return new Promise(function (resolve, reject) {
		const { value, error, warning } = schema.validate(options, { stripUnknown: true });
		if (error) reject(error);
		else if (warning) reject(warning);
		else Email
			.send({
				SecureToken: process.env.VUE_APP_EMAIL_SECURETOKEN,
				// NOTE: From 一定要包含 email，否則會出錯
				From: `${value.from_name} <${value.from_email}>`,
				To: process.env.VUE_APP_EMAIL_TO,
				Subject: value.subject,
				Body: value.body,
				Attachments: value.attachments,
			})
			.then(message => resolve(message))
			.catch(error => reject(error));
	});
}