<template>
	<div
		class="card row-grid"
		align-y="top"
		align-x="center"
		gutter-x="25"
		gutter-y="20"
		style="margin-bottom:1em; font-size:smaller;"
	>
		<div>
			<Ratio ratio="100%" style="width: 167px; border-radius: 999px;">
				<img
					:src="data.img"
					style="width: 100%; height: 100%; object-fit: cover;"
				/>
			</Ratio>
		</div>
		<div class="rwd-m-8">
			<div
				class="col"
				gutter="10"
				style="padding:1em; border-radius:10px; background:#f3f3f3;"
			>
				<pre>{{ data.header }}</pre>
				<hr style="background:#979797; border:none; height:1px; width:100%;" />
				<pre style="font-weight: bold;">{{ data.subtitle }}</pre>
				<pre
					v-html="
						data.description
							.replace(/\{\{/g, `<span style='color:#A2B93F'>`)
							.replace(/\}\}/g, `</span>`)
					"
				></pre>
				<p v-if="data.who" style="text-align:right;">——&nbsp;{{ data.who }}</p>
				<div style="background:#ffedaa; padding:1em; border-radius:">
					相關報導/文章：
					<ul>
						<li v-for="item in data.links" :key="item.url">
							<a :href="item.url" class="underline" target="_blank">{{
								item.text
							}}</a>
						</li>
					</ul>
					<template v-if="data.others && Array.isArray(data.others)">
						相關成效
						<ul>
							<li v-for="(item, i) in data.others" :key="`${item}-${i}`">
								{{ item }}
							</li>
						</ul>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Ratio from "../Ratio.vue";

export default {
	props: ["data"],
	components: { Ratio },
};
</script>
