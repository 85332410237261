<template>
	<ContentBlock>
		<h1 style="text-align:center;">演講/工作坊邀約表單</h1>
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
			<el-form-item label="1. 我要申請" prop="type">
				<el-radio v-model="ruleForm.type" label="演講" />
				<br>
				<el-radio v-model="ruleForm.type" label="心態工作坊" />
				<el-radio v-model="ruleForm.type" label="感受工作坊" />
				<el-radio v-model="ruleForm.type" label="想像工作坊" />
				<el-radio v-model="ruleForm.type" label="客製化工作坊" />
			</el-form-item>
			<el-form-item label="2. 邀約單位" prop="invite_from">
				<el-input v-model="ruleForm.invite_from" />
			</el-form-item>
			<el-form-item label="3. 聯絡人" prop="contact_name">
				<el-input v-model="ruleForm.contact_name" />
			</el-form-item>
			<el-form-item label="4. 聯絡人職稱" prop="contact_job_title">
				<el-input v-model="ruleForm.contact_job_title" />
			</el-form-item>
			<el-form-item label="5. 聯絡人電話" prop="contact_phone">
				<el-input v-model="ruleForm.contact_phone" />
			</el-form-item>
			<el-form-item label="6. 電子信箱" prop="contact_email">
				<el-input v-model="ruleForm.contact_email" />
			</el-form-item>
			<template v-if="ruleForm.type">
				<el-form-item label="7. 希望邀約日期" prop="prefer_date">
					<small>因人力有限，DFC 臺灣有許多專案同時進行，因此只能接受從當月算起兩個月「後」的邀約，若未能滿足您的邀約需求，請您見諒！</small>
					<b style="color:red;">2021年度演講及工作坊邀約已額滿，我們預計於2021/12月開放2022/02月起之邀約</b>
					<br>
					<el-select v-model="ruleForm.prefer_date" placeholder="請選擇時段" style="min-width:300px;" no-data-text="此月份不開放邀約" disabled>
						<el-option v-for="(option, i) in invitationFormOptions" :key="i" :label="option" :value="option" />
					</el-select>
				</el-form-item>
			</template>
			<el-form-item label="8. 預計時段" prop="prefer_time_period">
				<small>臺北市以外的演講、工作坊，因考慮講師交通時間，建議安排早上 10 點以後。</small>
				<el-input v-model="ruleForm.prefer_time_period" />
			</el-form-item>
			<el-form-item label="9. 地點名稱及地址">
				<small>請協助輸入舉辦場地完整地址，以利講師規劃交通。</small>
				<el-form-item label="地點名稱" prop="address_name">
					<el-input v-model="ruleForm.address_name" />
				</el-form-item>
				<br>
				<el-form-item label="地址" prop="address">
					<el-input v-model="ruleForm.address" />
				</el-form-item>
			</el-form-item>
			<el-form-item label="10. 大樓名稱、禮堂、會場、教室名稱（若有）" prop="venue_name">
				<small>演講場地須具備：投影設備、麥克風、可接電腦之音響喇叭。
					<br>工作坊場地須具備：平面教室（含適合分組討論之桌椅）、投影設備、麥克風、可接電腦之音響喇叭。</small>
				<el-input v-model="ruleForm.venue_name" />
			</el-form-item>
			<el-form-item label="11. 是否有媒體採訪或其他形式的媒體露出？" prop="has_media_exposure">
				<small>含校園記者、新聞稿發稿、貴單位專欄等.....</small>
				<el-radio v-model="ruleForm.has_media_exposure" label="是" />
				<el-radio v-model="ruleForm.has_media_exposure" label="否" />
			</el-form-item>
			<el-form-item label="12. 當天講師會帶協會出版的書籍，讓大家能夠翻閱購買，若不方便讓觀眾購書煩請告知，感恩您給我們機會！" prop="can_we_sale_books">
				<el-radio v-model="ruleForm.can_we_sale_books" label="歡迎現場販售書籍" />
				<el-radio v-model="ruleForm.can_we_sale_books" label="不便配合" />
			</el-form-item>
			<el-form-item label="13. 對象" prop="type_of_participant">
				<small>可描述聽講對象身份，越詳細越好唷！</small>
				<el-input v-model="ruleForm.type_of_participant" type="textarea" :autosize="{minRows:5}" />
			</el-form-item>
			<el-form-item label="14. 預計參與人數" prop="estimated_number_of_participants">
				<el-input-number v-model="ruleForm.estimated_number_of_participants" controls-position="right" :min="1" type="number" />
			</el-form-item>
			<el-form-item label="15. 參與對象彼此熟識程度" prop="familiarity_of_participants">
				<el-radio-group v-model="ruleForm.familiarity_of_participants">
					<template v-for="num in 10">
						<el-radio-button :label="num" :key="num" />
					</template>
				</el-radio-group>
				<div class="row" align-x="between">
					<small>1-互不認識、陌生群組</small>
					<small>10-非常熟識、互為工作夥伴</small>
				</div>
			</el-form-item>
			<el-form-item label="16. 貴單位對本次工作坊之課程期待" prop="degree_of_expectation">
				<el-input v-model="ruleForm.degree_of_expectation" />
			</el-form-item>
			<el-form-item label="17. 貴單位特殊故事、信念或期望演講中多強調的關鍵字與觀念：" prop="keyword_expect_to_hear">
				<el-input v-model="ruleForm.keyword_expect_to_hear" />
			</el-form-item>
			<el-form-item label="18. 您是如何認識 DFC 臺灣的呢？" prop="how_do_you_know_DFC">
				<el-input v-model="ruleForm.how_do_you_know_DFC" />
			</el-form-item>
			<el-form-item label="19. 備註" prop="note">
				<el-input v-model="ruleForm.note" type="textarea" :autosize="{minRows:5}" />
			</el-form-item>
			<el-form-item label="20. 交通費用" prop="transportation_fee">
				<small>實報實銷，敬請貴單位協助提供車站或機場至貴單位之接駁服務。</small>
				<el-radio v-model="ruleForm.transportation_fee" label="車站接駁" /><br>
				<el-radio v-model="ruleForm.transportation_fee" label="機場接駁" /><br>
				<inputable-radio v-model="ruleForm.transportation_fee" prefix="高鐵來回，費用" /><br>
				<inputable-radio v-model="ruleForm.transportation_fee" prefix="計程車來回，費用" /><br>
				<inputable-radio v-model="ruleForm.transportation_fee" prefix="其他" />
			</el-form-item>
			<el-form-item label="21. 講師費用" prop="lecturer_fee">
				<small>實報實銷，敬請貴單位協助提供車站或機場至貴單位之接駁服務。</small>
				<el-radio v-model="ruleForm.lecturer_fee" label="我是企業或其他單位邀約演講" /><br>
				<el-radio v-model="ruleForm.lecturer_fee" label="我是非營利機構邀約演講 新臺幣 6,000／２小時（含行政時間 30 分鐘，不含交通費）" /><br>
				<el-radio v-model="ruleForm.lecturer_fee" label="我是學校邀約演講 新臺幣 4,000／２小時（含行政時間 30 分鐘，不含交通費）" /><br>
				<el-radio v-model="ruleForm.lecturer_fee" label="我是企業或其他單位邀約工作坊" /><br>
				<el-radio v-model="ruleForm.lecturer_fee" label="我是學校邀約工作坊 新臺幣 9,000／３小時（不含講義費及交通費）" />
			</el-form-item>
			<el-form-item label="22. 教具費用報帳的抬頭及統編" prop="title_and_tax">
				<small>若您申請演講，請填寫「無」</small>
				<el-input v-model="ruleForm.title_and_tax" placeholder="若您申請演講，請填寫「無」" />
			</el-form-item>
			<el-form-item label="感謝您的邀約，讓我們先溫柔地提醒您一同配合：" :rules="{validator, trigger:'blur'}">
				<div class="col" gutter="10">
					<template v-for="(item, i) in agreements">
						<el-checkbox v-model="item.checked" :key="i">{{item.label}}</el-checkbox>
					</template>
				</div>
			</el-form-item>
			<br>
			<el-form-item>
				<div class="center">
					<DfcButton class="rwd-s-6" theme="red" round arrow @click.prevent="handleSubmit()">送出</DfcButton>
				</div>
			</el-form-item>
		</el-form>
	</ContentBlock>
</template>

<script>
import { mapActions, mapState } from "vuex";
import emailjs from "../../libs/emailjs-com";
import InputableRadio from "../InputableRadio.vue";
export default {
	components: { InputableRadio },
	data() {
		const rule = () => ({ required: true, message: "必填", trigger: "blur" });
		return {
			ruleForm: new Object,
			rules: {
				unit: rule(),
				invite_from: rule(),
				contact_name: rule(),
				contact_job_title: rule(),
				contact_phone: rule(),
				contact_email: [rule(), { type: "email", message: "錯誤的信箱格式", trigger: "blur" }],
				prefer_date: rule(),
				prefer_time_period: rule(),
				address_name: rule(),
				address: rule(),
				transportation_fee: rule(),
				lecturer_fee: rule(),
				title_and_tax: rule(),
			},
			agreements: [
				{
					label: "因投影片中有大量影片，現場需安排人員協助控制燈光",
					checked: false,
				},
				{
					label: "為了節省地球資源，演講不需印製講義，而我們也懇請您不用特別印製感謝狀，跟我們一起做小小的改變，您的感謝，我們銘記在心 :)",
					checked: false,
				},
				{
					label: "為了維護著作權，活動中可以拍照，但全程不開放錄影，當天簡報檔案亦無法提供。",
					checked: false,
				},
				{
					label: "我明白演講講師團隊陣容皆為經過 DFC 培訓優秀講師，我們將於媒合講師時間後，視情況安排最適宜的講師人選，無法指定，還請您見諒！",
					checked: false,
				},
			],
			suffixText: "",
			optionMapping: {
				"演講": "speech",
				"心態工作坊": "mindset_workshop",
				"感受工作坊": "feeling_workshop",
				"想像工作坊": "imagination_workshop",
				"客製化工作坊": "customized_workshop",
			}
		}
	},
	computed: {
		...mapState("speech-workshop", [
			"_invitationFormOptions"
		]),
		passed() {
			return this.agreements.every(({ checked }) => checked);
		},
		invitationFormOptions() {
			const { type } = this.ruleForm;
			return type
				&& this._invitationFormOptions[this.optionMapping[type]]
				&& this._invitationFormOptions[this.optionMapping[type]]
					.map(item => item.join(" ~ "))
				|| new Array;
		}
	},
	methods: {
		...mapActions("speech-workshop", [
			"_getData"
		]),
		validator(rule, value, callback) {
			if (!this.passed) callback(new Error("請閱讀並確認以上所有選項"));
			else callback();
		},
		handleSubmit() {
			this.$refs["ruleForm"].validate(async (valid, error) => {
				let loading;
				if (valid) {
					try {
						loading = this.$loading();
						await emailjs(process.env.VUE_APP_EMAIL_TEMPLATE_ID_1, this.ruleForm);
						this.$message.success("成功寄出");
					} catch (error) {
						this.$message.error("寄送失敗，請再試一次，或者聯絡 service@dfctaiwan.org")
					}
				} else {
					this.$message.error("請確認必填欄位都填寫完畢");
					const fixedMenu = document.getElementById("fixed-menu");
					scrollTo({ top: this.$el.offsetTop - fixedMenu.offsetHeight, behavior: "smooth" });
				}
				if (loading) loading.close();
			});
		}
	},
	async created() {
		const loading = this.$loading();
		await this._getData("invitation-form-options");
		loading.close();
	}
}
</script>

<style lang="scss" scoped>
.el-form {
	max-width: 510px;
	margin: auto;
}
small {
	display: block;
	margin-bottom: 10px;
}
.el-radio {
	input {
		border: none;
		color: inherit;
		background: transparent;
		border-bottom: 1px solid;
		outline: none;
	}
}
.el-checkbox {
	display: flex;
	align-items: baseline;
	white-space: pre-line;
}
</style>