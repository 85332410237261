<template>
	<ContentBlock clip bg-color="green1">
		<h1 style="color:#122e26; text-align:center;">使用協會任一標誌</h1>
		<p style="line-height:1.8;">社團法人臺灣童心創意行動協會、種子師「陪」計畫、Design for Change 及 Design for Change, Taiwan 的所有標示、徽標、網頁、截圖或其它專用標誌均受適用的商標法、版權法和其它智慧財產權法的保護。如果您要在您的網站、廣告、文章或書籍中使用任何上述標誌，或要在任何其它地方顯示這些標誌，都必須首先獲得社團法人臺灣童心創意行動協會（DFC 臺灣）的授權。若有任何需求，歡迎您<el-button type="text" @click="dialogVisible = true" style="color:#eb6165; text-decoration:underline;">聯絡我們</el-button></p>

		<el-dialog :visible="dialogVisible" :width="$root.rwd < 2 ? '600px' : '100%'" :show-close="false" center destroy-on-close append-to-body>
			<template slot="title">
				<img src="../../assets/icon-lens.svg" width="100" style="margin:auto;">
				<h1>聯絡我們</h1>
				<p style="margin:0;">我們很樂意回答您的問題，歡迎您填妥表格，我們將盡速回覆您， 或電洽、 email 給我們，將會有專人為您服務。</p>
			</template>
			<contact-us-form @cancel="dialogVisible = false" @done="dialogVisible = false" />
		</el-dialog>
	</ContentBlock>
</template>

<script>
import ContactUsForm from "../ContactUsForm.vue"
export default {
	components: { ContactUsForm },
	data() {
		return {
			dialogVisible: false,
		}
	}
}
</script>