<template>
	<ContentBlock>
		<h1 class="center">多元合作模式</h1>
		<br>
		<template v-if="$root.rwd < 2">
			<template v-for="(item, i) in list">
				<Card :data="item" :key="i" />
			</template>
		</template>
		<Siema ref="siema" v-else :data="list" :options="{ loop: true  }" control>
			<template v-slot="{slide}">
				<Card :data="slide" />
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
	</ContentBlock>
</template>

<script>
import Card from "./Card.vue"
import Siema from "../Siema.vue";
export default {
	components: {
		Card,
		Siema,
	},
	data() {
		return {
			list: [{
				img: require("../../assets/partners/img1.png"),
				title: "共築 DFC 種子學校",
				description: "共同攜手打造一或多所學校成為 DFC 種子學校，支持協會入校，為期一年，透過長期的陪伴與共同備課，與全校教師一同產出，一星期一堂，富有學校特色的 DFC 學習法課程。培養出更多創意且具人文精神的小小改變實踐者，將改變的影響力持續注入於教育現場。",
				cases: [{
					text: "財團法人花仙子教育基金會",
					url: "https://www.facebook.com/farcentfound/",
				}, {
					text: "財團法人英仕教育基金會",
					url: "https://www.facebook.com/英仕教育基金會-617464291655910/?ref=page_internal",
				}, {
					text: "陳永泰公益信託",
					url: "https://www.auroratrust.org.tw",
				}, {
					text: "財團法人國富文教基金會",
				}]
			}, {
				img: require("../../assets/partners/img2.png"),
				title: "攜手推動專案與活動，如  DFC 挑戰與分享季",
				description: "支持協會所辦理的各式活動與專案，提供軟硬體資源如：人力支援、拍攝器材、宣傳廣告版位、平面設計製作，幫助協會經營品質完善與全面，藉由協助露出企業 / 基金會品牌，建立企業良好形象落實社會責任，創造出雙贏的加乘效果。",
				cases: [{
					text: "財團法人永齡教育慈善基金會",
					url: "http://www.yonglin.org.tw",
				}, {
					text: "財團法人鴻海教育基金會",
					url: "https://www.foxconnfoundation.org",
				},]
			}, {
				img: require("../../assets/partners/img3.png"),
				title: "支持公益系列演講／工作坊",
				description: "邀請您與我們一起參與這場全球教育浪潮，支持協會專業講師至（指定區域）學校／單位執行免費演講／工作坊，以利 DFC 學習法的推廣與宣傳，讓我們的足跡遍佈全臺灣 22 縣市，持續將創新教育的影響力向外擴散！",
				cases: [{
					text: "公勝保險經紀人股份有限公司",
					url: "https://www.goldennet.com.tw/",
				}, {
					text: "台新國際商業銀行",
					url: "https://www.taishinbank.com.tw",
				}, {
					text: "新竹物流股份有限公司",
					url: "https://www.hct.com.tw",
				}, {
					text: "國泰慈善基金會",
					url: "https://patron.cathaylife.com.tw/ODAY/F0/ODAYF000",
				},]
			}, {
				img: require("../../assets/partners/img4.png"),
				title: "支持研發 DFC 學習法相關教材",
				description: "投注資源於協會開發各式議題之教材，如聯合國永續發展目標(SDGs) 17 項指標所含涵蓋的三大面向：社會進步、地方共融、環境保衛等議題，提供教師一個教學鷹架，協助全臺中小學教師將 DFC 學習法融入課堂中，帶領孩子達到教學目標，體現出教學的本質。",
				cases: [{
					text: "財團法人中華民國佛教慈濟慈善事業基金會",
					url: "http://visionfuture.org.tw",
				}, {
					text: "翰林出版事業股份有限公司",
					url: "https://www.hle.com.tw",
				},]
			}, {
				img: require("../../assets/partners/img5.png"),
				title: "引領青年投入教育議題",
				description: "邀請您匯聚協會營運之資源，於協會專業的核心夥伴，驅動新世代的力量投入。從多元的專業角度為臺灣教育耕耘，提供穩定、優質且完善的支持系統，與我們一同陪伴深深影響每一位孩子的教育實踐者，成為教育改革的最佳推手。",
				cases: [{
					text: "財團法人江許笋文教基金會",
					url: "https://www.chianghsusun.org.tw/",
				},]
			}, {
				img: require("../../assets/partners/img6.png"),
				title: "成為公益合作推手",
				description: "以線上線下的公益行銷合作，融入共同經營理念，將每一份感動透過與您合作之商品，傳遞給每位消費者。達成建立企業的品牌形象，同時鼓勵大眾參與社會公益。透過捐贈產品所得之部分利潤或消費紅利回饋，提升團隊的影響力，獲得多方的支持。",
				cases: [{
					text: "Be Together 巴一起咖啡",
					url: "https://www.betogether.com.tw/product-category/4-2-1/",
				}, {
					text: "台北旅店集團",
					url: "https://www.taipeiinn.com.tw",
				}, {
					text: "奉茶行動 Water Refill Map",
					url: "https://www.circuplus.org/product/miir_dfc/?utm_source=DFCTaiwan&utm_medium=partner&utm_campaign=DFCearthday&fbclid=IwAR0aGdXRHmz80q-yXqjcetEe73vATwkGGo7o5Gbgc3DACI4o4skgiUUbTvI)",
				},]
			},],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	--var-indicator-color: black;
	.right {
		right: 30% !important;
		// transform: none !important;
	}
	.left {
		left: 30% !important;
		// transform: none !important;
	}
	.right,
	.left {
		top: 100% !important;
	}
}
</style>