<template>
	<el-aside width>
		<router-link to="/">
			<img src="../../assets/logo.png" width="150" />
		</router-link>
		<br />
		<ul>
			<template v-for="(o, i) in list">
				<recursive
					v-model="list[i]"
					:path="`/manage${o.path ? '/' + o.path : ''}`"
					:key="i"
				/>
			</template>
		</ul>
		<br />
		<br />
		<br />
		<br />
	</el-aside>
</template>

<script>
export default {
	components: {
		Recursive: () => import("./Recursive"),
	},
	data() {
		return {
			list: [
				{
					label: "首頁",
					path: "home",
				},
				{
					label: "最新消息",
					path: "news",
				},
				{
					label: "關於協會",
					path: "about",
				},
				{
					label: "服務預約意願蒐集表",
					path: "speech-workshop",
				},
				{
					label: "媒體報導",
					path: "media-center",
				},
				// {
				// 	label: "故事分類",
				// 	path: "story-category",
				// },
				{
					label: "改變世界的故事",
					path: "story",
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.el-aside {
	padding: 1em;
	font-size: 1.2em;
}
</style>
