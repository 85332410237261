import firebase from "../../database/libs/firebase";
import "firebase/firestore";
import { tz } from "moment-timezone";
import Categories from "../../database/models/categories";

const db = firebase.firestore();
const _doc = db.collection("references").doc("categories");

const converter = {
	toFirestore: function (data = new Object) {
		return data.toFirestore && data.toFirestore() || data;
	},
	fromFirestore: function (snapshot, options) {
		const data = snapshot.data(options);
		return new Categories(data)
	}
}

export default {
	namespaced: true,
	state: {
		_categories: new Categories,
		_watcher: null,
	},
	actions: {
		_watchCategories({ state }) {
			return new Promise((resolve, reject) => {
				if (!state._watcher) {
					state._watcher = _doc
						.withConverter(converter)
						.onSnapshot(snapshot => {
							if (snapshot.exists) state._categories = snapshot.data();
							resolve(state._watcher);
						}, error => {
							console.error(error);
							reject(error);
						});
				} else {
					resolve(state._watcher);
				}
			});
		},
		_updateCategories({ }, data) {
			return new Promise((resolve, reject) => {
				_doc
					.withConverter(converter)
					.set(data, { merge: true })
					.then(resolve)
					.catch(reject);
			});
		}
	},
}