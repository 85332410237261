import firebase from "../../database/libs/firebase";
import "firebase/firestore";
import { cloneDeep } from "lodash";

const db = firebase.firestore();
const _doc = db.collection("references").doc("tags");

export default {
	namespaced: true,
	state: {
		_tags: new Array,
		_watcher: null,
	},
	actions: {
		_watchTags({ state }) {
			return new Promise((resolve, reject) => {
				if (!state._watcher) {
					state._watcher = _doc
						.onSnapshot(snapshot => {
							if (snapshot.exists) state._tags = snapshot.data().list;
							resolve(state._watcher);
						}, error => {
							console.error(error);
							reject(error);
						});
				} else {
					resolve(state._watcher);
				}
			});
		},
		_updateTags({ }, list) {
			return new Promise((resolve, reject) => {
				_doc.set({ list }, { merge: true })
					.then(resolve)
					.catch(reject);
			});
		}
	},
}