<template>
	<section id="visit-riverside" bg-color="white2">
		<TopBanner>
			<img src="../../assets/visit-riverside/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/visit-riverside/title.png" class="page-title" width="654">

		<!-- section 1 -->
		<ContentBlock class="col">
			<p>電影《三個傻瓜》那所令人嚮往的學校，原來真實存在！
				<br>Design For Change 的創辦人 Kiran Bir Sethi 同時也是印度河濱學校（Riverside School）的創辦人暨校長。原本只是因為她的兒子在上學時因為老師教學理念太過死板，所以希望為兒子找到一所好學校，結果最後卻因此創辦了河濱學校。因其與眾不同的教育理念，河濱學校後來甚至被電影《三個傻瓜》的編劇寫進了電影劇本裡喔！
				<br>
				<br>河濱學校的教學理念與方式突破教育框架，不但用不同的方式看見孩子的無限潛能，甚至連校長室都沒有！因為收到許多教育工作者要求能夠透過短期進修吸收河濱學校教育模式與課程設計理念精華，並帶回至自己的學校中，為此，河濱學校進修中心（Riverside Learning Center, 簡稱RLC）於 2006 年時成立，讓有興趣的教育者都能前往河濱學校參訪、上課。
			</p>
			<br>
			<div class="row-grid" gutter-x="20" gutter-y="20" align-y="center">
				<img class="rwd-s-6" src="../../assets/visit-riverside/img1.png">
				<img class="rwd-s-6" src="../../assets/visit-riverside/img2.png">
			</div>
		</ContentBlock>

		<!-- section 2 -->
		<ContentBlock clip bg-color="red3" color="white1" style="line-height:1.7;">
			<h1>申請流程圖</h1>
			<br>
			<div class="center">
				<img src="../../assets/visit-riverside/flow.png" width="800">
			</div>
			<br>
			<br>
			<ol style="margin-bottom:0;">
				<li>需提前於預訂出發日期前 5 個月填寫申請表單。</li>
				<li>DFC 臺灣團隊與您聯絡確認細節，確認需求。</li>
				<li>DFC 臺灣將報名資訊傳給印度河濱學校安排。</li>
				<li>由 DFC 臺灣團隊轉達印度河濱學校的參訪、進修申請成功與否。</li>
			</ol>
			<pre>&nbsp;★ 印度河濱學校擁有調整相關條件與相關參訪、進修規定之決定權。</pre>
		</ContentBlock>

		<!-- seciont 3 -->
		<ContentBlock clip bg-color="white1">
			<h1>Study Tour 教育參訪內容介紹</h1>
			<p>．兩天的沉浸式參訪，透過工作坊、觀課、利益關係者的會議和諮詢服務，可以讓參訪者了解河濱學校的教育哲學，一窺「以人為本」的學校容貌，最終得以將得到的啟發帶回自己的校園。
				<br>．以兩天的參訪課程為主，如有需要可以延長至三天。
				<br>．參訪課程全程為英文課程。
				<br>．河濱學校公告之行程選擇及價格（僅為課程費用不包含機票與食宿）：
				<br>2 天｜5 小時/天｜600美元/人｜人數限制：5-10 人
				<br>3 天｜5 小時/天｜850美元/人｜人數限制：5-10 人
			</p>
		</ContentBlock>

		<!-- section 4 -->
		<ContentBlock clip bg-color="green1">
			<div class="row-grid" align-x="center" align-y="center" gutter-y="20">
				<img class="rwd-m-2 rwd-s-3 rwd-xs-6" src="../../assets/visit-riverside/book.png">
				<div class="rwd-m-10 rwd-s-9 col">
					<p style="line-height:1.7; color:#1d1e1f;">還不太確定河濱學校是不是你想要參訪的地方嗎？沒關係！DFC 臺灣的發起人許芯瑋與上官良治已經親自去過，並且將他們在河濱學校的所見所聞寫成了《報告！這裡沒有校長室》一書。書裡詳細寫到了他們到河濱學校的教學方式及參與觀課的感受，讓大家能夠不出國門就能先領略到河濱學校的風采。</p>
					<br>
					<DfcButton class="rwd-m-6 rwd-s-10" theme="darkblue" round arrow href="https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=8" target="_blank">購買《報告！這裡沒有校長室》</DfcButton>
				</div>
			</div>
		</ContentBlock>

		<!-- section 5 -->
		<ContentBlock>
			<h1>FAQ</h1>
			<ol id="qa">
				<template v-for="(item, i) in list">
					<li :key="i">
						<div class="col" style="width:100%;">
							<p class="q">{{item.q}}</p>
							<p class="a">{{item.a}}</p>
						</div>
					</li>
				</template>
			</ol>
			<br>
			<div class="center">
				<DfcButton class="rwd-s-8 rwd-m-6 rwd-l-4" theme="red" round arrow href="https://form.jotform.com/200568141422446" target="_blank">填寫參訪河濱學校申請表</DfcButton>
			</div>
		</ContentBlock>

	</section>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					qn: "Q1",
					q: "我不是教育者，可以申請參訪嗎？",
					a: "課程沒有硬性要求必須是老師或者教育相關人員才能參加，但是課程內容因為都是為老師而設計的，所以內容會更適合老師。當然即使你不是老師，卻對教育抱持著滿腔的熱情，也歡迎前往參訪喔！",
				},
				{
					qn: "Q2",
					q: "我該如何申請？",
					a: "填寫好申請表單，DFC 臺灣團隊將會在七個工作天內聯絡您，確認後續相關事項。待申請成功後，請自行準備旅遊文件、住宿、交通、通訊、兌幣，如需印度河濱學校協助，請於申請表單內勾選相關選項。",
				},
				{
					qn: "Q3",
					q: "方案都不符合我的需求怎麼辦？",
					a: "如果您沒有參訪過河濱學校的話，建議您先選擇2至3日的教育參訪。若已參訪過，可在填寫申請時選擇客制化服務，我們會在一個星期內聯絡您，確認具體需求後，我們能協助您判斷是否能夠成行。",
				},
				{
					qn: "Q4",
					q: "這是由 DFC 臺灣帶團的參訪活動嗎？",
					a: "不是，DFC 臺灣目前不提供帶團服務，此申請服務僅為 DFC 臺灣團隊提供您與印度河濱學校參訪、進修接洽之協助。",
				},
			],
		}
	}
}
</script>

<style lang="scss" scoped>
h1 {
	text-align: center;
}
#qa {
	list-style: none;
	counter-reset: num;
	padding-inline-start: 0;
	line-height: 1.7;
	li {
		display: flex;
		align-items: baseline;
		counter-increment: num;
		margin-bottom: 0.5em;
		&::before {
			content: "Q" counter(num);
			display: block;
			width: 2.5em;
			height: 2.5em;
			border-radius: 100%;
			background: #fff;
			flex-shrink: 0;
			margin-right: -0.5em;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2em;
		}
	}
	.q,
	.a {
		padding: 5px 1em;
		border-radius: 3px;
	}
	.q {
		background: #fff;
	}
	.a {
		background: rgba($color: $red, $alpha: 0.2);
	}
}
</style>

