<template>
	<section class="col">
		<div class="center">
			<router-link data-depth="2" to="/">
				<el-link type="primary">
					<h3>回首頁</h3>
				</el-link>
			</router-link>
		</div>
		<br>
		<div ref="scene1" class="center">
			<img data-depth="0.2" src="../assets/404.svg" width="300">
		</div>
		<div ref="scene2" class="center">
			<h1 data-depth="0.5">Page not found</h1>
		</div>
		<div ref="scene3" class="center">
			<h2 data-depth="0.3">無法瀏覽頁面</h2>
		</div>
	</section>
</template>

<script>
import Parallax from "parallax-js";
export default {
	mounted() {
		new Parallax(this.$refs["scene1"]);
		new Parallax(this.$refs["scene2"]);
		new Parallax(this.$refs["scene3"]);
	}
}
</script>

<style lang="scss" scoped>
section {
	padding: 5%;
	width: 100%;
	height: 100%;
}
</style>