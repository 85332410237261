import Joi from "joi";

const schema = Joi.object({
	id: Joi.string().error(new Error("Story ID not found")),
	account: Joi.string().error(new Error("故事編號")),
	thumb: Joi.string().allow("").error(new Error("封面照片")),
	pictures: Joi.array().items(Joi.string()).error(new Error("照片")),
	video: Joi.string().allow("").error(new Error("[影片] URL")),
	document: Joi.string().allow("").error(new Error("[手冊] URL")),
	description_feeling: Joi.string().allow("").error(new Error("感受")),
	description_imagine: Joi.string().allow("").error(new Error("想像")),
	description_implementation: Joi.string().allow("").error(new Error("實踐")),
	description_sharing: Joi.string().allow("").error(new Error("分享")),
	name: Joi.string().allow("").error(new Error("故事名稱")),
	year: Joi.number().error(new Error("年度")),
	school: Joi.string().allow("").error(new Error("單位名稱")),
	counselors: Joi.array().items(Joi.string()).error(new Error("[輔導員]")),
	team_members: Joi.array().items(Joi.string()).error(new Error("[小推手]")),
});

export default function Story(initData = new Object) {
	const { value, error, warning } = schema.validate(initData, { stripUnknown: true });
	try {
		if (error) throw error;
		if (warning) console.warn(warning);
		return {
			id: initData.id || "",
			account: initData.account || "",
			thumb: initData.thumb || "",
			pictures: initData.pictures || new Array,
			video: initData.video || "",
			document: initData.document || "",
			description_feeling: initData.description_feeling || "",
			description_imagine: initData.description_imagine || "",
			description_implementation: initData.description_implementation || "",
			description_sharing: initData.description_sharing || "",
			name: initData.name || "",
			year: initData.year || new Date().get,
			school: initData.school || "",
			counselors: initData.counselors || new Array,
			team_members: initData.team_members || new Array,
		}
	} catch (error) {
		console.log({ error });
	}
}