<template>
	<div
		ref="collapsible"
		class="collapsible"
		:style="{
			maxHeight: collapsed
				? collapsedHeight
				: `${$refs.collapsible.scrollHeight}px`,
		}"
	>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		collapsed: {
			type: Boolean,
			default: () => false,
		},
		minHeight: {
			type: [String, Number],
			default: () => "0px",
		},
	},
	computed: {
		collapsedHeight() {
			return typeof this.minHeight === "string" && this.minHeight.includes("px")
				? this.minHeight
				: this.minHeight + "px";
		},
	},
};
</script>

<style lang="scss" scoped>
.collapsible {
	overflow: hidden;
	transition: 0.5s;
}
</style>
