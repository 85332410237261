<template>
	<ContentBlock>
		<h1>FAQ</h1>
		<template v-for="(item, i) in list">
			<div :key="i" class="col">
				<h3 v-if="item.category">{{item.category}}</h3>
				<div class="q row" align-x="between" align-y="center" @click="handleClick(i)">
					<span>{{item.question}}</span>
					<i :class="`el-icon-${item.active ? 'minus' : 'plus'}`">
						<template v-if="$root.rwd < 2">點選{{item.active ? "縮合" : "展開"}}</template>
					</i>
				</div>
				<el-collapse-transition>
					<div v-show="item.active">
						<br>
						<div class="a">
							<br>
							<div class="a-inner" v-html="item.answer" />
							<br>
						</div>
					</div>
				</el-collapse-transition>
				<br>
			</div>
		</template>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			currentIndex: 0,
			list: [
				{
					question: "完成 DFC 挑戰的必要繳交資料有哪些？",
					answer: `DFC 挑戰學習單／DFC 學習法四步驟照片／師生中英文姓名（DFC 國際證書資料）`,
				},
				{
					question: "我該怎麼跟家長溝通要帶孩子參加 DFC 挑戰？",
					answer: `我們有提供「故事資料授權同意書」給您作參考及修改。輔導員可以在與家長面對面的場合（如：親師座談會），以歷屆 DFC 挑戰故事向家長說明接下來會與孩子們一同經歷的歷程。<br><br><small>※ 溫馨提醒：根據創用 CC 授權規定，我們需要在每則故事標示創作者的姓名，以給予適當表彰。當您提供給我們「故事資料授權同意書」，意即您同意將孩子的故事授權給 DFC 臺灣分享、傳播。</small>`,
				},
				{
					question: "可以有多位成人擔任 DFC 輔導員嗎？",
					answer: `當然沒問題，建議由主要的輔導員建立好 DFC 挑戰組別後，凝聚更多的輔導員一同加入！<br><br><small>※ 建議輔導員們（可以是老師、家長、自學團老師等有意願的大人們）可以參照「DFC挑戰學習單做為課程架構，一步一步地帶學生完成 DFC 學習法四步驟的探究與行動歷程。</small>`,
				},
				{
					question: "可以讓學生自己登入 DFC 挑戰平台填寫資料嗎？",
					answer: `當然沒問題，若學生熟悉打字及電腦操作，可以分配給學生自行輸入平台資料。`,
				},
				{
					question: "一定要製作影片嗎？",
					answer: `若老師與學生決定要在年度「DFC 挑戰分享季」分享此次 DFC 挑戰的執行歷程，需要有一支完整的影片，來告訴大家「你們的 DFC 學習法四步驟歷程」是如何實踐你們的創意解方喔！`,
				},
			]
		}
	},
	methods: {
		handleClick(index) {
			this.list.forEach((item, i) => {
				this.$set(this.list[i], "active", i == index ? !item.active : false);
			});
		}
	},
}
</script>

<style lang="scss">
#dfc-challenge {
	.a-inner {
		a {
			text-decoration: underline;
			color: $red;
		}
	}
}
</style>

<style lang="scss" scoped>
h1 {
	text-align: center;
}
h2,
h3 {
	font-size: 1.2em;
	text-align: left !important;
}
h3 {
	color: #ff755d;
	@include left-line-title(#ff755d);
}
.q {
	background: #fff;
	padding: 0.6em 1em;
	cursor: pointer;

	i {
		color: $red;
		white-space: nowrap;
	}
}
.a {
	background: rgba(255, 194, 20, 0.3);
}
.a,
.q {
	border-radius: 6px;
	font-size: 1.2em;
}
.a-inner {
	padding: 0 1em;
	margin: 0;
}
</style>