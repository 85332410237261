<template>
	<button class="dfc-button" :disabled="disabled">
		<div class="backgrounded">
			<div class="slot">
				<slot />
			</div>
			<i class="el-icon-right" style="font-size:1.5em;" />
		</div>
		<template v-if="!disabled">
			<router-link class="anchor" v-if="to" :to="to" />
			<a class="anchor" v-else-if="href" :href="href" :target="target" />
			<span class="anchor" v-else-if="scrollTo" @click="scroll(scrollTo)" />
		</template>
	</button>
</template>

<script>
export default {
	props: {
		disabled: Boolean,
		to: String,
		href: String,
		target: String,
		scrollTo: String
	},
	methods: {
		emit(e) {
			if (!this.disabled) this.$emit(e.type, e);
		},
		scroll(target) {
			const el = target instanceof HTMLElement
				? target
				: document.getElementById(target.replace("#", ""));
			const fixedMenu = document.getElementById("fixed-menu");
			if (el) scrollTo({ top: el.offsetTop - fixedMenu.offsetHeight, behavior: "smooth" });
		},
	},
	mounted() {
		["click", "touchstart", "touchend", "touchmove"].forEach(e => {
			this.$el.addEventListener(e, this.emit);
		});
	},
}
</script>

<style lang="scss" scoped>
$colors: (
	"red": $red,
	"green": $green,
	"darkblue": $darkblue,
	"yellow": $yellow,
	"blue": $blue,
);
$text-white: ("red", "darkblue", "blue");
.slot {
	flex: 1;
	flex-shrink: 0;
	justify-content: center;
}
.slot,
.backgrounded {
	display: flex;
	align-items: center;
	align-content: center;
}
.dfc-button {
	position: relative;
	display: block;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
	padding: 0;
	outline: none;
	border: none;
	font-size: inherit;
	border-radius: 5px;
	overflow: hidden;
	background: #fff;
	color: #000;
	transition: 0.3s;
	@each $theme-name, $color in $colors {
		&[theme="#{$theme-name}"] {
			border-color: $color;
			.backgrounded {
				background: $color;
			}
		}
	}
	@each $theme-name in $text-white {
		&[theme="#{$theme-name}"] {
			&:not([plain]) {
				color: #fff;
			}
			&:hover[plain] {
				@media (hover: hover) {
					color: #fff;
				}
			}
		}
	}
	&:hover:not([disabled]) {
		@media (hover: hover) {
			box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.5);
		}
	}
	&:not([arrow]) {
		i {
			display: none;
		}
	}
	&[plain]:not(:hover) {
		.backgrounded {
			background: transparent;
		}
	}
	&[bordered],
	&[plain] {
		border-width: 1px;
		border-style: solid;
	}
	&[round] {
		border-radius: 999px;
	}
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.6;
	}
	&[arrow] {
		.slot {
			padding-right: 2em;
		}
		i {
			margin-right: -1em;
			margin-left: -1em;
		}
		&[small] {
			.slot {
				padding-right: 1em;
			}
			i {
				margin-right: -0.5em;
				margin-left: -0.5em;
			}
		}
	}
	&[small] {
		font-size: 0.8em;
		.backgrounded {
			padding: 0.5em 1em;
		}
	}
	.backgrounded {
		transition: background 0.3s;
		padding: 0.6em 2em;
	}
}
.anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>