import firebase from "../libs/firebase";
import "firebase/storage";
import compressor from "compressorjs";

const storage = firebase.storage();

class Instance {
	constructor() { }

	async uploadFile({ path, file, progress, error, complete }) {
		const storageRef = storage.ref(path);
		const task = storageRef.put(
			// 壓縮
			await new Promise((resolve, reject) => {
				new compressor(file, {
					quality: 0.6,
					success: resolve,
					error(err) {
						console.log("[compress file error]", err.message);
						reject(err);
					},
				});
			})
		);
		// 監聽上傳進度
		task.on(firebase.storage.TaskEvent.STATE_CHANGED,
			function (snapshot) {
				progress && progress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			},
			error,
			function () {
				task.snapshot.ref.getDownloadURL().then(url => complete && complete(url))
			}
		);
	}

	deleteFolder(path) {
		const storageRef = storage.ref(path);
		return new Promise((resolve, reject) => {
			storageRef
				.listAll()
				.then(res => {
					res.items.forEach(async ref => await ref.delete());
					resolve();
				})
				.catch(err => reject(err));
		})
	}

	deleteFile(path) {
		const storageRef = storage.ref(path);
		return new Promise((resolve, reject) => {
			storageRef
				.delete()
				.then(() => resolve())
				.catch(err => reject(err))
		})
	}
}

export default new Instance;