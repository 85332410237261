import firebase from "../../database/libs/firebase";
import "firebase/firestore";

const db = firebase.firestore();
const _aboutRef = db.collection("about");

export default {
	namespaced: true,
	state: {
		_achievementReport: {
			list: new Array
		}
	},
	actions: {
		_onDataChange({ state }, doc) {
			return new Promise(resolve => {
				_aboutRef.doc(doc)
					.onSnapshot(snapshot => {
						if (snapshot.exists) state[`_${doc.toCamelCase()}`] = snapshot.data();
						resolve(snapshot.data());
					})
			})
		},
		_getData({ state }, doc) {
			return new Promise(resolve => {
				_aboutRef.doc(doc)
					.get()
					.then(snapshot => {
						if (snapshot.exists) state[`_${doc.toCamelCase()}`] = snapshot.data();
						resolve(snapshot.data());
					})
					.catch(err => reject(err));
			});
		},
		_updateData({ }, { doc, data }) {
			return new Promise(resolve => {
				_aboutRef.doc(doc)
					.set(data, { merge: true })
					.then(() => resolve(data))
					.catch(err => reject(err));
			});
		}
	},
}