<template>
	<section style="margin:auto; max-width:1200px; padding:5%;">
		<el-select v-model="selectSDGs" clearable placeholder="聯合國永續目標 SDGs 分類" @change="handleGetList()">
			<template v-for="(item, i) in SDGsOptions">
				<el-option :label="`${i + 1}. ${item}`" :value="item" :key="item" />
			</template>
		</el-select>
		<el-button @click="selectSDGs = ''; handleGetList()">清空</el-button>
		&nbsp;
		<el-select :value="params.year" placeholder="選擇屆數" @change="handleSessionChange">
			<template v-for="option in yearOptions">
				<el-option :value="option.year" :key="option.year">{{option.year}}（{{ option.label }}）</el-option>
			</template>
		</el-select>
		<br>
		<br>
		<section v-loading="loading" element-loading-background="rgba(255, 242, 236, 0.8)" class="row-grid">
			<div v-for="item in _stories" class="rwd-m-3 rwd-s-6" :key="item.id" style="padding:10px;">
				<el-card :body-style="{ padding: 0 }" shadow="hover">
					<router-link :to="`/story/${year}/${item.id}`">
						<el-image class="center" :src="item.thumb || getRandomImage()" fit="cover" lazy>
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
						<table>
							<tr v-if="item.account.includes('_M')">
								<td align="right">
									<el-tag type="warning" size="mini">第15屆小學生公益行動故事</el-tag>
								</td>
							</tr>
							<tr>
								<th>{{ item.account }}</th>
							</tr>
							<tr>
								<th>{{ item.name }}</th>
							</tr>
							<tr>
								<td>學校單位：{{ item.school }}</td>
							</tr>
						</table>
					</router-link>
				</el-card>
			</div>
		</section>
		<div v-if="_cursor" class="center">
			<DfcButton arrow round theme="red" @click="handleGetMore()">取得更多</DfcButton>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CommingSoon from "../../components/CommingSoon.vue";
import yearOptions from "@/assets/year-options.json";
import SDGsOptions from "@/assets/SDGs.json";
import { getRandomIntInclusive } from "@/utils/random";
export default {
	components: {
		CommingSoon,
	},
	props: ["year"],
	data() {
		return {
			loading: false,
			searchText: "",
			SDGsOptions,
			selectSDGs: "",
			yearOptions,
		}
	},
	computed: {
		...mapState("story", [
			"_cursor",
			"_stories",
		]),
		params() {
			return {
				year: this.year,
			}
		},
		queries() {
			return this.selectSDGs ? [
				["sdgs", "array-contains", this.selectSDGs]
			] : []
		},
	},
	methods: {
		...mapActions("story", [
			"_getListByQueriesWithPagination",
			"_getMoreByQueriesWithPagination",
		]),
		async handleGetList() {
			this.loading = true;
			await this._getListByQueriesWithPagination({
				params: this.params,
				queries: this.queries,
			});
			this.loading = false;
		},
		async handleGetMore() {
			this.loading = true;
			await this._getMoreByQueriesWithPagination({
				params: this.params,
				queries: this.queries,
			});
			this.loading = false;
		},
		handleSessionChange(year) {
			this.$router.push(`/story/${year}`);
		},
		getRandomImage() {
			return [
				require("../../assets/story/thumb1.jpg"),
				require("../../assets/story/thumb2.jpg"),
				require("../../assets/story/thumb3.jpg"),
				require("../../assets/story/thumb4.jpg"),
			][getRandomIntInclusive(0, 3)]
		}
	},
	created() {
		if (!this._stories.length) this.handleGetList();
	},
	async beforeRouteUpdate(to, from, next) {
		if (from.path != to.path) {
			next();
			this.loading = true;
			await this._getListByQueriesWithPagination({
				params: to.params,
				queries: this.queries,
			});
			this.loading = false;
		}
	}
}
</script>

<style lang="scss" scoped>
.el-card {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	background: transparent;
	border: none;

	.el-image {
		width: 100%;
		height: 150px;
		background: #eaeaea;
	}

	table {
		width: 100%;
		margin-top: 5px;
		border-collapse: separate;
		border-spacing: 0 3px;
	}

	th {
		text-align: left;
	}

	td {
		vertical-align: top;
		font-size: small;

		&:first-child:not(:last-child) {
			white-space: nowrap;
			// text-align: right;
		}
	}
}
</style>