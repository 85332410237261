import Vue from "vue"
import Vuex from "vuex"

import home from "./modules/home";
import news from "./modules/news";
import about from "./modules/about";
import tag from "./modules/tag";
import category from "./modules/category";
import speech_workshop from "./modules/speech-workshop";
import media_center from "./modules/media-center";
import story from "./modules/story";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		home,
		news,
		about,
		tag,
		category,
		"media-center": media_center,
		"speech-workshop": speech_workshop,
		story,
	}
})
