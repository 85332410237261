import firebase from "../libs/firebase";
import "firebase/auth";

const auth = firebase.auth();

class Instance {
	constructor() {
		this.user = null;
	}

	async createUserWithEmailAndPassword(email, password) {
		return await auth.createUserWithEmailAndPassword(email, password);
	}

	onAuthStateChanged(callback) {
		return new Promise((resolve, reject) => {
			auth.onAuthStateChanged(user => {
				this.user = user;
				callback && callback(user);
				auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);	// 設定只有在「目前的瀏覽器 Tab」保持登入
				resolve(user);
			}, err => reject(err));
		})
	}

	async signInWithEmailAndPassword(email, password) {
		return await auth.signInWithEmailAndPassword(email, password);
	}

	async signOut() {
		return await auth.signOut();
	}
}

export default new Instance;