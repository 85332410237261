<template>
	<el-card>
		<h1><small>{{data.text}}</small></h1>
		<img :src="data.banner" width="100%">
		<h2>主題</h2>
		<template v-if="typeof data.topic == 'string'">
			<p>{{data.topic}}</p>
		</template>
		<ul v-else>
			<template v-for="(o, i) in data.topic">
				<recursive v-model="data.topic[i]" :key="i" />
			</template>
		</ul>
		<template v-if="data.outline">
			<h2>大綱</h2>
			<ul>
				<template v-for="(o, i) in data.outline">
					<recursive v-model="data.outline[i]" :key="i" />
				</template>
			</ul>
		</template>
		<h2>講師</h2>
		<p>{{data.lecturer}}</p>
		<h2>時間</h2>
		<p>{{data.time}}</p>
		<h2>規格、場地設備需求</h2>
		<ul>
			<template v-for="(o, i) in data.details">
				<recursive v-model="data.details[i]" :key="i" />
			</template>
		</ul>
		<h2>費用</h2>
		<ul>
			<template v-for="(o, i) in data.fee">
				<recursive v-model="data.fee[i]" :key="i" />
			</template>
		</ul>
		<h2>邀約時間</h2>
		<p v-html="data.inviteTime" />
		<h2>邀約建議</h2>
		<p>{{data.advice}}</p>
		<h2>溫馨提醒</h2>
		<ul>
			<template v-for="(o, i) in data.suggestion">
				<recursive v-model="data.suggestion[i]" :key="i" />
			</template>
		</ul>
		<br>
		<a class="center" :href="data.link" target="_blank">
			<!-- <DfcButton class="rwd-m-4 rwd-s-6" theme="red" round arrow @click="handleScroll()">填寫表單</DfcButton> -->
			<DfcButton class="rwd-m-4 rwd-s-6" theme="red" round arrow>{{data.linkText}}</DfcButton>
		</a>
		<br>
	</el-card>
</template>

<script>
import Recursive from "./Recursive.vue";
export default {
	components: {
		Recursive,
	},
	props: ["data"],
	methods: {
		handleScroll() {
			const fixedMenu = document.getElementById("fixed-menu");
			const el = document.getElementById("section4");
			if (el) scrollTo({ top: el.offsetTop - fixedMenu.offsetHeight, behavior: "smooth" });
		}
	}
}
</script>

<style lang="scss" scoped>
.recursive {
	&:not(.is_list):not(.is_empty) {
		&::marker {
			content: "｜";
			font-weight: bold;
		}
	}
}
.el-card {
	h1 {
		text-align: center;
		margin-top: 0;
	}
	h2 {
		color: $red;
		margin-bottom: 0;
		margin-top: 1em;
	}
}
</style>