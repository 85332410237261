<template>
	<ContentBlock>
		<template v-if="$root.rwd > 1">
			<StickyMenu2 v-model="buttonList" @change="handleChange">
				<template slot-scope="{ item, index, callback }">
					<div class="center" style="padding: 5px; flex: 1;">
						<DfcButton
							class="rwd-12"
							@click="
								callback();
								visible = [index];
							"
							:theme="visible.includes(index) ? 'red' : ''"
							>{{ item.text }}</DfcButton
						>
					</div>
				</template>
			</StickyMenu2>
			<br />
		</template>
		<div class="row-grid" gutter-x="40">
			<template v-for="(item, i) in list">
				<div class="rwd-m-6" :key="i" v-show="visible.includes(i)">
					<Card :data="item" />
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
import StickyMenu2 from "../StickyMenu2.vue";
import Card from "./Card.vue";
export default {
	components: {
		StickyMenu2,
		Card,
	},
	data() {
		return {
			visible: [0, 1],
			buttonList: [{ text: "演講" }, { text: "工作坊" }],
			list: [
				{
					linkText: "邀約演講",
					link: "https://form.jotform.me/82461332886462",
					text: "演講",
					banner: require("@/assets/speech-workshop/img1.png"),
					topic: "你的力量 超乎想像（講師亦可能依需求另訂定更貼切的主題）",
					outline: [
						"話說從前：DFC 學習法起源，其核心價值和發展脈絡",
						"經典重現：DFC 國際及臺灣歷年來國內外參與創意行動挑戰的案例分享",
						"實戰演練：設計思考初體驗，實際操作 DFC 學習法的四步驟－感受、想像、實踐、分享",
						"協會服務：聽完充滿熱情的演講後，協會會提供什麼樣的服務以協助貴單位推動 DFC 學習法呢？",
						"成為改變：你覺得這個世界需要什麼人呢，讓我們一起成為你想看見的改變！",
						"",
						"",
						"",
						"",
						"",
						"",
					],
					lecturer: "依協會安排 1 位講師",
					time: "90 分鐘（加上設備測試等行政時間，共 120 分鐘）",
					details: [
						"對象：學校教師／主任／校長／家長／企業",
						"參與人數：建議 30 人以上",
						"場地設備：任一聽講空間（含可書寫之桌面）、投影設備、麥克風以及可接電腦之音響喇叭",
						"",
						"",
						"",
					],
					fee: [
						"講師費：",
						[
							"學校：新臺幣 4,000／2 小時（含行政時間，不含交通費）",
							"非營利機構：新臺幣 6,000／2 小時（含行政時間，不含交通費）",
							"企業或其他單位：請填寫詳細聯絡資訊，我們將主動與您聯繫",
						],
						"交通費：\n實報實銷，敬請貴單位協助提供車站或機場至貴單位之中／短程接駁服務",
						"",
					],
					inviteTime:
						"協會僅接受請於<span color='red4'>演講執行日 2 個月前以表單填寫之預約</span><br>（請依開放日期為主）。<br><br>因團隊人力有限，須依據人力及主要推行專案執行做為評估依據，額滿為止，若未能滿足您的邀約需求，敬請見諒。",
					advice:
						"若貴單位【少數教師聽過 DFC 學習法】或【不曾開設過 DFC 學習法課程】，建議您申請【演講】讓單位夥伴初步認識 DFC 學習法與其基礎操作；而後有進階操作之需求，方可申請工作坊。",
					suggestion: [
						"節能減碳：為節省地球資源，演講與工作坊皆不需印製講義，亦懇請您不用特別印製感謝狀，跟我們一起做小小的改變！",
						"著作維護：為了維護著作權，演講與工作坊全程不開放錄影，簡報檔案亦無法提供。",
					],
				},
				{
					linkText: "邀約工作坊",
					link: "https://form.jotform.com/213342132752446",
					text: "工作坊",
					banner: require("@/assets/speech-workshop/img2.png"),
					topic: [
						"心態工作坊：\n以體驗設計思考結合 DFC 學習法心態架構出發，讓以人為本、傾向行動、相信過程、創意自信、持續迭代等五大核心之心態元素融入於工作坊。結合教師的常見班級經營及主題式時事議題為內容，讓教師在工作坊中體驗 DFC 學習法的課程架構，進而後續能轉化為課室內能實際運用的教學策略與方法。",
						"感受工作坊：\n採用大量教學現場所蒐集來的教學策略與融合設計思考思維作為課程基底。帶領教師透過 DFC 學習法引導不同年段的學生發現問題，走向深入的議題探究與分析，進而在發展教學技巧的同時融入觀察力、分析能力、資料搜集和他人需求與洞察能力。",
						"想像工作坊：\n帶領教師由想像力的教學策略，設計出帶學生打破慣性思維邏輯的課程，融入腦力激盪的互動元素，包含破框思維的發想、發想後收斂、點子轉化，及歷程中的尊重與傾聽溝通，讓學生培養從不同的視角增進想像力，更從中學習如何欣賞他人的想法與練習疊加彼此的想法延伸更多的創造力。",
					],
					lecturer: "依協會安排 1 位講師及 1 位助教",
					time: "3 小時（加上設備測試等行政時間，共 4 小時）",
					details: [
						"對象： 學校教師／主任／校長／家長",
						"參與人數：15～30人之間為佳，以利於分組深度討論",
						"場地設備：平面教室（含適合小組討論之桌椅）、投影設備、麥克風以及可接電腦之音響喇叭\n\n（＊備註：協會將以照片及文字紀錄工作坊之學習狀況，並刊登於協會所經營之自媒體。）",
					],
					fee: [
						"講師費：",
						[
							"學校：講師：新臺幣 6,000元／3 小時 + 助教：新臺幣 3,000元／3 小時，合計為新臺幣 9,000 元 （不含講義費及交通費）",
							"非營利組織／企業／其他單位：若有客製化工作坊的需求，請洽 (02) 2368-1310 課程服務組",
						],
						"交通費：\n實報實銷，敬請貴單位協助提供車站或機場至貴單位之中／短程接駁服務",
						"講義費／教具費：\n實報實銷，貴單位需提供抬頭及統編",
					],
					inviteTime:
						"協會僅接受請於<span color='red4'>工作坊執行日 2 個月前以表單填寫之預約</span><br>（請依開放日期為主）。<br><br>因團隊人力有限，須依據人力及主要推行專案執行做為評估依據，若未能滿足您的邀約需求，敬請見諒。",
					advice:
						"若貴單位【聽過演講已知道 DFC 學習法】，【 預計執行以 DFC 學習法為主軸的校訂課程／彈性學習課程／多元選修課程】，方可申請【工作坊】，更深入地瞭解 DFC 學習法之四步驟各個階段的引導技巧。 ",
					suggestion: [
						"節能減碳：為節省地球資源，演講與工作坊皆不需印製講義，亦懇請您不用特別印製感謝狀，跟我們一起做小小的改變！",
						"著作維護：為了維護著作權，演講與工作坊全程不開放錄影，簡報檔案亦無法提供。",
					],
				},
			],
		};
	},
	methods: {
		handleChange() {
			this.visible = [this.buttonList.findIndex((item) => item.active)];
			const fixedMenu = document.getElementById("fixed-menu");
			scrollTo({ top: this.$el.offsetTop - fixedMenu.offsetHeight });
		},
	},
	mounted() {
		this.visible = this.$root.rwd < 2 ? [0, 1] : [0];
		window.addEventListener("resize", (e) => {
			this.visible = this.$root.rwd < 2 ? [0, 1] : [0];
		});
	},
};
</script>

<style scoped>
.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 200px;
}
</style>
