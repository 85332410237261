<template>
	<el-container style="width:100%; height:100%;">

		<navbar />

		<el-container>

			<el-header class="row" align-x="between" align-y="center" height="auto">
				<h1>DFC 官方網站 後台系統</h1>
				<el-button type="text" @click="signOut" style="font-size:1.2em;">
					登出&emsp;<i class="el-icon-switch-button" />
				</el-button>
			</el-header>

			<el-main>
				<transition name="el-fade-in" mode="out-in">
					<router-view style="max-width:1000px; margin:auto;" />
				</transition>
			</el-main>

		</el-container>
	</el-container>
</template>

<script>
import auth from "../database/modules/auth";
import navbar from "../components/navbar/manage.vue";
export default {
	components: {
		navbar
	},
	methods: {
		async signOut() {
			await auth.signOut();
			this.$router.replace("/login");
		},
	},
	mounted() {
		document.body.style = `height:100%;`;
	},
	beforeDestroy() {
		document.body.style = "";
	},
}
</script>

<style lang="scss" scoped>
.el-header {
	padding: 1em 2em;
	border-bottom: 2px inset #dedede;

	h1 {
		margin: 0;
		font-weight: lighter;
	}
}
.el-aside {
	border-right: 2px inset #dedede;
}
.el-main {
	padding-bottom: 15em;
	background: #eaeaea;
}
</style>