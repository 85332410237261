<template>
	<div>
		<template v-for="(item, i) in list">
			<el-link :href="item.link" :target="item.link.includes('http') ? '_blank' : ''" :key="i">{{item.label}}</el-link>
			<br v-if="i < list.length - 1" :key="`${i}-br`">
		</template>
	</div>
</template>

<script>
export default {
	props: {
		list: Array,
		required: true,
	}
}
</script>

<style lang="scss" scoped>
.el-link {
	margin-top: 5px;
}
</style>