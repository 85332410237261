<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top" style="color:#696969;">
		<el-form-item label="身份" prop="role">
			<el-radio v-model="ruleForm.role" label="媒體工作者" /><br>
			<el-radio v-model="ruleForm.role" label="學生" /><br>
			<inputable-radio v-model="ruleForm.role" prefix="其他，" />
		</el-form-item>
		<el-form-item label="聯絡人姓名" prop="from_name">
			<el-input v-model="ruleForm.from_name" />
		</el-form-item>
		<el-form-item label="聯絡電話" prop="phone">
			<el-input v-model="ruleForm.phone" />
		</el-form-item>
		<el-form-item label="電子信箱" prop="email">
			<el-input v-model="ruleForm.email" />
		</el-form-item>
		<el-form-item label="希望採訪日期" prop="reserve_date">
			<small>除突發新聞事件之外，讓我們邀請您於預計採訪日期前10~15個工作天提出採訪申請，我們會更有充裕的時間調整受訪人力。</small><br>
			<el-date-picker v-model="ruleForm.reserve_date" value-format="yyyy-MM-dd" />
		</el-form-item>
		<el-form-item label="希望採訪時間長度" prop="duration">
			<el-input v-model="ruleForm.duration" />
		</el-form-item>
		<el-form-item label="採訪露出管道" prop="media_impressions">
			<el-checkbox v-model="ruleForm.media_impressions" label="報紙" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="雜誌" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="電視節目" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="電視新聞" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="網路文章" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="網路影片" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="課堂作業" /><br>
			<el-checkbox v-model="ruleForm.media_impressions" label="學位論文或計劃之學術研究" />
		</el-form-item>
		<el-form-item label="採訪露出觀眾族群" prop="target_audience">
			<el-checkbox v-model="ruleForm.target_audience" label="家長" /><br>
			<el-checkbox v-model="ruleForm.target_audience" label="社會大眾" /><br>
			<el-checkbox v-model="ruleForm.target_audience" label="國小-高中學生" /><br>
			<el-checkbox v-model="ruleForm.target_audience" label="國小-高中老師" /><br>
			<el-checkbox v-model="ruleForm.target_audience" label="國小-高中行政人員" />
		</el-form-item>
		<el-form-item label="預估採訪觸及之觀眾數量" prop="reach_amount">
			<el-input-number v-model="ruleForm.reach_amount" :min="0" />
		</el-form-item>
		<el-form-item label="希望採訪方式" prop="interview_ways">
			<el-checkbox v-model="ruleForm.interview_ways" label="現場（請提供地點）" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="旁聽演講" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="旁聽工作坊" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="書面或通信" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="電話訪問" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="廣播" /><br>
			<el-checkbox v-model="ruleForm.interview_ways" label="其他" />
		</el-form-item>
		<el-form-item label="採訪團隊的人數" prop="number_of_team">
			<el-input-number v-model="ruleForm.number_of_team" :min="0" />
		</el-form-item>
		<el-form-item label="採訪現場需要" prop="on_site_demands">
			<el-checkbox v-model="ruleForm.on_site_demands" label="錄影" /><br>
			<el-checkbox v-model="ruleForm.on_site_demands" label="錄音" /><br>
			<el-checkbox v-model="ruleForm.on_site_demands" label="拍照" />
		</el-form-item>
		<el-form-item label="訪談大綱" prop="outline">
			<small>是否需要提供照片？（若有，請簡述需求）</small>
			<el-input v-model="ruleForm.outline" type="textarea" :autosize="{ minRows:5 }" />
		</el-form-item>
		<el-form-item label="是否有車馬費補助" prop="has_allowance">
			<el-radio v-model="ruleForm.has_allowance" label="否" /><br>
			<inputable-radio v-model="ruleForm.has_allowance" prefix="是，" />
		</el-form-item>
		<el-form-item label="您是如何認識我們的" prop="how_to_know_us">
			<el-input v-model="ruleForm.how_to_know_us" type="textarea" :autosize="{ minRows:5 }" />
		</el-form-item>
		<el-form-item label="您希望採訪我們的緣由／原因" prop="reason_of_invitation">
			<el-input v-model="ruleForm.reason_of_invitation" type="textarea" :autosize="{ minRows:5 }" />
		</el-form-item>
		<el-form-item label="其他補充" prop="other_comments">
			<el-input v-model="ruleForm.other_comments" type="textarea" :autosize="{ minRows:5 }" />
		</el-form-item>
		<el-form-item>
			<div class="center">
				<el-button type="info" round @click="cancel()" :disabled="loading">取 消</el-button>
				<el-button type="primary" round @click="submit()" :loading="loading">寄 出</el-button>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
import emailjs from "../libs/emailjs-com";
import InputableRadio from "./InputableRadio.vue";
const ruleForm = {
	role: "",
	from_name: "",
	phone: "",
	email: "",
	reserve_date: "",
	duration: "",
	media_impressions: [],
	target_audience: [],
	reach_amount: 0,
	interview_ways: [],
	number_of_team: 0,
	on_site_demands: [],
	outline: "",
	has_allowance: "",
	how_to_know_us: "",
	reason_of_invitation: "",
	other_comments: "",
}
export default {
	components: {
		InputableRadio,
	},
	data() {
		return {
			loading: false,
			ruleForm: new Object(ruleForm),
			rules: {
				role: { required: true, message: "必填", trigger: "blur" },
				from_name: { required: true, message: "必填", trigger: "blur" },
				phone: { required: true, message: "必填", trigger: "blur" },
				email: [
					{ required: true, message: "必填", trigger: "blur" },
					{ type: "email", message: "檢查格式", trigger: "blur" },
				],
				reserve_date: { required: true, message: "必填", trigger: "blur" },
				duration: { required: true, message: "必填", trigger: "blur" },
				media_impressions: { required: true, message: "必填", trigger: "blur" },
				target_audience: { required: true, message: "必填", trigger: "blur" },
				reach_amount: { required: true, message: "必填", trigger: "blur" },
				interview_ways: { required: true, message: "必填", trigger: "blur" },
				number_of_team: { required: true, message: "必填", trigger: "blur" },
				on_site_demands: { required: true, message: "必填", trigger: "blur" },
				outline: { required: true, message: "必填", trigger: "blur" },
			},
		}
	},
	methods: {
		submit() {
			this.$refs["ruleForm"].validate(async (valid, error) => {
				if (valid) {
					try {
						this.loading = true;
						await emailjs(process.env.VUE_APP_EMAIL_TEMPLATE_ID_2, {
							...this.ruleForm,
							media_impressions: this.ruleForm.media_impressions.join("、"),
							target_audience: this.ruleForm.target_audience.join("、"),
							interview_ways: this.ruleForm.interview_ways.join("、"),
							on_site_demands: this.ruleForm.on_site_demands.join("、"),
						});
						this.$message.success("成功寄出");
					} catch (error) {
						this.$message.error("寄送失敗，請再試一次，或者聯絡 service@dfctaiwan.org")
					}
				} else {
					this.$message.error("請確認必填欄位都填寫完畢");
				}
				this.loading = false;
			});
		},
		cancel() {
			if (!this.loading) this.$emit("cancel");
		}
	}
}
</script>