<template>
	<ContentBlock clip style="background:#fff;">
		<h1 class="center">計畫期程</h1>
		<br>
		<div class="row-grid" align-x="between">
			<template v-for="(sublist, i) in list">
				<div class="row rwd-m-6" align-y="top" align-x="between" gutter="5" :key="i">
					<template v-for="(item, j) in sublist">
						<img v-if="i+j" src="../../assets/dfc-school/arrow.png" align-self="center" style="width:1.6em;" :key="`arrow-${i}${j}`">
						<div class="col" align-x="center" gutter="5" :key="j">
							<img :src="item.img" width="100">
							<small color="white1" style="background:#f46852; border-radius:4px; padding: 0 10px;">{{item.due}}</small>
							<pre style="color:#ff5a41;">{{item.event}}</pre>
						</div>
						<img v-if="$root.rwd > 1 && !i && j==2" src="../../assets/dfc-school/arrow.png" align-self="center" style="width:1.6em;" :key="`arrow-${j}`">
					</template>
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			list: [
				[
					{
						img: require("../../assets/dfc-school/img3.png"),
						due: "4月",
						event: "開放申請",
					},
					{
						img: require("../../assets/dfc-school/img4.png"),
						due: "5月",
						event: "訪視評估",
					},
					{
						img: require("../../assets/dfc-school/img5.png"),
						due: "6月",
						event: "公佈錄取學校",
					},
				],
				[
					{
						img: require("../../assets/dfc-school/img6.png"),
						due: "8月",
						event: "辦理「讀書會」及\n「入校說明會」",
					},
					{
						img: require("../../assets/dfc-school/img7.png"),
						due: "9月~隔年5月",
						event: "一學期共備課程 ",
					},
					{
						img: require("../../assets/dfc-school/img8.png"),
						due: "隔年6月",
						event: "成為典範學校",
					},
				],
			]
		}
	}
}
</script>