<template>
	<section id="thank-you">
		<TopBanner>
			<img src="../../assets/thank-you/banner.jpg">
		</TopBanner>
		<img src="../../assets/thank-you/title.png" class="page-title" width="528">
		<ContentBlock>
			<p>謝謝以下單位，讓 DFC 臺灣團隊一路以來得以成長、茁壯，無論是您的贊助、協助媒體資源露出或是協力舉辦大型活動，您都在我們需要的時候，幫助我們！</p>
			<br>
			<p style="text-align:center;">以下單位與機構依照筆劃順序排列</p>
			<hr style="background:#1d1e1f; height:1px; margin-bottom:1em;">
			<RwdTable :data="sortedList" fixed />
		</ContentBlock>
	</section>
</template>

<script>
import RwdTable from "../../components/RwdTable.vue";
export default {
	components: {
		RwdTable,
	},
	data() {
		return {
			list: [
				"•「未來想像」  國立政治大學未來想像與創意人才培育計畫  總計畫辦公室",
				[
					"Bang Event",
					"Breadwalker",
					"Color Origin",
					"CubeFace",
					"MyStory",
					"SirSpeedy",
					"TEDx Taipei",
					"TOTES & TEES",
				],
				"",
				[
					"九典聯合建築師事務所",
					"大師房屋",
					"小英基金會",
					"山大企業股份有限公司",
					"中茂分色製版印刷事業股份有限公司",
					"中華一餅",
					"今週刊",
					"六度空間雷射室內射擊會館",
					"他群工作室",
					"卡市達創業加油站",
					"可樂旅遊",
					"台北大同扶輪社",
					"台北市永福扶輪社",
					"台北市家長協會",
					"台北市福友扶輪社",
					"台北旅店管理顧問（股）公司",
					"台北朝陽扶輪社",
					"台北圓桌扶輪社",
					"台新金控",
					"台新銀行文化藝術基金會",
					"台灣三星電子股份有限公司",
					"台灣走透休閒事業",
					"台灣國際教育資源網學會",
					"台灣康寧顯示玻璃股份有限公司",
					"台灣導盲犬協會",
					"台灣閱讀文化基金會",
					"左腦創意行銷",
					"未來 family",
					"永齡教育基金會",
					"立督科技股份有限公司",
					"全國家長團體聯盟",
					"百合扶輪社",
					"伽立略家庭餐廳",
					"宏達文教基金會",
					"見性社會福利基金會",
					"亞太電信",
					"兩打半互動",
					"明曜親子館",
					"易度企業股份有限公司",
					"欣傳媒股份有限公司",
					"社企流",
					"花蓮中正國小",
					"金車文教基金會",
					"長榮航空股份有限公司",
					"非常婚禮",
					"品格英語學院",
					"為台灣而教教育基金會",
					"盈科泛利股份有限公司",
					"研華文教基金會",
					"飛達航空貨運代理股份有限公司",
					"食藝餐飲有限公司",
					"香港創意行動協會",
					"泰美親子館",
					"財團法人華科事業群慈善基金會",
					"高圓清燕窩",
					"國立政治大學創意與創造力研究中心",
					"國立臺灣科學教育館",
					"國立臺灣師範大學附屬高級中學",
					"國泰金控",
					"悠遊卡股份有限公司",
					"羚洋科技有限公司",
					"麻葉餐飲食品有限公司",
					"凱信國際行銷有限公司",
					"創致工作室",
					"喬邦富興業有限公司",
					"富邦文教基金會",
					"湛藍教育",
					"絕佳創意有限公司",
					"華山 1914",
					"華固教育基金會",
					"華視文化教育基金會",
					"貳樓餐飲有限公司",
					"雅德賽斯濱江團",
					"集思會展事業群",
					"黑松教育基金會",
					"黑暗中對話",
					"黑暗對話社會企業股份有限公司",
					"新北市土城國小",
					"新竹物流",
					"蜂派思有限公司",
					"誠致教育基金會",
					"鉅瑋實業有限公司",
					"彰化南郭國小",
					"滿福堂餅行",
					"漢聲廣播電臺",
					"臺北市文化局（世界設計之都）",
					"臺北市立大學附設實驗國民小學",
					"臺北市和平實驗國民小學",
					"臺北松山意舍酒店",
					"臺北家長協會",
					"臺灣走透",
					"臺灣青年氣候聯盟",
					"臺灣夏普股份有限公司",
					"臺灣戲曲學院",
					"遠流出版公司",
					"遠流出版事業股份有限公司",
					"澎湖縣七美國中",
					"熱血設計有限公司",
					"親子天下",
					"龍口食品企業股份有限公司",
					"勵豐實業有限公司",
					"鴻海教育基金會",
					"黛比澍有限公司",
					"曜碩科技股份有限公司",
					"邊境實驗室",
					"林堉璘宏泰教育文化公益信託",
					"林堉璘宏泰基金會",
					"飛瑞旅行社股份有限公司",
					"飛達旅遊",
					"智邦公益館",
					"智邦文教基金會",
				],
			]
		}
	},
	computed: {
		sortedList() {
			return this.list
				.reduce((a, b) => {
					if (Array.isArray(b)) {
						b.sort((i, j) => i.localeCompare(j));
						a = a.concat(b);
					} else a.push(b);
					return a;
				}, new Array);
		}
	},
}
</script>

<style lang="scss" scoped>
#thank-you {
	line-height: 2;
	@media screen and (max-width: 425px) {
		text-align: center;
	}
}
</style>