<template>
	<ContentBlock clip bg-color="white1">
		<h1 class="center">大事紀</h1>
		<br />
		<Collapsible
			class="hidden-content"
			minHeight="300px"
			:collapsed="!expanded"
			:class="{ expanded }"
		>
			<Timeline
				:data="list"
				theme="#738bff"
				text-color="rgb(255,255,255)"
			>
				<template v-slot="{ item }">
					<TimelineNode :data="item" />
				</template>
			</Timeline>
		</Collapsible>
		<br />
		<div v-if="!expanded" class="center">
			<DfcButton theme="yellow" round arrow @click="expanded = true">
				點我看更多內容
			</DfcButton>
		</div>
	</ContentBlock>
</template>

<script>
import Timeline from "./Timeline.vue";
import TimelineNode from "./TimelineNode.vue";
import Collapsible from "@/components/Collapsible.vue";
export default {
	components: {
		Timeline,
		TimelineNode,
		Collapsible,
	},
	data() {
		return {
			expanded: false,
			list: [
				{
					time: "2024",
					title1: "第十四屆 DFC 挑戰分享季@臺北、臺南",
					content: `第十五屆 DFC 挑戰啟動\n參與雜學校展覽\nTEDx Tainan\nDFC 全球年會 ＠阿拉伯聯合大公國（杜拜）`,
				},
				{
					time: "2023",
					title1: "第五屆 DFC 種子學校",
					content: `第四屆 DFC 種子教師計畫 &\n初階種子教師課程開辦\n第十三屆 DFC 挑戰分享季 ＠桃園、花蓮、彰化\n第十四屆 DFC 挑戰啟動\nDFC 全球年會 ＠印度\n《日日挑戰：透過 DFC 學習法，實踐永續好點子》\nDFC 永續教具發行與再版\n獲選社企流第二屆 NPO 創新成長加速器團隊\n榮獲「第九屆傳善獎」肯定\nTEDx Zhubei`,
					marks: ["金門縣金寧國中小", "新北市雙溪國小"],
				},
				{
					time: "2022",
					title1: `第四屆 DFC 種子學校`,
					content: `第三屆 DFC 種子教師計畫啟動\n協會品牌識別重塑\n第十二屆 DFC 挑戰分享季\n第十三屆 DFC 挑戰啟動\nDFC 全球年會 @馬來西亞、烏拉圭\nTEDx NTUST`,
					marks: ["桃園市西門國小", "桃園市內壢國中"],
				},
				{
					time: "2021",
					title1: "第三屆 DFC 種子學校",
					content: `第二屆 DFC 種子教師計畫啟動\n第十一屆 DFC 挑戰分享季\n第十二屆 DFC 挑戰啟動\nDFC 全球年會 ＠巴西（線上）`,
					marks: ["新竹縣竹北國小", "宜蘭縣東澳國小"],
				},
				{
					time: "2020",
					title1: "第二屆 DFC 種子學校",
					content: `第一屆 DFC 種子教師計畫啟動\n第十屆 DFC 挑戰與分享季\n第十一屆 DFC 挑戰啟動\nDFC 全球年會 ＠新加坡（線上）`,
					marks: ["桃園市文化國小", "高雄市大灣國中"],
				},
				{
					time: "2019",
					redTitle: "協會正式轉型師「陪」",
					title1: "第一屆 DFC 種子學校",
					content: `第九屆 DFC 挑戰分享季\n第十屆 DFC 挑戰啟動\nDFC 全球年會 ＠梵蒂岡`,
					marks: ["桃園市霄裡國小", "花蓮縣國風國中"],
				},
				{
					time: "2018",
					title1: "第八屆 DFC 挑戰分享季",
					content: `第九屆 DFC 挑戰啟動<br /><span style="color:#f46852">DFC全球年會 ＠臺灣</span><br />親子天下《報告！這裡沒有校長室》再版`,
				},
				{
					time: "2017",
					title1: "第七屆 DFC 挑戰分享季",
					content: `第八屆 DFC 挑戰啟動\nDFC 全球年會 ＠西班牙\n第四屆大推手行動分享會@臺北、高雄`,
				},
				{
					time: "2016",
					title1: "第六屆 DFC 挑戰分享大會",
					content: `第七屆 DFC 挑戰啟動\nDFC 全球年會 ＠中國\n第三屆大推手行動分享會@臺北、宜蘭、臺中\n參與世界設計之都`,
				},
				{
					time: "2015",
					title1: "第五屆 DFC 挑戰分享大會",
					content: `第六屆 DFC 挑戰啟動\nDFC 全球年會 ＠墨西哥\n第二屆大推手行動分享會@臺北、彰化、高雄、臺東`,
				},
				{
					time: "2014",
					title1: "第五屆 DFC 挑戰啟動",
					content: `首次群眾募資\nDFC 全球年會 ＠印度\n第一屆大推手行動分享會@ 臺北、臺中`,
				},
				{
					time: "2013",
					title1: "第四屆 DFC 挑戰與分享大會",
					content: `DFC 全球年會 ＠印度\n親子天下《報告！這裡沒有校長室》出版`,
				},
				{
					time: "2012",
					title1: "第三屆 DFC 挑戰與分享大會",
					content: `第一次 DFC 全球年會 ＠印度\n凱信《給孩子改變世界的機會》《童心協力，翻轉地球》出版`,
				},
				{
					time: "2011",
					title1: "共同創辦人許芯瑋和上官良治\n首次參訪河濱學校",
					content: `協會成立\n第二屆 DFC 挑戰與分享大會\n第一次全球夥伴會議@印度`,
				},
				{
					time: "2010",
					title1: "DFC 臺灣團隊成立",
					content: `示範挑戰與頒獎典禮\n第一屆 DFC 挑戰與分享大會\nTEDx Monga Make It Real 演講`,
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.hidden-content {
	position: relative;
	transition: 1s;
	overflow: hidden;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to bottom, transparent, #fff);
	}

	&.expanded {
		&::after {
			display: none;
		}
	}
}
</style>
