<template>
	<section>
		<el-page-header @back="$router.push('/manage/news')" :content="ruleForm.title" />
		<h1 style="margin:1em; text-align:center;">最新消息</h1>
		<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="6em">
			<el-form-item label="快速操作" prop="published">
				<el-card>
					<div class="row" align-x="between" align-y="center">
						<el-button @click="handleChangeStatus(ruleForm.published)" :type="ruleForm.published && 'info' || 'success'" :loading="loading">{{mapStatusText(ruleForm.published)}}</el-button>
						<el-button @click="handleDelete(id)" type="danger" icon="el-icon-delete" size="mini">刪除</el-button>
					</div>
				</el-card>
			</el-form-item>
			<hr>
			<br>
			<el-form-item label="縮圖" prop="title">
				<el-card>
					<upload v-model="ruleForm.fileList" :id="id" @change="statusChanged = true" />
				</el-card>
			</el-form-item>
			<el-form-item label="標題" prop="title">
				<el-input v-model="ruleForm.title" @keyup.native="statusChanged = true" />
			</el-form-item>
			<el-form-item label="日期" prop="date">
				<el-date-picker v-model="ruleForm.date" value-format="timestamp" @change="statusChanged = true" />
			</el-form-item>
			<el-form-item label="簡介" prop="brief">
				<el-input v-model="ruleForm.brief" type="textarea" :autosize="{minRows:5}" @keyup.native="statusChanged = true" />
			</el-form-item>
			<el-form-item label="內容" prop="content">
				<template v-if="exists">
					<tinymce v-model="ruleForm.content" @change="statusChanged = true" />
				</template>
			</el-form-item>
			<br>
			<el-form-item>
				<div class="center">
					<el-button @click="handleCancel()" type="info" :disabled="!statusChanged">回 復</el-button>
					<el-button @click="handleUpdate()" type="primary" :disabled="!statusChanged" :loading="loading">儲 存</el-button>
				</div>
			</el-form-item>
		</el-form>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import preventLeave from "../../mixin/prevent-leave.js";
import Tinymce from "../../components/Tinymce.vue";
import Upload from "../../components/news/Upload.vue";
export default {
	components: {
		Tinymce,
		Upload,
	},
	mixins: [preventLeave],
	props: ["id"],
	data() {
		return {
			exists: false,
			loading: true,
			ruleForm: new Object,
			rules: {
				title: { required: true, message: "標題" },
				date: { required: true, message: "請指定日期" },
				content: { required: true, message: "請填寫內容" },
				brief: { required: true, message: "請填寫簡介" },
			}
		}
	},
	computed: {
		...mapState("news", [
			"_news",
		]),
	},
	methods: {
		...mapActions("news", [
			"_getData",
			"_updateData",
			"_changeStatue",
			"_deleteData",
		]),
		handleUpdate() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					try {
						this.loading = true;
						await this._updateData(this.ruleForm);
						this.statusChanged = false;
						this.$message.success("success");
					} catch (error) {
						this.$message.error(error.message);
					}
					this.loading = false;
				}
			})
		},
		handleCancel() {
			Object.assign(this.ruleForm, this._news);
			this.statusChanged = false;
		},
		handleChangeStatus(published) {
			this.$confirm(`確定要「${this.mapStatusText(published)}」？`, { type: "warning" })
				.then(async () => {
					this.loading = true;
					try {
						await this._changeStatue({
							id: this.id,
							published: !published
						});
						this.ruleForm.published = !published;
					} catch (error) {
						this.$message.error(error.message);
					}
					this.loading = false;
				});
		},
		mapStatusText(val) {
			return val ? "存為草稿" : "發布";
		},
		handleDelete() {
			this.$confirm(`<h2>確定要刪除「${this.ruleForm.title}」？</h2>
				<p>刪除後將不可復原，<br>可以用「<u><i>存為草稿</i></u>」代替刪除唷！</p>`,
				{
					type: "warning",
					center: true,
					dangerouslyUseHTMLString: true,
					title: "警告"
				}).then(async () => {
					this.loading = true;
					try {
						await this._deleteData(this.ruleForm);
					} catch (error) {
						this.$message.error(error.message);
					}
					this.loading = false;
				});
		}
	},
	created() {
		this._getData(this.id)
			.then(async doc => {
				this.exists = doc.exists;
				if (!doc.exists) {
					await this.$message.error("此項目不存在");
					this.$router.push("/manage/news");
				} else {
					this.ruleForm = doc.data();
				}
				this.loading = false;
			});
	},
}
</script>

<style lang="scss" scoped>
.el-form {
	max-width: 1200px;
}
</style>