<template>
	<ContentBlock clip>
		<h1 class="center">成為志工</h1>
		<div id="pad">
			<h3>「讓自己成為你在世界上想看到的那個改變」—甘地</h3>
			<p><small>自 2019 年協會轉型後，我們積極展開以「師陪」的角度切入教育現場，建立穩固的教師支持系統和陪伴網絡，期許能點燃孩子的學習動機。在這場溫柔的教育改革路上，我們正在找尋對教育現場充滿熱情的你，和我們一起實現明年度的專案目標，讓創新教育可以深耕在更多的教室裡，陪伴孩子和教師成長！</small></p>
			<br>
			<br>
			<ArticleBlock picture-position="left">
				<template slot="paragraph">
					<h3>「開發你對學習的熱情，你將永遠不會停止成長。」—Anthony J. D’Angelo</h3>
					<p><small>團隊依照不同活動與專案的推進，不定期招募志工。我們重視志工的學習與成長歷程。每次的志工招募都將依據您的興趣、想法及經驗，為您安排職務內容及事前充滿活力與創意的志工培訓。</small></p>
					<DfcButton theme="yellow" round arrow small href="https://bit.ly/3wOyWss" target="_blank">志工簡章下載及報名</DfcButton>
				</template>
				<img slot="picture" src="../../assets/join-us/img1.png">
			</ArticleBlock>
		</div>
	</ContentBlock>
</template>

<script>
import ArticleBlock from "../ArticleBlock.vue"
export default {
	components: {
		ArticleBlock,
	},

}
</script>

<style lang="scss" scoped>
.content-block {
	font-size: 1.1em;
	@media screen and (min-width: 769px) {
		background-image: url("../../assets/join-us/role1.png"),
			url("../../assets/join-us/role2.png");
		background-position: left 40%, right 90%;
		background-repeat: no-repeat;
		background-size: 17%, 15%;
	}
	background: $red;
	color: #fff;
}
#pad {
	@media screen and (min-width: 769px) {
		padding: 0 10% 5%;
	}
}
</style>