<template>
	<ContentBlock clip>
		<el-card>
			<h1 style="text-align:center;">要怎麼邀約 DFC 來學校？<br>我們學校現在適合選哪一個服務呢？</h1>
		</el-card>
		<br>
		<br>
		<el-card id="second-card">
			<h1 style="text-align:center;">溫馨提醒</h1>
			<p>請先確認：</p>
			<div class="row-grid" gutter-x="15" gutter-y="5">
				<div class="rwd-s-4">
					<div class="precaution">邀約日期</div>
				</div>
				<div class="rwd-s-4">
					<div class="precaution">活動所需時間</div>
				</div>
				<div class="rwd-s-4">
					<div class="precaution">參與學員身分、人數</div>
				</div>
			</div>
			<br>
			<p>★ DFC 臺灣僅接受自當月算起 2 個月後的演講及工作坊預約，請以表單邀約，額滿為止。因團隊人力有限，必須依據人力及主要推行專案執行做為評估場次依據，若未能滿足您的邀約需求，請您見諒！
				<br>★ 為維護著作權，活動中全程不開放錄影，當天簡報檔案亦無法提供。
				<br>★ 請跟我們一起做小小的改變，節省地球資源，演講不需印製講義，而我們也懇請您不用特別印製感謝狀，您的感謝，我們銘記在心 :)
			</p>
		</el-card>
		<br>
		<br>
		<table v-if="$root.rwd < 3">
			<tr>
				<th>適合的服務</th>
				<td>演講</td>
				<td>DFC 四步驟心態工作坊</td>
				<td>感受技巧工作坊、<br>想像技巧工作坊</td>
				<td>客製化工作坊</td>
			</tr>
			<tr>
				<th>學校同事對 DFC 的認識程度</th>
				<td>．少數教師聽過 DFC<br>．學校不曾開設 DFC 課程</td>
				<td>．教師們知道 DFC，也都聽過 DFC 演講<br>．預計將執行以 DFC 課程爲主軸的校訂課程／彈性學習課程／多元選修課程</td>
				<td>．正在進行 DFC 課程<br>．曾參與心態工作坊</td>
				<td>．正在進行 DFC 課程<br>．已邀約過演講、心態工作坊及想像工作坊</td>
			</tr>
			<tr>
				<th>希望得到的收穫</th>
				<td>．想知道 DFC 四步驟是什麼<br>．想知道 DFC 的教育理念<br>．體驗 DFC 四步驟<br>．更多DFC 故事案例<br>．如何啟發孩子和其他教育實踐者的學習動機</td>
				<td>．想深入了解 DFC 四步驟<br>理論<br>．體驗 DFC 四步驟</td>
				<td>．想知道如何利用課堂活動及相關教學資源，在課堂上實際操作 DFC<br>．想更深入了解 DFC 及設計思考<br>．DFC 四步驟<br>帶領技巧、引導方式</td>
				<td>依據學校發展 DFC 四步驟課程歷程與需求，強化某項目標，例如團隊領導、發展 DFC 四步驟評量等等。</td>
			</tr>
			<tr>
				<th>預計參與學員身分</th>
				<td>．國小國中的學校教師、主任、校長<br>．國中小、高中學生</td>
				<td colspan="3">國中小、高中的學校教師、主任、校長</td>
			</tr>
			<tr>
				<th>預計參與人數</th>
				<td>30人以上</td>
				<td colspan="3">15人至30人</td>
			</tr>
			<tr>
				<th>有多少時間</th>
				<td>最多連續兩節課<br>（一場1.5 小時）</td>
				<td colspan="3">半天（一場3小時）</td>
			</tr>
			<tr>
				<th>可使用的場地及設備</th>
				<td>有投影設備、麥克風、可接電腦之音響喇叭</td>
				<td colspan="3">
					<ol style="margin:0;">
						<li>平面教室，含課桌椅（可進行小組討論）。</li>
						<li>投影設備、麥克風、可接電腦之音響喇叭。</li>
					</ol>
				</td>
			</tr>
		</table>
		<template v-else>
			<table>
				<tr>
					<td>
						<b>適合的服務</b>
						<p>演講</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>學校同事對 DFC 的認識程度</b>
						<p>．少數教師聽過 DFC<br>．學校不曾開設 DFC 課程</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>希望得到的收穫</b>
						<p>．想知道 DFC 四步驟是什麼<br>．想知道 DFC 的教育理念<br>．體驗 DFC 四步驟<br>．更多DFC 故事案例<br>．如何啟發孩子和其他教育實踐者的學習動機</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與學員身分</b>
						<p>．國小國中的學校教師、主任、校長<br>．國中小、高中學生</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與人數</b>
						<p>30人以上</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>有多少時間</b>
						<p>最多連續兩節課<br>（一場1.5 小時）</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>可使用的場地及設備</b>
						<p>有投影設備、麥克風、可接電腦之音響喇叭</p>
					</td>
				</tr>
			</table>
			<br>
			<table>
				<tr>
					<td>
						<b>適合的服務</b>
						<p>DFC 四步驟心態工作坊</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>學校同事對 DFC 的認識程度</b>
						<p>．教師們知道 DFC，也都聽過 DFC 演講<br>．預計將執行以 DFC 課程爲主軸的校訂課程／彈性學習課程／多元選修課程</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>希望得到的收穫</b>
						<p>．想深入了解 DFC 四步驟<br>理論<br>．體驗 DFC 四步驟</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與學員身分</b>
						<p>國中小、高中的學校教師、主任、校長</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與人數</b>
						<p>15人至30人</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>有多少時間</b>
						<p>半天（一場3小時）</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>可使用的場地及設備</b>
						<ol style="margin:0;">
							<li>平面教室，含課桌椅（可進行小組討論）。</li>
							<li>投影設備、麥克風、可接電腦之音響喇叭。</li>
						</ol>
					</td>
				</tr>
			</table>
			<br>
			<table>
				<tr>
					<td>
						<b>適合的服務</b>
						<p>感受技巧工作坊、<br>想像技巧工作坊</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>學校同事對 DFC 的認識程度</b>
						<p>．正在進行 DFC 課程<br>．曾參與心態工作坊</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>希望得到的收穫</b>
						<p>．想知道如何利用課堂活動及相關教學資源，在課堂上實際操作 DFC<br>．想更深入了解 DFC 及設計思考<br>．DFC 四步驟<br>帶領技巧、引導方式</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與學員身分</b>
						<p>國中小、高中的學校教師、主任、校長</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與人數</b>
						<p>15人至30人</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>有多少時間</b>
						<p>半天（一場3小時）</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>可使用的場地及設備</b>
						<ol style="margin:0;">
							<li>平面教室，含課桌椅（可進行小組討論）。</li>
							<li>投影設備、麥克風、可接電腦之音響喇叭。</li>
						</ol>
					</td>
				</tr>
			</table>
			<br>
			<table>
				<tr>
					<td>
						<b>適合的服務</b>
						<p>客製化工作坊</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>學校同事對 DFC 的認識程度</b>
						<p>．正在進行 DFC 課程<br>．已邀約過演講、心態工作坊及想像工作坊</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>希望得到的收穫</b>
						<p>依據學校發展 DFC 四步驟課程歷程與需求，強化某項目標，例如團隊領導、發展 DFC 四步驟評量等等。</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與學員身分</b>
						<p>國中小、高中的學校教師、主任、校長</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>預計參與人數</b>
						<p>15人至30人</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>有多少時間</b>
						<p>半天（一場3小時）</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>可使用的場地及設備</b>
						<ol style="margin:0;">
							<li>平面教室，含課桌椅（可進行小組討論）。</li>
							<li>投影設備、麥克風、可接電腦之音響喇叭。</li>
						</ol>
					</td>
				</tr>
			</table>
		</template>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {}
	}
}
</script>

<style lang="scss" scoped>
.content-block {
	background: $green;
	background-image: url("../../assets/speech-workshop/role1.png"),
		url("../../assets/speech-workshop/role2.png");
	background-position: left 10%, right 40%;
	background-repeat: no-repeat;
	background-size: 23%, 20%;
}
h1 {
	margin-top: 0;
}
#second-card {
	font-size: 1.1em;
	.row-grid .precaution {
		text-align: center;
		width: 100%;
		border-radius: 8px;
		border: 1px solid #50c5a4;
	}
}
table {
	@include bordered-table(#ababab, 10px);
	width: 100%;
	background: #fff;
	table-layout: fixed;
	@media screen and (min-width: 426px) {
		font-size: 0.875em;
	}
	th,
	td {
		padding: 10px;
		line-height: 1.5;
		text-align: left;
		vertical-align: top;
	}
	th {
		background: rgba($color: #50c5a4, $alpha: 0.2);
	}
	th,
	b {
		color: #0e9e76;
	}
}
</style>