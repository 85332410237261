<template>
	<li class="recursive" :class="{ is_list, is_empty }">
		<br v-if="is_empty && $root.rwd < 2">
		<template v-else>
			<ul v-if="is_list">
				<template v-for="(item, i) in data">
					<recursive v-model="data[i]" :key="i" />
				</template>
			</ul>
			<pre v-else>{{data}}</pre>
		</template>
	</li>
</template>

<script>
export default {
	components: {
		Recursive: () => import("./Recursive.vue")
	},
	props: {
		value: {
			type: [String, Array],
			required: true
		}
	},
	model: {
		prop: "value",
		event: "change"
	},
	computed: {
		data: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("change", val);
			}
		},
		is_list() {
			return this.data && typeof (this.data) != "string";
		},
		is_empty() {
			return !this.data.length;
		}
	}
}
</script>

<style lang="scss" scoped>
li {
	&::marker {
		content: "- ";
	}
	&.is_list,
	&.is_empty {
		list-style: none;
		&::marker {
			content: "";
		}
	}
}
pre {
	margin: 0;
}
</style>