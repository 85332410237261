<template>
	<el-radio :value="model" :label="value" @change="handleChange">{{prefix}}<input v-model="suffix" @focus="handleChange" @input="handleChange" /></el-radio>
</template>

<script>
export default {
	props: {
		model: String,
		prefix: String,
	},
	model: {
		prop: "model",
		event: "change",
	},
	data() {
		return {
			suffix: "",
		}
	},
	computed: {
		value() {
			return this.prefix + this.suffix;
		},
	},
	methods: {
		handleChange() {
			this.$emit("change", this.value);
		},
	},
}
</script>

<style lang="scss" scoped>
input {
	color: inherit;
	border: none;
	border-bottom: 1px solid;
	background: transparent;
	outline: none;
}
</style>