<template>
	<router-link v-if="to.path" class="anchor" :to="to.path" :exact="exact">
		<span class="underline">
			<slot />
		</span>
	</router-link>
	<a v-else-if="to.href" class="anchor" :href="to.href" target="_blank">
		<span class="underline">
			<slot />
		</span>
	</a>
	<span v-else class="anchor">
		<span class="underline">
			<slot />
		</span>
	</span>
</template>

<script>
export default {
	props: {
		to: Object,
		exact: Boolean,
	},
	mounted() {
		["click", "touchstart", "touchend", "touchmove"].forEach(type => {
			this.$el.addEventListener(type, e => {
				this.$emit(type, e);
			});
		});
	}
}
</script>

<style lang="scss" scoped>
.anchor {
	vertical-align: top;
	cursor: pointer;

	&.router-link-active {
		color: $red;
		.underline {
			border-bottom: 1.5px solid $red;
		}
	}
}
</style>