<template>
	<ContentBlock clip style="background:#f7b159;">
		<h1 class="center">參加辦法</h1>
		<br>
		<template v-if="$root.rwd > 1">
			<StickyMenu2 v-model="buttonList" @change="handleChange">
				<template slot-scope="{item, index, callback}">
					<div class="center" style="padding: 5px; flex: 1;">
						<DfcButton class="rwd-12" @click="callback(); visible = [index];" :theme="visible.includes(index) ? 'red' : ''">{{item.text}}</DfcButton>
					</div>
				</template>
			</StickyMenu2>
			<br>
		</template>
		<div class="row-grid" align-x="around" align-y="top" style="align-items: stretch;">
			<div class="card col rwd-m-5" gutter="10" v-show="visible.includes(0)">
				<h2 class="center">故事繳交</h2>
				<hr>
				<template v-for="(item, i) in list">
					<div class="col" :key="i">
						<div class="row-reverse" align-y="center" align-x="right">
							<h3>{{item.title}}</h3>
							<img :src="require(`../../assets/dfc-challenge/icon${i+1}.png`)">
						</div>
						<p>{{item.p}}</p>
					</div>
				</template>
				<br>
				<div class="center">
					<DfcButton class="rwd-s-8" theme="red" round arrow href="https://drive.google.com/file/d/1CZgDIClRpe62RQTrp-9MwdBe884Y7rAV/view" target="_blank">簡章下載</DfcButton>
				</div>
			</div>
			<div class="card col rwd-m-5" gutter="10" v-show="visible.includes(1)" style="justify-content: space-between;">
				<div>
					<h2 class="center" style="margin-bottom: 10px;margin-top: 0;">DFC 挑戰支持系統</h2>
					<hr>
					<h3>教學資源及聯絡管道</h3>
					<p>若對 DFC 挑戰有任何行政事項或引導教學相關問題，歡迎您透過下列管道洽詢團隊：</p>
					<br>
					<p>DFC 挑戰課程服務信箱 : challenge@dfctaiwan.org
						<br><small>※ 歡迎您隨時來信，我們會儘速於三日內回覆。</small>
					</p>
					<br>
					<p>DFC 挑戰課程服務專線：02-2368-1310
						<br><small>※ 客服專線時間：週一至週五 10:00~17:00</small>
					</p>
					<br>
					<p>DFC  挑戰  LINE@：搜尋  @801cukmv
					</p>
					<br>
					<h3>加入 DFC 教學社群（臉書社團）</h3>
					<ul>
						<li>提供 DFC 學習法相關資源，如課程設計、歷屆精選故事</li>
						<li>與其他操作 DFC 學習法之教師線上與線下互動</li>
						<li>協助教師順利執行 DFC 學習法課程或完成 DFC 挑戰</li>
					</ul>
					<br>
					<br>
				</div>
				<div class="center">
					<DfcButton class="rwd-s-5" style="margin-right: 10px;" theme="red" round arrow href="https://lin.ee/QIkPOUr" target="_blank">加入  LINE@</DfcButton>
					<DfcButton class="rwd-s-5" theme="red" round arrow href="https://www.facebook.com/groups/1114591105274908" target="_blank">教學社群</DfcButton>
				</div>
			</div>
		</div>
	</ContentBlock>
</template>

<script>
import StickyMenu2 from "../StickyMenu2.vue";
export default {
	components: {
		StickyMenu2
	},
	data() {
		return {
			visible: [0, 1],
			buttonList: [
				{ text: "故事繳交" },
				{ text: "DFC 挑戰支持系統" }
			],
			list: [
				{
					title: "學習單",
					p: "輔導員們（可以是老師、家長、自學團老師等有意願的大人們）可以參照「 DFC 挑戰學習單」做為課程或是執行 DFC 挑戰的架構，一步一步地帶領學生完成 DFC 四步驟的探究與行動歷程。",
				},
				{
					title: "影片或投影片",
					p: "若想參與每年度的「 DFC 挑戰分享季」，分享你們執行 DFC 挑戰的歷程。請記得將你們解決問題的過程與珍貴的畫面紀錄下來，並提供「檔案下載連結」，讓我們為你安排合適的舞台發光發熱！",
				},
				{
					title: "故事授權資料",
					p: "若希望讓更多人看到你們的 DFC 挑戰故事，請記得填寫家長同意書及故事授權書，將你的故事授權給我們。",
				},
				{
					title: "國際證書＿姓名羅馬拼音",
					p: "由於我們的 DFC 挑戰國際證書為國際授權核發，因此需要每位參與者的姓名羅馬拼音（與護照上相同為佳）。",
				},
			]
		}
	},
	methods: {
		handleChange() {
			this.visible = [this.buttonList.findIndex(item => item.active)];
			const fixedMenu = document.getElementById("fixed-menu");
			scrollTo({ top: this.$el.offsetTop - fixedMenu.offsetHeight });
		}
	},
	mounted() {
		this.visible = this.$root.rwd < 2 ? [0, 1] : [0];
		// window.addEventListener("resize", e => {
		// 	this.visible = this.$root.rwd < 2 ? [0, 1] : [0];
		// });
	},
}
</script>

<style lang="scss" scoped>
.card {
	background: #fff;
	border-radius: 6px;
	border: solid 1px #dc3d3c;
	font-size: smaller;
	padding: 1em 2em;

	hr {
		border: none;
		height: 1px;
		width: 100%;
		background: #dc3d3c;
	}

	h3 {
		color: #f46852;
		& + img {
			margin-right: 10px;
			width: 2em;
		}
	}

	li::marker {
		content: "•";
	}
}
</style>