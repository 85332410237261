<template>
	<ContentBlock clip style="background:#fff;">
		<h1 class="center">報名流程</h1>
		<div class="siema center" v-if="$root.rwd < 2">
			<img src="../../assets/dfc-challenge/img345.png" width="100%">
		</div>
		<Siema ref="siema" v-else :data="list" control>
			<template v-slot="{slide}">
				<img :src="slide" class="rwd-12" style="max-width:300px; margin:auto;">
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
	</ContentBlock>
</template>

<script>
import Siema from "../Siema.vue"
export default {
	components: {
		Siema,
	},
	data() {
		return {
			list: [
				require("../../assets/dfc-challenge/img3.png"),
				require("../../assets/dfc-challenge/img4.png"),
				require("../../assets/dfc-challenge/img5.png"),
			],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.siema {
	margin-bottom: 5em;
	.right {
		right: 30% !important;
	}
	.left {
		left: 30% !important;
	}
	.right,
	.left {
		top: 100% !important;
		transform: translateY(50%);
	}
}
</style>