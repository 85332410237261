<template>
	<section id="publications">
		<div id="banner" bg-color="white1">
			<TopBanner>
				<img src="../../assets/publications/banner.jpg" width="100%">
			</TopBanner>
			<img src="../../assets/publications/title.png" class="page-title" width="300">
		</div>
		<template v-for="(item, i) in list">
			<ContentBlock :key="i" clip :bg-color="!(i % 2) ? 'white1' : ''">
				<div class="row-grid" align-x="around" align-y="top" gutter-x="30" gutter-y="30">
					<img :src="item.thumb" class="rwd-m-4 rwd-s-6 rwd-xs-8">
					<div class="col rwd-m-7">
						<p style="margin-top:0;"><b>{{item.title}}</b></p>
						<li>{{item.suggestion}}</li>
						<div v-html="item.descriptions && item.descriptions.join('<br>')"></div>
						<br>
						<br>
						<div v-if="item.link" class="center">
							<DfcButton class="rwd-s-8" theme="red" round arrow :href="item.link" target="_blank">前往購買書籍</DfcButton>
						</div>
					</div>
				</div>
			</ContentBlock>
		</template>
		<div class="col" align-x="center">
			<p style="font-size:1.5em;">縣市圖書館列表</p>
			<RwdTable :data="libraries" />
		</div>
		<br>
		<br>
		<br>
		<br>
		<br>
	</section>
</template>

<script>
import RwdTable from "../../components/RwdTable.vue";
export default {
	components: {
		RwdTable,
	},
	data() {
		return {
			list: [
				{
					thumb: require("@/assets/publications/book1.png"),
					title: "《創意行動之旅 — 給中學教師的設計思考教學手冊》",
					suggestion: "建議對象：中學教師",
					descriptions: [
						"《創意行動之旅：給中學教師的設計思考術》是一本提供中、小學教師的設計思考教學手冊，由史丹佛大學(Stanford University)、哈佛大學 (Harvard University)、知名設計公司 IDEO、河濱學校 (Riverside School) 跨國教育研究與教學團隊聯合研發，期望透過設計思考培養孩子的同理心、創造力、行動力以及自信心。本書藉由有趣的漫畫與發人深省的紀錄短片，剖析世界各地孩子如何解決問題的真實故事，並提供活動、教案、資源協助教師帶領學生「感受」身旁問題，進行腦力激盪及最佳情境分析，讓學生大膽「想像」可能的解決方案，並收斂成可行方案。 再擬定具體計畫，勇敢地試著「實踐」，並將行動過程記錄下來，最後，試著用自己的方法「分享」屬於他們的故事，然後發揮影響力感染更多的人。",
						"",
						"深受教師們歡迎、非常實用的《創意行動之旅》在團隊夥伴們用心的校對、在地化用詞潤飾調整後，終於在 2020 年發行第四版嘍！",
						"",
						"改版過程全紀錄： ",
						"<a href='https://medium.com/design-for-change-taiwan/dfc-%E8%87%BA%E7%81%A3%E5%9C%98%E9%9A%8A%E7%9A%84-dfc-%E5%89%B5%E6%84%8F%E8%A1%8C%E5%8B%95%E4%B9%8B%E6%97%85-%E7%B5%A6%E4%B8%AD%E5%AD%B8%E6%95%99%E5%B8%AB%E7%9A%84%E8%A8%AD%E8%A8%88%E6%80%9D%E8%80%83%E8%A1%93-%E6%94%B9%E7%89%88%E5%AE%8C%E6%88%90-f2c40343994c' target='_blank'>《創意行動之旅：給中學教師的設計思考術》—改版完成！</a>",
						"",
						"<a href='https://medium.com/design-for-change-taiwan/%E8%B7%9F%E8%91%97-%E5%89%B5%E6%84%8F%E8%A1%8C%E5%8B%95%E4%B9%8B%E6%97%85-%E8%BC%94%E5%8A%A9%E5%BD%B1%E7%89%87-%E4%B8%80%E8%B5%B7%E5%88%B0%E5%8D%B0%E5%BA%A6%E6%B2%B3%E6%BF%B1%E5%AD%B8%E6%A0%A1%E8%A7%80%E8%AA%B2%E5%90%A7-70ea3dbab9cf' target='_blank'>印度河濱學校老師與你分享自己的《創意行動之旅》操作心法！</a>",
						"<a href='https://medium.com/design-for-change-taiwan/%E7%9E%84%E4%B8%80%E7%9C%BC%E5%9B%9B%E6%AD%A5%E9%A9%9F-%E6%83%B3%E5%83%8F-%E4%B8%8D%E6%98%AF%E5%8F%AA%E6%9C%89%E6%83%B3%E5%80%8B%E8%BE%A6%E6%B3%95%E8%80%8C%E5%B7%B2-f31a3a8a05c9' target='_blank'>瞄一眼 DFC 四步驟：想像，不是只有想個辦法而已！</a>",
						"<a href='https://medium.com/design-for-change-taiwan/%E7%9E%84%E4%B8%80%E7%9C%BC%E5%9B%9B%E6%AD%A5%E9%A9%9F-%E6%88%91%E5%80%91%E7%9A%84%E8%A1%8C%E5%8B%95-%E6%9C%83%E5%A6%82%E4%BD%95%E5%BD%B1%E9%9F%BF%E4%BD%BF%E7%94%A8%E8%80%85-6b34aa614f40' target='_blank'>瞄一眼 DFC 四步驟：實踐，我們的行動，會如何影響使用者？</a>",
						"<a href='https://medium.com/design-for-change-taiwan/%E7%9E%84%E4%B8%80%E7%9C%BC%E5%9B%9B%E6%AD%A5%E9%A9%9F-%E8%AA%AA%E8%91%97%E8%87%AA%E5%B7%B1%E7%9A%84%E6%95%85%E4%BA%8B-%E5%BB%BA%E7%AB%8B%E7%9C%9F%E6%AD%A3%E7%9A%84%E8%87%AA%E4%BF%A1-f760581b2c49' target='_blank'>瞄一眼 DFC 四步驟：分享，說著自己的故事、建立真正的自信</a>",
						"<a href='https://www.dfcworld.com/file2015/research_2.pdf' target='_blank'>相關國際研究</a>",
					],
					link: "https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=8",
				},
				{
					thumb: require("@/assets/publications/book2.png"),
					title: "《報告！這裡沒有校長室》 親子天下 2018 年再版",
					suggestion: "建議對象：教師、家長、關注教育議題的讀者",
					descriptions: [
						"電影《三個傻瓜》那所令人嚮往的學校，原來真實存在！印度的河濱學校裡沒有神秘兮兮的校長室，校長 Kiran Bir Sethi 特別把辦公座位搬到學生必經之處，樂意聽取學生的建議，更蓋了一座可以泡腳「涼快一夏」的教室！本書收錄 DFC 臺灣發起人許芯瑋及共同發起人上官良治親自赴印度取經，介紹印度河濱學校的辦學理念，更透過與該校師生的互動經驗分享，為臺灣讀者帶來第一手、最熱血的所見所聞。",
					],
					link: "https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=8",
				},
				{
					thumb: require("@/assets/publications/book3.png"),
					title: "《設計思考：從教育開始的破框思維》 親子天下2017 年出版",
					suggestion: "建議對象：教師、家長、關注教育議題的讀者",
					descriptions: [
						"由親子天下出版，第一本針對教育教學現場而寫的設計思考書籍，引導老師成為課堂裡的教學設計師！",
						"",
						"書中不但直擊了美國 IDEO 總部教育工作室、史丹佛大學 d.school，一窺設計思考發源地全貌；也收錄台大創新設計學院與 Design for Change（DFC）臺灣團隊，本土兩大推動設計思考團隊在臺灣校園推動設計思考的精華心得與心法。",
						"",
						"而在本書中，DFC 臺灣團隊分享了「經過教育現場調整的設計思考」-- DFC 四步驟：感受、想像、實踐、分享，如何融入在臺灣現有的教育體制中的心法，並期待能透過分享，讓更多教育實踐者能帶著孩子們有更多活用所學、嘗試解決身邊問題的機會，一同打造讓孩子主動學習的環境！",
					],
					link: "https://shopping.parenting.com.tw/products/694",
				},
				{
					thumb: require("@/assets/publications/book4.png"),
					title: "《改變世界，我可以！》 遠流 2015 年出版",
					suggestion: "建議對象：中學以下學生、關注教育議題的讀者",
					descriptions: [
						"本書收錄 24 篇全球各地（包含臺灣）的創意故事，面對生活周遭出現的各種問題，孩子沒有選擇沉默或是抱怨，而是捲起袖子、用自己的力量改變現狀！我們的孩子已經找到了「解決問題」的超能力，他們正運用自己的創意，一點一滴改善現況、改變世界。此外，每則故事皆附有引導思考的學習單，誠摯推薦教師們在課堂上引用這些故事，做為激勵孩子的教材，引導孩子將所學知識應用於改善生活之用。",
					],
					link: "https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=8",
				},
				{
					thumb: require("@/assets/publications/book5.png"),
					title: "《給孩子改變世界的機會》凱信企管 2012 年出版，已絕版，請至各縣市圖書館借閱",
					suggestion: "建議對象：教師、家長、關注教育議題的讀者、中學生",
					descriptions: [
						"Design For Change 全球首部專書，介紹全球孩童創意行動挑戰，以及 DFC 在臺灣的實際活動經驗。書中詳細說明如何運用感受、想像、實踐、分享四步驟，鼓勵每個人都可以「成為心中想要看見的改變」。本書特別精選 12 則臺灣孩子的 DFC 真實故事，並追蹤孩子們的後續改變，以及行動對於他人和社區的後續影響。",
					],
					link: "",
				},
				{
					thumb: require("@/assets/publications/book6.png"),
					title: "《童心協力 翻轉地球》凱信企管 2012 年出版，已絕版，請至各縣市圖書館借閱",
					suggestion: "建議對象：中學以下學生",
					descriptions: [
						"本書在印度由銷量最大的漫畫書商（Amar Chitra Katha）發行、兒童文學作家 Dr. Devika Rangachari 忠實呈現孩子們透過實踐力改變世界的精彩實錄！並由知名印度電影《三個傻瓜》編劇 Abhijat Joshi 序文推薦。每則故事附有該國地理、歷史介紹，引導孩子理解各國文化，並隨著故事內容和各國孩子們一同解決生活周遭的問題。",
					],
					link: "",
				},
			],
			libraries: [
				"國家圖書館",
				"立法院國會圖書館",
				"國立公共資訊圖書館總館",
				"國立公資圖-中興分館",
				"國立公資圖-精武分館",
				"國立公資圖-黎明分館",
				"國立臺灣圖書館",
				"臺北市立圖書館",
				"新北市立圖書館",
				"桃園市立圖書館",
				"臺中市公共圖書館",
				"臺南市公共圖書館",
				"高雄市立圖書館",
				"基隆市公共圖書館",
				"新竹市公共圖書館",
				"新竹縣政府文化局圖書館",
				"苗栗縣立圖書館",
				"彰化縣文化局圖書館",
				"南投縣政府文化局圖書館",
				"雲林縣政府文化處圖書館",
				"嘉義市政府文化局圖書館",
				"嘉義縣政府教育處圖書館",
				"屏東縣政府文化處圖書館",
				"宜蘭縣政府文化局圖書館",
				"花蓮縣文化局圖書館",
				"臺東縣政府文化處圖書館",
				"澎湖縣政府文化局圖書館",
				"金門縣文化局圖書館",
				"連江縣政府文化局圖書館",
			],
		}
	},
}
</script>

<style lang="scss">
#publications {
	line-height: 1.7;
	a {
		color: $red;
		text-decoration: underline;
	}
	.rwd-table {
		@include bordered-table;
		background: #fff;
		color: #5d5e5f;
		@media screen and (max-width: 425px) {
			text-align: center;
		}
		td {
			padding: 5px 1em;
		}
	}
}
</style>

<style lang="scss" scoped>
#banner-clip-border {
	padding-top: 3em;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1em));
	@media screen and (min-width: 768px) {
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - 1.5em),
			60% 100%,
			20% calc(100% - 1.5em),
			0 100%
		);
	}
}
</style>