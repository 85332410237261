<template>
	<ContentBlock>
		<div id="tabs-menu" class="row-grid" align-x="center">
			<template v-for="(item, i) in tabs">
				<div class="rwd-s-4 center" style="padding: 10px;" :key="i">
					<button @click="handleChange(i)" :class="{ col: $root.rwd < 3, row: $root.rwd >= 3, active: item.active }" :align-x="$root.rwd < 3 ? 'center' : ''" align-y="center">
						<img :src="item.img">
						<span>{{item.text}}</span>
					</button>
				</div>
			</template>
		</div>
		<div style="padding:3%;" />
		<el-card>
			<template v-for="(item, i) in tabs">
				<div v-if="item.active" :key="i" v-loading="!data[item.category].length && loading">
					<el-table :data="data[item.category]" stripe size="large" :show-header="$root.rwd < 3">
						<template v-if="$root.rwd < 3">
							<el-table-column label="日期" prop="date" min-width="100">
								<template slot-scope="{row}">{{row.date | dateFilter}}</template>
							</el-table-column>
							<el-table-column label="標題" prop="title" min-width="200">
								<template slot-scope="{row}">
									<a :href="row.link" target="_blank">
										<pre style="margin:0;">{{row.title}}</pre>
									</a>
								</template>
							</el-table-column>
							<el-table-column label="出處" prop="publicate_by" min-width="100">
								<template slot-scope="{row}">
									<a :href="row.link" target="_blank">{{row.publicate_by}}</a>
								</template>
							</el-table-column>
						</template>
						<template v-else>
							<el-table-column>
								<template slot-scope="{row}">
									{{row.date | dateFilter}}
									<br>
									<a :href="row.link" target="_blank">
										<pre>{{row.title}}</pre>
									</a>
									<br>
									{{row.publicate_by}}
								</template>
							</el-table-column>
						</template>
					</el-table>
					<br>
					<div v-if="cursor[item.category]" class="center">
						<el-button type="text" icon="el-icon-arrow-down" @click="handleGetList(item.category)" :loading="loading">載入更多</el-button>
					</div>
				</div>
			</template>
		</el-card>
	</ContentBlock>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StickyMenu2 from "../StickyMenu2.vue";
export default {
	components: {
		StickyMenu2,
	},
	data() {
		return {
			loading: false,
			dialogVisible: false,
			ruleForm: new Object,
			currentTab: "",
			rules: {
				category: { required: true, message: "必填", trigger: "blur" },
				date: { required: true, message: "必填", trigger: "blur" },
				title: { required: true, message: "必填", trigger: "blur" },
				publicate_by: { required: true, message: "必填", trigger: "blur" },
			},
			cursor: {
				team_concept: 1,
				teaching_learning: 1,
				direct_activity: 1,
			},
			tabs: new Array,
		}
	},
	computed: {
		...mapState("media-center", [
			"_categories",
			"_team_concept_list",
			"_teaching_learning_list",
			"_direct_activity_list",
		]),
		data() {
			return {
				team_concept: this._team_concept_list,
				teaching_learning: this._teaching_learning_list,
				direct_activity: this._direct_activity_list,
			}
		},
	},
	watch: {
		async currentTab(val) {
			if (!this[`_${val}_list`].length) {
				this.loading = true;
				await this.handleGetList(val);
				this.loading = false;
			};
		},
	},
	methods: {
		...mapActions("media-center", [
			"_getListByCategory",
		]),
		async handleGetList(category) {
			this.loading = true;
			const { cursor } = await this._getListByCategory({
				category,
				cursor: this.cursor[category],
			});
			this.cursor[category] = cursor;
			this.loading = false;
		},
		handleChange(index) {
			this.tabs.forEach((item, i) => {
				if (index == i) {
					this.currentTab = item.category;
					this.tabs[i].active = true;
				} else this.tabs[i].active = false;
			});
		},
	},
	async created() {
		this.currentTab = Object.keys(this._categories)[0];
		this.tabs = Object.keys(this._categories)
			.map((category, i) => ({
				text: this._categories[category],
				active: i == 0,
				img: require(`@/assets/media-center/icon${i + 1}.png`),
				category,
			}))
	},
}
</script>

<style lang="scss" scoped>
#tabs-menu {
	max-width: 700px;
	margin: auto;
	button {
		cursor: pointer;
		width: 100%;
		height: 100%;
		line-height: 1.5;
		color: black;
		border: 1px solid #dc3d3c;
		padding: 1em;
		transition: background 0.3s;
		border-radius: 6px;
		text-align: center;
		outline: none;
		background: transparent;

		@mixin active {
			background: $red;
			color: white;
		}
		&.active {
			@include active;
		}
		@media (hover: hover) {
			&:hover {
				@include active;
			}
		}

		img {
			width: 6em;
			margin: 0 0 0.5em;
		}

		@media screen and (min-width: 768px) {
			font-size: 1.2em;
		}

		@media screen and (max-width: 425px) {
			border-radius: 100px;
			padding: 0.5em 1em;

			img {
				margin: 0 1em;
				width: 2em;
			}
		}
	}
}
a {
	text-decoration: underline;
	@media screen and (max-width: 425px) {
		color: $red;
	}
	&:hover {
		@media (hover: hover) {
			color: $red;
		}
	}
}
</style>