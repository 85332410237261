<template>
	<ContentBlock clip bg-color="white1">
		<h1 class="center">志工回饋</h1>
		<template v-if="$root.rwd < 2">
			<template v-for="(item, i) in list">
				<VolunteerCard :data="item" :key="i" />
			</template>
		</template>
		<Siema ref="siema" v-else :data="list" :options="{ loop: true  }" control>
			<template v-slot="{slide}">
				<VolunteerCard :data="slide" />
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
	</ContentBlock>
</template>

<script>
import VolunteerCard from "./VolunteerCard.vue"
import Siema from "../Siema.vue";
export default {
	components: {
		VolunteerCard,
		Siema,
	},
	data() {
		return {
			list: [
				{
					img: require("../../assets/join-us/img2.png"),
					says: "「在協會推廣的 DFC 學習法中，我看見與自己的教育理念不謀而合的『相信』。我是在開始了解偏鄉教育議題，透過志工服務接觸不同族群的人後，才慢慢找到屬於自己真正想投入教育的原因和自己的教育理念。」",
					who: "協會年度志工  序旻",
				},
				{
					img: require("../../assets/join-us/img3.png"),
					says: "「在加入 DFC 臺灣的一年中，改寫了許多從小島四面八方投遞來的故事，除了看見老師們手把手的帶孩子迎接每一個挑戰，孩子們豐富的想像力、勇敢的創造力及強大的實踐力，都讓我十分佩服！也讓我更相信，這些小小的孩子，都有著顛覆我們想像的超能力，能 #為自己、#為世界 盡一份心力！」",
					who: "DFC 挑戰故事志工  亭儀",
				},
				{
					img: require("../../assets/join-us/img4.png"),
					says: "「我開始更積極的關注社會上的議題，關心地球，在遇到問題時，少一點抱怨、多一些換位思考，也透過翻譯工作坊的教材，認識了不同引導孩子參與教學的方法。\n\n久而久之，漸漸的對於翻譯任務有了層使命感：『讓孩子們的創意能夠被更多人看見，讓大人不再小看孩子們的超能力！』」",
					who: "外文翻譯志工  虹瑾",
				},
			],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	.right,
	.left {
		top: 20% !important;
	}
}
</style>