<template>
	<ContentBlock clip bg-color="red4" color="white1">
		<h1 style="text-align:center;">服務內容</h1>
		<br />
		<div class="row-grid" align-x="around" gutter-x="10" gutter-y="10">
			<template v-for="(item, i) in list">
				<div class="col rwd-m-2 rwd-s-4 rwd-6" align-x="center" :key="i">
					<img :src="item.img" width="100" />
					<pre style="text-align: center;">{{ item.text }}</pre>
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					img: require("../../assets/dfc-teacher/img4.png"),
					text: "48小時培訓課程",
				},
				{
					img: require("../../assets/dfc-teacher/img5.png"),
					text: "協會專業講師的諮詢與陪伴",
				},
				{
					img: require("../../assets/dfc-teacher/img6.png"),
					text: "社群交流",
				},
				{
					img: require("../../assets/dfc-teacher/img7.png"),
					text: "通過認證者將擔任 \nDFC 學習法推廣講師",
				},
				{
					img: require("../../assets/dfc-teacher/img8.png"),
					text: "通過認證者將擔任 DFC 種子學校共同備課講師",
				},
			],
		};
	},
};
</script>
