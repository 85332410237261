<template>
	<section
		v-loading="loading"
		element-loading-background="rgba(255, 242, 236, 0.8)"
		style="margin: auto; max-width: 1200px; padding: 3%"
	>
		<el-page-header
			@back="$router.push(previousPage)"
			class="row"
			align-y="baseline"
		>
			<h1 slot="content" style="text-align: left; line-height: 1.2">
				{{ _story.name }} {{ _story.account }}
			</h1>
		</el-page-header>
		<br />
		<section class="main">
			<table>
				<tr>
					<td>學校單位：</td>
					<td>{{ _story.school }}</td>
				</tr>
				<tr>
					<td>輔導員：</td>
					<td>
						{{ _story.counselors && _story.counselors.join("、") }}
					</td>
				</tr>
				<tr>
					<td>小推手：</td>
					<td>
						{{
							_story.team_members &&
							_story.team_members.join("、")
						}}
					</td>
				</tr>
				<tr>
					<td>SDGs：</td>
					<td>{{ _story.sdgs && _story.sdgs.join("、") }}</td>
				</tr>
			</table>
			<br />
			<div class="row-grid" align-y="top" gutter-x="20" gutter-y="20">
				<div
					class="row-grid rwd-l-5"
					align-y="top"
					gutter-x="10"
					gutter-y="10"
				>
					<div
						v-if="_story.pictures && _story.pictures.length"
						class="rwd-m-12 rwd-s-6"
					>
						<Siema
							:data="_story.pictures"
							:options="{ loop: true, interval: 5000 }"
							indicator
						>
							<template v-slot="{ slide }">
								<img :src="slide" width="100%" />
							</template>
						</Siema>
					</div>
					<div v-if="_story.video" class="rwd-m-12 rwd-s-6">
						<Ratio ratio="youtube">
							<iframe
								:src="_story.video | youtubeFilter"
								width="100%"
								height="100%"
								frameborder="0"
								allowfullscreen
							/>
						</Ratio>
					</div>
				</div>
				<div class="col rwd-l-7" align-x="left">
					<span class="category-title" style="background: #ef6086"
						>感受</span
					>
					<pre style="background: rgba(239, 96, 134, 0.1)">{{
						_story.description_feeling
					}}</pre>
					<br />
					<span class="category-title" style="background: #ffc82f"
						>想像</span
					>
					<pre style="background: rgba(255, 200, 47, 0.1)">{{
						_story.description_imagine
					}}</pre>
					<br />
					<span class="category-title" style="background: #b8d200"
						>實踐</span
					>
					<pre style="background: rgba(184, 210, 0, 0.1)">{{
						_story.description_implementation
					}}</pre>
					<br />
					<span class="category-title" style="background: #008ed8"
						>分享</span
					>
					<pre style="background: rgba(0, 142, 216, 0.1)">{{
						_story.description_sharing
					}}</pre>
				</div>
			</div>
		</section>
		<br />
		<br />
		<br />
		<br />
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Siema from "../../components/Siema.vue";
export default {
	props: ["year", "id"],
	components: {
		Siema,
	},
	data() {
		return {
			previousPage: "",
			loading: true,
		};
	},
	computed: {
		...mapState("story", ["_story"]),
		params() {
			return {
				year: this.year,
				id: this.id,
			};
		},
	},
	methods: {
		...mapActions("story", ["_getStory"]),
	},
	async created() {
		try {
			this.previousPage = `/story/${this.year}`;
			await this._getStory(this.params);
		} catch (error) {
			console.log({ error });
			this.$message.error(error.message);
		}
		this.loading = false;
	},
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
	.main {
		padding-left: 6em;
	}
}

.el-image {
	width: 100%;
	height: 100%;
}

.siema,
.ratio {
	width: 100%;
}

.category-title {
	color: #fff;
	font-size: 1.3em;
	padding: 5px 1em;

	+ pre {
		padding: 2em;
		width: 100%;
	}
}

.el-tag {
	margin: 5px;
}

table {
	th {
		text-align: right;
		font-weight: normal;
	}

	td {
		vertical-align: top;

		&:first-child:not(:last-child) {
			white-space: nowrap;
			text-align: right;
		}
	}
}
</style>
