<template>
	<ContentBlock clip bg-color="red4" color="white1">
		<div v-if="$root.rwd > 2" class="row" align-x="around">
			<template v-for="(item, i) in list">
				<el-button class="switch-btn" :key="i" @click="listSwitch = i" :class="{active:listSwitch==i}">{{item.title}}</el-button>
			</template>
		</div>
		<br>
		<div class="row" align-x="around" gutter-x="10">
			<div v-for="(column, i) in computedList" :key="i" class="col rwd-m-5 rwd-s-6">
				<p class="center" style="font-size:1.3em; font-weight:bold;">{{column.title}}</p>
				<Siema :data="column.list" :options="{ loop: true }" indicator>
					<template v-slot="{slide}">
						<div class="col" align-x="center">
							<img :src="slide.avatar" width="124">
							<br>
							<p style="font-size:smaller;">{{slide.school}}</p>
							<p>{{slide.who}}</p>
							<pre style="font-size:smaller;">{{slide.content}}</pre>
						</div>
					</template>
				</Siema>
			</div>
		</div>
	</ContentBlock>
</template>

<script>
import Siema from "../Siema.vue";
export default {
	components: {
		Siema,
	},
	data() {
		return {
			listSwitch: 0,
			list: [
				{
					title: "參與的老師說⋯",
					list: [
						{
							avatar: require("@/assets/dfc-challenge/avatar_teacher1.png"),
							school: "彰化南郭國小",
							who: "陳宥妤 老師",
							content: "帶領孩子參加 DFC，永遠都有感動與成長在發生，然後在很多當下我們看到的更多是以前那個自己，現在再一次有機會，讓我們透過孩子，一起去改變它可能的樣貌，是一種對自我的挑戰！\n\n感受現在生活周遭的一切，想像一個你跟孩子都將被改變的機會，試著執行一個不算大不算小的行動後，會有更多的溫暖和力量與你分享，因為我們值得。"
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_teacher2.png"),
							school: "桃園瑞塘國小",
							who: "鄭志誠 主任",
							content: "教學者之間的「共好」就是，在你的教室很好，你可以把你的方法渲染、傳染、分享給你周邊的人，然後讓你校園裡面的很多老師跟你一樣都有很多正向能量，然後他很想要去解決他生活當中教學上遇到的問題、影響更多學生。"
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_teacher1.png"),
							school: "南投縣立北梅國中",
							who: "許碧蕙 老師",
							content: "我覺得在透過「感受」、「想像」、「實踐」、「分享」的過程當中，讓孩子更成為\n一個人，而不是一個讀書的機器，這是我覺得 DFC 挑戰最棒的地方。"
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_teacher2.png"),
							school: "新北市土城國小",
							who: "徐意婷 老師",
							content: "不管孩子在過程中失敗還是跌倒，都無所謂，因為我們還在，我們還可以幫他，但如果他長大了，跌了大跟斗，卻沒有過失敗的經驗，他可能就爬不起來了。"
						},
					]
				},
				{
					title: "參與的孩子說⋯",
					list: [
						{
							avatar: require("@/assets/dfc-challenge/avatar_child1.png"),
							school: "第十四屆 DFC 挑戰參與者",
							who: "臺中市建功國小",
							content: "透過 DFC 挑戰，我們可以從不同角度去思考問題，也能讓我們欣賞同學有趣的想法。",
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_child2.png"),
							school: "第十四屆 DFC 挑戰參與者",
							who: "雲林縣口湖國中",
							content: "DFC 挑戰讓我們學習到不要害怕面對問題。當別人說我們無法改變現況時，我們要相信自己是有潛力可以改變現況。",
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_child3.png"),
							school: "第十三屆 DFC 挑戰參與者",
							who: "花蓮縣宜昌國小",
							content: "只要願意開始做，即使遇到困難，也都會有解決的方式。所以最重要的就是決定開始做的那一步！",
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_child4.png"),
							school: "第十三屆 DFC 挑戰參與者",
							who: "臺北市麗湖國小",
							content: "我們不會覺得生活有什麼問題，那是因為我們習慣了。但當我們認真的思考、認真的去看，才會發現還是有很多問題，而我們可以去解決它。",
						},
						{
							avatar: require("@/assets/dfc-challenge/avatar_child2.png"),
							school: "第十三屆 DFC 挑戰參與者",
							who: "新竹市康橋國際學校",
							content: "我們想告訴自己和其他人，勇敢地挑戰自己的想法，並且不要害怕失敗。",
						},
					]
				}
			],
		}
	},
	computed: {
		computedList() {
			return this.$root.rwd < 3
				? this.list
				: this.list.slice(this.listSwitch, this.listSwitch + 1);
		}
	}
}
</script>

<style lang="scss" scoped>
.switch-btn {
	background: transparent;
	color: #fff;

	&.active {
		background: #fff;
		color: #000;
	}
}
.siema {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>