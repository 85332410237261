<template>
	<div>
		<el-tag :key="tag + i" v-for="(tag, i) in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
			{{ tag }}
		</el-tag>
		<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
		</el-input>
		<el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
	</div>
</template>

<script>
export default {
	props: {
		data: Array,
	},
	model: {
		prop: "data",
		event: "change",
	},
	data() {
		return {
			inputVisible: false,
			inputValue: ""
		};
	},
	computed: {
		dynamicTags: {
			get() {
				return this.data;
			},
			set(val) {
				this.$emit("change", val);
			}
		}
	},
	methods: {
		handleClose(tag) {
			this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.dynamicTags.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = "";
		}
	}
}
</script>

<style lang="scss" scoped>
.el-tag {

	&+.el-tag,
	&+.el-input,
	&+.el-button {
		margin-left: 10px;
	}
}

.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>