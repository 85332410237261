<template>
	<ContentBlock>
		<h2 style="text-align:center;">
			<span style="display:inline-block;">面對教育新浪潮，</span>
			<span style="display:inline-block;">老師就是主動學習、</span>
			<br>擁有不斷嘗試自信心的榜樣。
		</h2>
		<br>
		<div id="section1-grid" class="row-grid" gutter-x="20" align-x="around">
			<template v-for="(item, i) in list">
				<div class="row-grid rwd-s-3" gutter-y="15" :key="i">
					<img :src="item.img" class="rwd-s-12 rwd-xs-6">
					<pre>{{item.text}}</pre>
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					img: require("@/assets/teacher-list/icon1.png"),
					text: "以設計思考實現\n自己的教育理念"
				},
				{
					img: require("@/assets/teacher-list/icon2.png"),
					text: "以人為本\n社群合作"
				},
				{
					img: require("@/assets/teacher-list/icon3.png"),
					text: "影響力擴散"
				},
			],
		}
	}
}
</script>

<style lang="scss" scoped>
#section1-grid {
	.row-grid {
		align-items: center;
		&:nth-child(even) {
			flex-direction: row-reverse;
		}
		@media screen and (min-width: 769px) {
			align-items: flex-start;
		}
		pre {
			flex: 1;
			text-align: center;
			font-size: 1.25em;
		}
		@media screen and (max-width: 425px) {
			img {
				padding: 1em;
			}
		}
	}
}
</style>