<template>
	<ContentBlock clip bg-color="red4" color="white1">
		<h1 style="text-align:center;">服務內容</h1>
		<br />
		<div class="row-grid" align-x="around" gutter-x="10" gutter-y="10">
			<template v-for="(item, i) in list">
				<div class="col rwd-m-2 rwd-s-4 rwd-6" align-x="center" :key="i">
					<img :src="item.img" width="100" />
					<pre><span style="font-size: 1.5em;">{{item.num}}</span>&nbsp;{{item.text}}</pre>
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					img: require("../../assets/dfc-school/img9.png"),
					num: 2,
					text: "位協會專業講師",
				},
				{
					img: require("../../assets/dfc-school/img10.png"),
					num: 2,
					text: "次觀議課",
				},
				{
					img: require("../../assets/dfc-school/img11.png"),
					num: 6,
					text: "次共備工作坊\n（180 分鐘／次）",
				},
				{
					img: require("../../assets/dfc-school/img12.png"),
					num: 1,
					text: "次入校說明會\n（90 分鐘）",
				},
				{
					img: require("../../assets/dfc-school/img13.png"),
					num: 1,
					text: "次線上讀書會\n（90 分鐘）",
				},
				{
					img: require("../../assets/dfc-school/img14.png"),
					num: 1,
					text: "年後續服務陪伴\n（90 分鐘）",
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped></style>
