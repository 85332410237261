<template>
	<section id="speech-workshop">
		<TopBanner>
			<img src="../../assets/speech-workshop/banner.jpg" />
		</TopBanner>
		<img
			src="../../assets/speech-workshop/title.png"
			class="page-title"
			width="450"
		/>

		<ContentBlock style="padding-bottom: 0">
			<p>
				<span color="red4"
					>社團法人臺灣童心創意行動協會（DFC
					臺灣）將源自印度河濱學校的 Design for Change
					教育理念引入臺灣，將設計思考融入教學現場，以 DFC
					學習法四步驟讓孩童體驗解決問題的行動歷程，探索其多元潛力，提升孩童的學習自信，形塑一個讓臺灣孩童發揮潛力的教育未來。</span
				>
				<br />
				<br />
				<span color="red4"
					>讓我們邀請您填寫下表，讓我們透過理解更多貴組織的需求，為您媒合協會適切的服務。</span
				>
				<br />
				<br />
				註：2024 年起，DFC
				臺灣重新規劃各校邀約演講和工作坊的執行原則，我們希望從原本的邀約制，改為主動制，並且採取合併演講和工作坊的策略。若您希望收到
				DFC
				臺灣辦理區域演講與工作坊的資訊，讓我們邀請您於下列表單留下聯繫方式，我們會第一時間通知您，謝謝您理解我們的策略變更。
			</p>
			<div class="center">
				<DfcButton
					theme="red"
					round
					arrow
					href="https://form.jotform.com/240518470260450"
					target="_blank"
					>填寫服務預約意願蒐集表</DfcButton
				>
			</div>
		</ContentBlock>

		<!-- <section1 id="section1" style="padding-top: 0;" /> -->
		<!-- <section3 ref="section3" id="section3" /> -->
		<!-- <section4 ref="section4" id="section4" /> -->

		<el-dialog
			:visible="dialogVisible"
			width="500px"
			:show-close="false"
			style="color: #e6a23c"
		>
			<h1 style="margin: 0; text-align: center">
				<i class="el-icon-info" style="color: #e6a23c">&emsp;提醒</i>
				<br />請確認已閱讀上方「溫馨提醒」
			</h1>
			<div slot="footer" class="center">
				<el-button @click="handleConfirm(true)">已 閱 讀</el-button>
				<el-button type="primary" @click="handleConfirm(false)"
					>尚 未 閱 讀</el-button
				>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import section1 from "../../components/speech-workshop/section1.vue";
import section3 from "../../components/speech-workshop/section3.vue";
import section4 from "../../components/speech-workshop/section4.vue";
export default {
	components: {
		section1,
		section3,
		section4,
	},
	data() {
		return {
			is_hint: false,
			dialogVisible: false,
		};
	},
	methods: {
		endscroll(e) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				if (
					!this.is_hinted &&
					this.section4.getBoundingClientRect().top <= 500
				) {
					this.is_hinted = true;
					this.dialogVisible = true;
				}
			}, 66);
		},
		handleConfirm(confirm) {
			if (confirm)
				window.removeEventListener("scroll", this.endscroll, true);
			else {
				const el = this.section3;
				const fixedMenu = document.getElementById("fixed-menu");
				if (el)
					scrollTo({
						left: 0,
						top: el.offsetTop - fixedMenu.offsetHeight,
						behavior: "smooth",
					});
			}
			this.dialogVisible = false;
		},
	},
	mounted() {
		// this.section3 = document.getElementById("section3");
		// this.section4 = document.getElementById("section4");
		// window.addEventListener("scroll", this.endscroll, true);
	},
};
</script>

<style lang="scss" scoped>
#speech-workshop {
	line-height: 1.8;
}
.center {
	margin-top: 50px;
	margin-bottom: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>
