<template>
	<ContentBlock clip>
		<h1 class="center">影響力數據</h1>
		<br />
		<div class="col">
			<template v-for="(row, i) in chunkedList">
				<div :key="i" class="lists">
					<template v-for="(col, j) in row">
						<div class="cell" :key="j">
							<CountUp :num="col.num" :unit="col.unit" :what="col.what" />
						</div>
					</template>
				</div>
			</template>
		</div>
	</ContentBlock>
</template>

<script>
import CountUp from "./CountUp.vue";
export default {
	components: {
		CountUp,
	},
	data() {
		return {
			list: [
				{ num: 364, unit: "所", what: "DFC 挑戰參與學校" },
				{ num: 715, unit: "位", what: "DFC 挑戰參與教育實踐者" },
				{ num: 8539, unit: "位", what: "DFC 挑戰參與學生" },
				{ num: 10, unit: "所", what: "種子學校" },
				{ num: 64, unit: "位", what: "種子教師" },
				{ num: 34, unit: "位", what: "校園大使" },
			],
		};
	},
	computed: {
		chunkedList() {
			const chunk_size_list = [1, 4, 4];
			let arr = Array.from(this.list);
			const chunk_size = Math.ceil(
				arr.length / chunk_size_list[this.$root.rwd - 1]
			);
			return arr.chunk(chunk_size);
		},
	},
};
</script>

<style lang="scss" scoped>
.content-block {
	background: $red;
	color: #fff;
}
.cell {
	flex: 1;
	padding: 10px 0;
	margin-bottom: 0;
	border-bottom: 1px solid #fff;
	@media screen and (min-width: 768px) {
		padding: 0;
		margin-bottom: 50px;
		border-bottom:0;
	}
}
.cell:nth-of-type(3n+2){
	border-left: 0;
	border-right: 0;
	@media screen and (min-width: 768px) {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
	}
}

.lists{
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
</style>
