<template>
	<section>
		<div class="row" align-x="between" align-y="center">
			<router-link to="/" tag="div">
				<img id="logo" src="../../assets/logo-mobile.png" style="height:2em;" />
			</router-link>
			<DfcButton
				theme="yellow"
				small
				href="https://www.dfcchallenge.org"
				target="_blank"
			>
				<small>登入/註冊<br />DFC 挑戰平台</small>
			</DfcButton>
			<DfcButton
				theme="red"
				small
				href="https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=5&utm_source=officailwebsite&utm_medium=cpc&utm_campaign=2023donation"
				target="_blank"
			>
				立即捐款
			</DfcButton>
			<Ratio ratio="square" id="burger-btn">
				<i class="el-icon-s-unfold" @click="visible = !visible"></i>
			</Ratio>
		</div>
		<el-drawer
			class="navber-mobile"
			:visible.sync="visible"
			append-to-body
			size="100%"
		>
			<div class="col">
				<template v-for="(item, i) in list">
					<template v-if="item.length">
						<div class="col" :key="i">
							<div
								class="row anchor"
								align-x="between"
								align-y="center"
								:class="routerLinkActive(item)"
								@click="collapseVisible = !collapseVisible"
								style="padding-bottom:5px; border-bottom: 1px solid;"
							>
								<span>{{ item[0].label }}</span>
								<i class="el-icon-caret-bottom el-icon--right" />
							</div>
							<el-collapse-transition>
								<div
									class="col"
									v-show="collapseVisible"
									style="padding-left:1em;"
								>
									<template v-for="(subitem, j) in item">
										<template v-if="subitem.path || subitem.href">
											<router-link
												v-if="subitem.path"
												:to="subitem.path"
												:key="j"
												class="anchor"
												@click.native="close()"
												:exact="subitem.exact"
											>
												{{ subitem.label }}
											</router-link>
											<a
												v-else-if="subitem.href"
												:href="subitem.href"
												target="_blank"
												:key="j"
												@click="close()"
											>
												{{ subitem.label }}
											</a>
										</template>
									</template>
								</div>
							</el-collapse-transition>
						</div>
					</template>
					<template v-else>
						<router-link
							v-if="item.path"
							:to="item.path"
							:key="i"
							class="anchor"
							@click.native="close()"
							:exact="item.exact"
						>
							{{ item.label }}
						</router-link>
						<a
							v-else-if="item.href"
							:href="item.href"
							target="_blank"
							:key="i"
							@click="close()"
						>
							{{ item.label }}
						</a>
					</template>
				</template>
			</div>
			<br />
			<br />
			<br />
			<div class="col footer" gutter="20">
				<h2 style="margin-top:0; text-align:left;">
					Design for Change, Taiwan
				</h2>
				<p>
					地址：
					<a href="https://g.page/DFCTaiwan" target="_blank" class="underline">
						臺北市中正區紹興北街31巷15弄5號
					</a>
					<br />電話：<a href="tel:02-2368-1310" class="underline">
						02-2368-1310
					</a>
					<br />Email：<a href="/join-us#section5" class="underline">
						service@dfctaiwan.org
					</a>
				</p>
				<div class="row" gutter="10">
					<a href="https://www.instagram.com/dfc.taiwan" target="_blank">
						<img src="../../assets/social-ig.png" width="30" />
					</a>
					<a
						href="https://www.facebook.com/groups/1114591105274908about"
						target="_blank"
					>
						<img src="../../assets/social-fb.png" width="30" />
					</a>
					<a
						href="https://www.youtube.com/user/dfctaiwan/featured"
						target="_blank"
					>
						<img src="../../assets/social-yt.png" width="30" />
					</a>
					<a href="https://medium.com/design-for-change-taiwan" target="_blank">
						<img src="../../assets/social-medium.png" width="30" />
					</a>
				</div>
				<div class="col" gutter="10">
					<DfcButton
						theme="green"
						small
						round
						href="https://www.dfcchallenge.org/"
						target="_blank"
						@click="close()"
					>
						DFC 挑戰平台 登入/註冊
					</DfcButton>
					<DfcButton theme="red" small round @click="dialogVisible = true">
						媒體採訪邀約表單
					</DfcButton>
				</div>
				<small>
					發票捐贈碼：2721 (二心二力）
				</small>
				<small>
					許可證書：臺內社字第1000214388號函
					<br />法人登記：登記簿第94冊第49頁第2077號 <br />統一編號：26398814
				</small>
			</div>
		</el-drawer>
		<el-dialog
			:visible="dialogVisible"
			:width="$root.rwd < 2 ? '600px' : '100%'"
			:show-close="false"
			center
			destroy-on-close
			append-to-body
		>
			<template slot="title">
				<img
					src="../../assets/icon-microphone.svg"
					width="100"
					style="margin:auto;"
				/>
				<h1>訪談、採訪邀約</h1>
			</template>
			<template v-if="dialogVisible">
				<p style="margin:0;">
					感謝各位媒體朋友對於 Design for Change, Taiwan
					的關注，我們很樂意與您分享 DFC
					的理念以及孩子們改變世界的故事，但是由於近期協會接收的採訪邀約漸漸變多，需要請您先協助我們填寫以下表單，
					並提供我們採訪綱要，便於讓我們理解貴單位的採訪需求，我們會在上班時間儘快與您聯繫，若等候過久，也請您來電不吝提醒我們。
					<br />（02）2368-1310
					<br />
					<br />同時，如果您對孩子的DFC故事有興趣的話，也許可引介您採訪有參加
					DFC 挑戰的老師和孩子們，他們才是真正的主角唷！:D
					<br />
					<br />再一次謝謝您！
					<br />
					<br />除突發新聞事件之外，讓我們邀請您於預計採訪日期前10～15個工作天提出採訪邀約，我們會更有充裕的時間調整受訪人力。
					<br />另外，我們也希望能夠於採訪露出後，麻煩貴單位郵寄一份成果，給我們留作紀錄（光碟、雜誌等...）
					<br />
					<br />謝謝您 :)
				</p>
				<br />
				<media-interview-form
					@cancel="dialogVisible = false"
					@done="dialogVisible = false"
				/>
			</template>
		</el-dialog>
	</section>
</template>

<script>
import MediaInterviewForm from "../MediaInterviewForm.vue";
export default {
	components: {
		MediaInterviewForm,
	},
	props: ["list"],
	data() {
		return {
			visible: false,
			dialogVisible: false,
			collapseVisible: false,
		};
	},
	methods: {
		routerLinkActive(arr) {
			return arr.some(({ path }) => this.$route.path.includes(path))
				? "router-link-active"
				: "";
		},
		close() {
			this.visible = false;
			this.collapseVisible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
#burger-btn {
	position: relative;
	background: $red;
	width: 1.5em;
	color: #fff;
	font-size: 2em;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
.anchor {
	vertical-align: top;
	cursor: pointer;

	&.router-link-active {
		color: $red;
		border-bottom: 1.5px solid $red;
	}
}
.el-drawer {
	.dfc-button {
		width: 250px;
	}
	a,
	.anchor {
		padding-top: 0.8em;
	}
}
</style>

<style lang="scss">
.el-drawer {
	background: $background;
}
.el-drawer__body {
	overflow: auto;
	padding: 0 1em 2em;
}
#el-drawer__title.el-drawer__header {
	margin-bottom: 0;
}
</style>
