<template>
	<div class="card row-grid" align-y="top" align-x="center" gutter-x="25" gutter-y="20">
		<img :src="data.img" class="rwd-m-5 rwd-s-7">
		<div class="rwd-m-7">
			<div class="col" gutter="10">
				<h2 style="text-align:left;">{{data.title}}</h2>
				<hr style="display:block; width:4em; height:3px; background:#a2b93f; margin-top:0.5em;">
				<pre>{{data.description}}</pre>
				<br>
				<h3 style="text-align:left; color:#7d7d7d;">合作案例</h3>
				<ul style="list-style:none; padding:0;">
					<li v-for="item in data.cases" :key="item.text">
						<template v-if="item.url">
							<a :href="item.url" class="underline" target="_blank">{{item.text}}</a>
						</template>
						<template v-else>{{item.text}}</template>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["data"],
}
</script>

<style lang="scss" scoped>
.card {
	border-radius: 1em;
	padding: 1em;
	margin: 0 !important;
	margin-top: 2.5em !important;
	margin-bottom: 2.5em !important;
	background: #fff;
	font-size: smaller;
}
</style>