<template>
	<ContentBlock clip bg-color="white1">
		<h1 class="center">年度成果報告書</h1>
		<br>
		<Siema :data="list" :options="{ loop: true, perPage: { 769: 5, 426: 3 }  }" :indicator="$root.rwd > 1" :control="$root.rwd > 1">
			<template v-slot="{slide}">
				<a :href="slide.documentURL" target="_blank" class="center">
					<img :src="slide.thumbURL" width="90%">
				</a>
			</template>
		</Siema>
	</ContentBlock>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Siema from "../Siema.vue";
export default {
	components: {
		Siema,
	},
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		...mapState("about", [
			"_achievementReport"
		]),
		list() {
			return this._achievementReport.list || new Array;
		},
	},
	methods: {
		...mapActions("about", [
			"_getData",
		]),
	},
	async created() {
		await this._getData("achievement-report");
		this.loading = false;
	},
}
</script>

<style lang="scss" scoped>
.siema {
	--var-indicator-color: #eb6165;
}
</style>