export default {
	data() {
		return {
			statusChanged: false,
		}
	},
	methods: {
		preventReload(e) {
			if (!this.statusChanged) return;
			e.preventDefault()
			e.returnValue = "";
		},
	},
	created() {
		window.addEventListener("beforeunload", this.preventReload);
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.preventReload);
	},
	async beforeRouteLeave(to, from, next) {
		const confirm = !this.statusChanged
			|| await this.$confirm("有尚未儲存的編輯項目", "確定離開？", { type: "warning" });
		if (confirm) next();
		else next(false);
	}
}