<template>
	<ContentBlock clip style="color:#fff; background:#f46852; line-height:2;">
		<h1 class="center">關於 DFC 挑戰分享季</h1>
		<p>我們致力於創造屬於孩子的多元舞台，將孩子改變世界的故事透過各種管道，不受限地分享。期望能帶來更多啟發與影響，使每個人皆相信：「自己就是問題的解方！」。除了砥礪孩子長出自信心，願意化知識為行動之外，在未來面對人生裡大小挑戰時，都能勇敢說出「我做得到」！</p>
		<br>
		<br>
		<ArticleBlock picture-position="left" class="scaled">
			<template slot="paragraph">
				<h2>全球年會</h2>
				<hr style="background:#fff;">
				<p>每年世界各國的 DFC 夥伴帶著孩子齊聚一堂分享故事，相互交流，此活動不僅增加 DFC 臺灣在國際的形象，我們更於 2018 年爭取到舉辦權。 近兩年由於新型冠狀病毒疫情改以線上舉行，不論實體或線上，每年皆有孩子代表臺灣至世界各地參與。</p>
			</template>
			<img slot="picture" src="../../assets/dfc-challenge/img1.png">
		</ArticleBlock>
		<br>
		<br>
		<ArticleBlock picture-position="right" class="scaled">
			<template slot="paragraph">
				<h2>各式分享舞台</h2>
				<hr style="background:#fff;">
				<p>因應經費和策略重置，2020 年起，我們將每年的單場實體分享大會改以多種形式呈現，打造多樣貌的舞台，包括：電視節目錄製、廣播電台、公開演講等等。亦積極與不同組織單位合作，使分享方式拓展至各產業與受眾，讓孩子能更不受場域限制地分享。</p>
			</template>
			<img slot="picture" src="../../assets/dfc-challenge/img2.png">
		</ArticleBlock>
	</ContentBlock>
</template>

<script>
import ArticleBlock from "../ArticleBlock.vue"
export default {
	components: {
		ArticleBlock,
	},
}
</script>