<template>
	<section id="teacher-list">
		<div bg-color="white1">
			<TopBanner>
				<img src="../../assets/teacher-list/banner.jpg" />
			</TopBanner>
			<img
				src="../../assets/teacher-list/title.png"
				class="page-title"
				width="610"
			/>

			<section1 id="section1" />

			<template>
				<div class="button-con">
					<div class="center">
						<DfcButton
							theme="red"
							round
							arrow
							href="https://dfcteacher-advanced-training.vercel.app/"
							target="_blank"
							class="button"
							>下載 DFC 種子教師簡章</DfcButton
						>
						<DfcButton
							theme="red"
							round
							arrow
							href="https://www.jotform.com/form/233242795070455"
							target="_blank"
							class="button form"
							>申請表單</DfcButton
						>
					</div>
				</div>
				<br />
				<br />
			</template>
		</div>
		<div id="banner-clip-border" bg-color="white1" />

		<section2 id="section2" />
	</section>
</template>

<script>
import section1 from "../../components/teacher-list/section1.vue";
import section2 from "../../components/teacher-list/section2.vue";
export default {
	components: {
		section1,
		section2,
	},
};
</script>

<style lang="scss" scoped>
#banner-clip-border {
	padding-top: 3em;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1em));
	@media screen and (min-width: 768px) {
		clip-path: polygon(
			0 0,
			100% 0,
			100% calc(100% - 1.5em),
			60% 100%,
			20% calc(100% - 1.5em),
			0 100%
		);
	}
}
.button-con{
	display: flex;
	justify-content: center;
	.center{
		display: flex;
		flex-direction: column;
		width: 90%;
		max-width: 1000px !important;
		.button{
			width: 100%;
			margin: 0 10px;
			@media screen and (min-width: 768px) {
				width: 31%;
			}
		}
		.form{
			margin-top: 1rem;
			@media screen and (min-width: 768px) {
				margin-top: 0;
			}
		}
		@media screen and (min-width: 768px) {
			flex-direction: row;
		}
	}
}

</style>
