<template>
	<div class="volunteer-card row-grid" align-y="center" align-x="center" gutter-x="25" gutter-y="20">
		<img :src="data.img" width="180">
		<div class="rwd-m-8">
			<div class="content col" gutter="10">
				<pre>{{data.says}}</pre>
				<pre style="text-align:right;">——&nbsp;{{data.who}}</pre>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["data"],
}
</script>

<style lang="scss" scoped>
.volunteer-card {
	margin-bottom: 2.5em !important;
}
.content {
	padding: 1em;
	border-radius: 10px;
	@media screen and (min-width: 426px) {
		background: #f3f3f3;
	}
}
</style>