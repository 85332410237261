<template>
	<div class="siema">
		<div ref="siema">
			<template v-for="(item, i) in data">
				<slot :slide="item" :index="i" />
			</template>
		</div>
		<template v-if="control && length > 1">
			<slot name="prev">
				<button class="arrow left" @click="prev()" />
			</slot>
			<slot name="next">
				<button class="arrow right" @click="next()" />
			</slot>
		</template>
		<div v-if="indicator && length > 1" class="indicators" :class="indicator">
			<template v-for="i in length">
				<i ref="indicator" @click="goTo(i - 1)" :key="i" />
			</template>
		</div>
	</div>
</template>

<script>
import Siema from "siema";
export default {
	props: {
		data: {
			type: [Number, Array],
			required: true
		},
		options: {
			type: Object,
			default: () => new Object,
			validator: val => {
				const { interval, duration } = val;
				try {
					if (interval && (interval < 0 || interval < duration)) throw new Error("interval can't be smaller than duration");
				} catch (e) {
					console.error("[siema props]:", e);
					return false
				}
				return true;
			}
		},
		indicator: [Boolean, String],
		control: [Boolean, String],
	},
	data() {
		return {
			siema: null,
			canPlay: true,
		}
	},
	computed: {
		length() {
			switch (typeof this.data) {
				case "number":
					return this.data;
				case "object":
					return this.data.length;
				default:
					return 0;
			}
		},
	},
	methods: {
		init() {
			if (this.length > 1) {
				const vm = this;
				this.indicatorsDOM = this.$refs["indicator"];
				this.siema = new Siema({
					...this.options,
					selector: this.$refs["siema"],
					onInit: function () {
						vm.handleAutoPlay();
						vm.changeIndicator(this.currentSlide);	// 更新 indicators
						vm.$emit("init", this.currentSlide);
					},
					onChange: function () {
						vm.canPlay = true;
						vm.handleAutoPlay();
						vm.changeIndicator(this.currentSlide);	// 更新 indicators
						vm.$emit("change", this.currentSlide);
					}
				});
			}
		},
		handleAutoPlay() {
			const { interval } = this.options;
			if (this.canPlay && interval && interval > 0) {
				this.timeout && clearTimeout(this.timeout);
				this.timeout = setTimeout(this.next, interval);
			}
		},
		changeIndicator(currentSlide) {
			if (this.indicatorsDOM) {
				this.indicatorsDOM.forEach((el, i) => {
					if (currentSlide == i) el.classList.add("active")
					else el.classList.remove("active")
				});
			}
		},
		goTo(index) {
			this.siema && this.siema.goTo(index);
		},
		prev(howManySlides = 1) {
			this.siema && this.siema.prev(howManySlides);
		},
		next(howManySlides = 1) {
			this.siema && this.siema.next(howManySlides);
		},
		destroy(restoreMarkup) {
			this.timeout && clearTimeout(this.timeout);
			this.siema && this.siema.destroy(restoreMarkup);
			this.siema = null;
		},
		pause() {
			this.timeout && clearTimeout(this.timeout);
			this.canPlay = false;
		},
		play() {
			this.canPlay = true;
			this.handleAutoPlay();
		}
	},
	mounted() {
		this.$nextTick(this.init);
	},
	beforeUpdate() {
		this.destroy(true);
	},
	updated() {
		this.init();
		console.log("[siema] updated");
	},
	beforeDestroy() {
		this.destroy();
	}
}
</script>

<style lang="scss" scoped>
.siema {
	--var-indicator-color: #fff;
	position: relative;
}
.arrow {
	position: relative;
	width: 40px; // use px when browser not support rem
	width: 2.5rem;
	border-radius: 100%;
	padding: 0;
	transition: 0.5s;
	border: none;
	background: #a1a1a1;
	cursor: pointer;
	outline: none;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;
	}

	&::after {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		border-style: solid;
		border-width: 2px 2px 0 0;
		border-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&.right {
		&::after {
			transform: translate(-75%, -50%) rotate(45deg);
		}
	}

	&.left {
		&::after {
			transform: translate(-25%, -50%) rotate(-135deg);
		}
	}

	@media (hover: hover) {
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}
.right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px; // use px when browser not support rem
	right: 0.625rem;
}
.left {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px; // use px when browser not support rem
	left: 0.625rem;
}
.indicators {
	padding-top: 16px; // use px when browser not support rem
	padding-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	&.inside {
		transform: translateX(-50%);
		position: absolute;
		bottom: 10px; // use px when browser not support rem
		bottom: 0.625rem;
		left: 50%;
	}

	i {
		width: 10px; // use px when browser not support rem
		height: 10px; // use px when browser not support rem
		width: 0.625rem;
		height: 0.625rem;
		cursor: pointer;
		transition: 0.3s;
		border-radius: 100%;
		background: transparent;
		border: 1px solid var(--var-indicator-color);
		&:not(:first-child) {
			margin-left: 10px; // use px when browser not support rem
			margin-left: 0.625rem;
		}

		&:hover,
		&.active {
			background: var(--var-indicator-color);
		}
	}
}
</style>