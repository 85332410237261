<template>
	<section id="resources">
		<TopBanner>
			<img src="../../assets/resources/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/resources/title.png" class="page-title" width="450">

		<!-- section1 -->
		<ContentBlock>
			<p>DFC 臺灣團隊從 2010 年創立以來，除了蒐集了許多孩子的 DFC 挑戰故事，也得到了許多教育工作者與家長的心得與回饋，更研發出了許多教案。</p>
			<p>瀏覽 DFC 臺灣經營的教學資源平台、YouTube 頻道、Medium 專欄，就可以取得我們精心為你準備的免費資源唷，邀請您瀏覽、取用，並且歡迎您以 Facebook 訊息或 e-mail 跟我們分享您的使用心得，我們將繼續優化這些資源，幫助更多對於 DFC 躍躍欲試的新手教育工作者與家長。</p>
			<br>
			<StickyMenu :data="list">
				<div slot-scope="{item, scroll}" class="rwd-m-3 rwd-s-4" style="padding:1em;">
					<a :href="`#${item.target.id}`" :class="{ active: item.active }" class="row" align-x="center" align-y="center" gutter="10" @click.prevent="scroll(item.target)">
						<img v-show="!item.active" :src="item.icon" style="height:2em;">
						<img v-show="item.active" :src="item.iconActive" style="height:2em;">
						<span>{{item.text}}</span>
					</a>
				</div>
			</StickyMenu>
		</ContentBlock>
		<template v-for="(item, i) in list">
			<ContentBlock :id="`${item.target.replace('#','')}`" :key="i">
				<ArticleBlock picture-position="right">
					<template slot="paragraph">
						<div class="col" gutter="20">
							<h2>{{item.text}}</h2>
							<p>{{item.p}}</p>
							<DfcButton class="rwd-s-8" theme="red" round arrow :href="item.link" target="_blank">{{item.linkText}}</DfcButton>
						</div>
					</template>
					<img slot="picture" :src="item.img">
				</ArticleBlock>
			</ContentBlock>
		</template>
	</section>
</template>

<script>
import StickyMenu from "../../components/StickyMenu.vue"
import ArticleBlock from "../../components/ArticleBlock.vue"
export default {
	components: {
		StickyMenu,
		ArticleBlock,
	},
	data() {
		return {
			list: [
				{
					target: "#section2",
					text: "書籍著作",
					p: "我們推出適合教師、家長、學生及關心教育議題之讀者的書籍：《報告！這裡沒有校長室！》、《創意行動之旅 — 給中學教師的設計思考教學手冊》、《設計思考：從教育開始的破框思維》 多本書籍，內容包含設計思考、DFC 學習法心態與四步驟、課程規劃、印度河濱學校教育與辦學理念、以及 DFC 挑戰故事案例與學習單，都可以在書中一探究竟！",
					link: "https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=8",
					linkText: "瞭解更多",
					img: require("../../assets/resources/img1.png"),
					icon: require("../../assets/resources/icon1.png"),
					iconActive: require("../../assets/resources/icon1-active.png"),
				},
				{
					target: "#section3",
					text: "教學資源平台",
					p: "官方教學資源匯整中心，提供輔助教師將 DFC 學習法融入課堂中之各式教學資源，可搭配不同科目、目標自由且彈性地運用，包括 DFC 挑戰引導手冊、DFC 學習法相關教學工具等等。至今已上架 11 份教學工具，內容皆包含「教師工具使用說明書」及「教學用簡報」；團隊持續延伸研發結合學科、更貼近教育現場的教學工具。歡迎您註冊成為平台會員，索取適合您的免費教學資源，讓它們成為執行 DFC 學習法課程的最佳助力！",
					link: "https://www.dfcchallenge.org",
					linkText: "註冊平台",
					img: require("../../assets/resources/img2.png"),
					icon: require("../../assets/resources/icon2.png"),
					iconActive: require("../../assets/resources/icon2-active.png"),
				},
				{
					target: "#section4",
					text: "DFC 教學社群",
					p: "DFC 教學社群目前有近 3,000 位使用者，為一穩定成⻑的臉書教學社群，以延續與操作 DFC 學習法之教師間的關係，建立線上及線下的連結。提供免費線上教學回饋與電話支持服務，傾聽教師的狀況，推薦合適的教學資源，協助教師順利執行 DFC 學習法課程或完成 DFC 挑戰。社群主題包含設計思考專欄分享、永續發展目標主題探討、最新研發的教學資源及課程設計技巧、及優先獲取第一手研習及活動資訊。",
					link: "https://www.facebook.com/groups/1114591105274908",
					linkText: "加入社群",
					img: require("../../assets/resources/img3.png"),
					icon: require("../../assets/resources/icon3.png"),
					iconActive: require("../../assets/resources/icon3-active.png"),
				},
			],
		}
	},
	mounted() {
		console.log(
			document.getElementById("article-block1").offsetTop,
			document.getElementById("article-block2").offsetTop,
			document.getElementById("article-block3").offsetTop,
		);
	}
}
</script>

<style lang="scss" scoped>
.content-block {
	&:not(:first-of-type) {
		padding-top: 0;
	}
	&:not(:last-of-type) {
		padding-bottom: 0;
	}
}
.article-block {
	h2 {
		display: flex;
		align-items: center;
		&::before {
			content: "";
			display: block;
			height: 1px;
			width: 1.5em;
			margin-right: 5px;
			background: #eb6165;
		}
	}
}
.sticky-menu {
	a {
		width: 100%;
		height: 100%;
		line-height: 1.5;
		color: black;
		border: 1px solid #dc3d3c;
		padding: 1em;
		transition: background 0.3s;
		border-radius: 6px;
		text-align: center;
		background: #fff;
		white-space: nowrap;

		@mixin active {
			background: $red;
			color: white;
		}
		&.active {
			@include active;
		}
		@media (hover: hover) {
			&:hover {
				@include active;
			}
		}

		@media screen and (max-width: 425px) {
			border-radius: 100px;
			padding: 0.5em 1em;

			img {
				margin: 0 1em;
				width: 2em;
			}
		}
	}
}
</style>