<template>
	<ContentBlock clip bg-color="white1">
		<h1 style="color:#122e26; text-align:center;">DFC 學習法</h1>
		<br />
		<p>
			起源於「設計思考」，是根據教育現場需求調整而成的設計思考流程，其核心為「DFC
			學習法之四步驟—感受、想像、實踐、分享」。由印度河濱學校校長 Kiran Bir
			Sethi 與設計公司 IDEO 現任執行長 Sandy Speicher
			等人，在觀察現場教師教學及孩子學習發展的需求後，經反覆測試和驗證後研發而成。
			<br />
			<br />融入探究式學習、跨領域整合、社會智能等概念的 DFC
			學習法，能激發孩子解決真實世界問題的動力，找到學習的意義和動機，並培養「人生需要的能力」，也就是「二心二力—同理心、創造力、行動力、自信心」。
		</p>
		<Siema
			:data="list"
			:options="{ loop: true, perPage: { 769: 4, 426: 2 } }"
			:control="$root.rwd > 1"
		>
			<template v-slot="{ slide }">
				<a :href="slide.documentURL" target="_blank">
					<div class="col" align-x="center" gutter="15" style="padding:1em;">
						<img :src="slide.img" style="width:10.5rem;" />
						<div
							class="col"
							gutter="10"
							style="padding:1em; border-radius:15px; text-align:center; width:100%;"
							:color="slide.color"
							:style="{ background: slide.bgColor }"
						>
							<b style="font-size:1.5em;">{{ slide.title }}</b>
							<p>{{ slide.subtitle }}</p>
							<hr style="height:1px; border:none;" :bg-color="slide.color" />
							<span style="min-height:3em;">{{ slide.p }}</span>
						</div>
					</div>
				</a>
			</template>
		</Siema>
	</ContentBlock>
</template>

<script>
import Siema from "../Siema.vue";
export default {
	components: {
		Siema,
	},
	data() {
		return {
			listSwitch: 0,
			list: [
				{
					img: require("../../assets/home/step1.png"),
					title: "感受",
					subtitle: "Feel",
					p: "發現身邊的問題",
					color: "red1",
					bgColor: "#f9ecec",
				},
				{
					img: require("../../assets/home/step2.png"),
					title: "想像",
					subtitle: "Imagine",
					p: "想像各種解決辦法及最佳情境",
					color: "yellow3",
					bgColor: "#fdf7ed",
				},
				{
					img: require("../../assets/home/step3.png"),
					title: "實踐",
					subtitle: "DO",
					p: "執行擬定的計畫",
					color: "green2",
					bgColor: "#f0f8ef",
				},
				{
					img: require("../../assets/home/step4.png"),
					title: "分享",
					subtitle: "Share",
					p: "擴大影響力",
					color: "blue1",
					bgColor: "#f0f9fc",
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.content-block {
	@media screen and (min-width: 768px) {
		$max: 300px; // 背景圖片的 max-width
		$min: 150px; // 背景圖片的 min-width
		position: relative;
		background-image: url("../../assets/home/boy.png"),
			url("../../assets/home/girl.png");
		background-position: left 20%, right 80%;
		background-repeat: no-repeat;
		background-size: max(min(15%, #{$max}), #{$min});
	}
}

.switch-btn {
	background: transparent;
	color: #fff;

	&.active {
		background: #fff;
		color: #000;
	}
}
.siema {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	i {
		border-color: #fff !important;
		&.active {
			background: #fff !important;
		}
	}
}
</style>
