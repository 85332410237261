<template>
	<ContentBlock clip bg-color="red4">
		<h1 class="center" style="color: #fff">團隊夥伴</h1>
		<br />
		<el-card :body-style="{ paddingLeft: 0, paddingRight: 0 }">
			<RwdTable :list="list1">
				<template slot-scope="{ row }">
					<div
						class="col"
						align-x="center"
					>
						<img :src="row.img" alt="組織架構圖" />
					</div>
				</template>
			</RwdTable>
		</el-card>
		<!-- <br>
		<br>
		<el-card>
			<tabs :list="list2" active-color="green" />
		</el-card> -->
		<br />
		<br />
		<el-card :body-style="{ paddingLeft: 0, paddingRight: 0 }">
			<RwdTable :list="list3" color="red">
				<template slot-scope="{ row }">
					<div
						class="col rwd-s-6"
						align-x="left"
						gutter="5"
						style="font-size: 1.2em"
					>
						<small class="gray">{{ row.date }}</small>
						<p>{{ row.location }}</p>
					</div>
				</template>
			</RwdTable>
		</el-card>
		<br />
		<br />
		<el-card :body-style="{ paddingLeft: 0, paddingRight: 0 }">
			<header slot="header">理監事名單</header>
			<RwdTable :list="list4" color="red" :padding="[0, 0]">
				<template slot="head" slot-scope="{ title }">
					<div v-html="title" />
				</template>
				<template slot-scope="{ row }">
					<p style="width: 100%; margin: 0">{{ row }}</p>
				</template>
			</RwdTable>
		</el-card>
		<br />
		<br />
		<div class="center">
			<DfcButton
				class="rwd-m-4 rwd-s-6"
				theme="yellow"
				round
				arrow
				to="/join-us"
				>加入我們</DfcButton
			>
		</div>
	</ContentBlock>
</template>

<script>
import Tabs from "../Tabs.vue";
import RwdTable from "./RwdTable.vue";
export default {
	components: {
		Tabs,
		RwdTable,
	},
	data() {
		return {
			// 全職夥伴/兼職夥伴
			// 排序:執行長>營運長>領導與營運部>教學研發與培力組>品牌影響力促進組>政府與法人關係促進組
			// 相同單位再依照姓氏筆畫排列
			list1: [
				"組織架構圖",
				[{ img: require("@/assets/about/組織架構圖.png") }],
			],
			list2: [
				{
					title: "總籌行政組",
					text: "負責組織內部營運之人事、財務、法務接洽、國際聯繫、協會行政及會務制度建立及維護；制定營運策略，有效統整歷史檔案及資訊以提升營運成效，並依據組織營運狀況將募得的資源分配至各組。",
				},
				{
					title: "課程服務組",
					text: "依據設計思考及 DFC 四步驟，研發並提供符合教育現場需求的教案、教材及工作坊內容，致力於透過線上、線下的交流活動，讓教育實踐者能夠透過團隊的服務得到在運用 DFC 之歷程中的支持與協助、共創資源。依照現行的教育趨勢與議題主辦不同主題之工作坊與共備課程，並同時於線上教學平台提供老師、家長們更即時的共享資源。",
				},
				{
					title: "公共事務組",
					text: "塑造動人的 DFC 品牌故事，以獲得大眾長期支持。\n制定募資策略、開發潛在合作單位及潛在支持者，維護公共關係、擬定組織對外宣傳活動、媒體與合作單位接洽、經營大眾溝通管道、推廣組織理念打造並維持組織對外品牌形象，以期擴散組織影響力。",
				},
				{
					title: "專案合作",
					text: "團隊以「打造以使用者為中心的教育組織」為目標，聘請相關專家協助組織內部夥伴增能，並依據組織的服務對象進行其使用經驗訪談、研究與分析，以期瞭解教育現場師生及協會捐款人需求，提出團隊發展策略的建議，針對組織的服務設計出更完善的服務流程。",
				},
			],
			list3: [
				"專業行政事務",
				[
					{
						date: "2022.01 起",
						location: "中友聯合會計師事務所",
					},
					{
						date: "2016.12 起",
						location: "法睿法律事務所",
					},
				],
			],
			list4: [
				"理事長",
				["黃兆緯｜團隊創始成員"],
				"理事<p style='font-size:12px;'>(依照姓氏筆畫排列)</p>",
				[
					"上官良治｜團隊創始成員",
					"王俊斌｜國立臺北教育大學 課程與教學傳播科技研究所 教授兼所長",
					"林頎富｜法博思品牌管理與策略設計顧問公司 品牌策略顧問",
					"許又仁｜元沛農坊（沃畝股份有限公司）創辦人",
					"許韶芬｜表演藝術工作者",
					"陳宥妤｜彰化縣南郭國小教師",
					"鄭以萱｜國立暨南國際大學 國際文教與比較教育系 教授",
					"羅淑華｜桃園市教育局 商借教師候用校長",
				],
				"監事<p style='font-size:12px;'>(依照姓氏筆畫排列)</p>",
				[
					"王俊凱｜財團法人見性社會福利基金會 執行長",
					"黃國珍｜品學堂文化股份有限公司",
				],
				"顧問<p style='font-size:12px;'>(依照姓氏筆畫排列)</p>",
				[
					"陳文剛｜大予創意設計股份有限公司（AJA） 使用經驗總監",
					"陳秀涵｜聚吉良策有限公司 創辦人",
					"蔡明哲｜悠識數位顧問有限公司 首席體驗架構師",
				],
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.el-card {
	padding: 10px;
	header {
		font-size: 1.5em;
		text-align: center;
	}
	small.gray {
		color: #868686;
	}
	pre {
		margin: 0;
	}
}
.dfc-button {
	margin: 1em;
}
.team-image {
	width: 122px;
	height: 122px;
	object-fit: cover;
	object-position: top;
}
</style>
