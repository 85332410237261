<template>
	<section>
		<tag />
		<br>
		<br>
		<category />
	</section>
</template>

<script>
import Category from "../../components/story/Category.vue";
import Tag from "../../components/story/Tag.vue";
export default {
	components: {
		Category,
		Tag,
	},
}
</script>