<template>
	<upload :data="fileList" :media-folder="`news/${id}`" accept="image/*" list-type="picture" :limit="1" @remove="handleChange" @complete="handleChange">
		<p slot="tip" style="color:pink;">1.只能上傳圖片檔
			<br>2. 至多一張
			<br>3. 小於５MB
			<br>4. 建議尺寸為 10:7（e.g. 300 x 210）
		</p>
	</upload>
</template>

<script>
import Upload from "../Upload.vue";
export default {
	components: {
		Upload
	},
	props: ["data", "id"],
	model: {
		prop: "data",
		event: "change"
	},
	computed: {
		fileList() {
			return this.data || new Array
		}
	},
	methods: {
		handleChange(fileList) {
			this.$emit("change", fileList);
		}
	}
}
</script>