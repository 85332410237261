<template>
	<nav class="sticky-menu2" :class="{fixed}">
		<!-- fixed 上方的 menu -->
		<div class="sticky row" :style="{top}">
			<template v-for="(item, i) in list">
				<button :key="i" class="anchor center" :class="{active: item.active}" @click.prevent="handleClick(i)">{{item.text}}</button>
			</template>
		</div>
		<!-- 留在原本位置的 menu -->
		<div class="static row-grid" align-x="center">
			<template v-for="(item, i) in list">
				<slot :item="item" :index="i" :callback="() => handleClick(i)">{{item.text}}</slot>
			</template>
		</div>
	</nav>
</template>

<script>
import stickyMixin from "../mixin/sticky-menu.vue";
export default {
	mixins: [stickyMixin],
	model: {
		prop: "data",
		event: "change"
	},
	computed: {
		list: {
			get() {
				return this.data;
			},
			set(val) {
				this.$emit("change", val);
			}
		}
	},
	methods: {
		handleClick(i) {
			this.list = this.list.map((item, j) => ({
				...item,
				active: i == j
			}))
		}
	}
}
</script>

<style lang="scss" scoped>
.collapse-button {
	background: $red;
	color: #fff;
	padding: 0.5em;

	.el-icon-caret-right {
		transition: 0.5s;
		&.visible {
			transform: rotate(90deg);
		}
	}
}
button {
	margin: 0;
	cursor: pointer;
	background: transparent;
	outline: none;
}
</style>