<template>
	<ContentBlock clip style="background:#50c5a4;">
		<h1 class="center">歷屆回饋</h1>
		<br>
		<br>
		<template v-if="$root.rwd < 2">
			<template v-for="(item, i) in list">
				<Card :data="item" :key="i" />
			</template>
		</template>
		<Siema ref="siema" v-else :data="list" :options="{ loop: true  }" control>
			<template v-slot="{slide}">
				<Card :data="slide" />
			</template>
			<button slot="prev" class="arrow left" @click="prev()" />
			<button slot="next" class="arrow right" @click="next()" />
		</Siema>
		<br>
		<div class="center">
			<DfcButton class="rwd-m-4 rwd-s-6" theme="darkblue" round arrow to="/teacher-list">認識更多種子教師</DfcButton>
		</div>
	</ContentBlock>
</template>

<script>
import Card from "./Card.vue"
import Siema from "../Siema.vue";
export default {
	components: {
		Card,
		Siema,
	},
	data() {
		return {
			list: [{
				img: require("../../assets/dfc-teacher/teacher1.png"),
				description: "「相較於三年前的培訓，DFC種子教師認證計畫更深入談到以人為本的課程理念，並​透過各種方法落實於策略的發想上。」",
				who: "第一屆種子教師 桃園市文化國小 羅淑華主任",
			}, {
				img: require("../../assets/dfc-teacher/teacher2.png"),
				description: "「『共創』、『共備』對參與者的意義在於合作學習，透過溝通、彼此合作，更容易達到目標。」",
				who: "第一屆種子教師 桃園市中埔國小 吳雅蕙老師",
			},],
		}
	},
	methods: {
		prev() {
			this.$refs["siema"].prev();
		},
		next() {
			this.$refs["siema"].next();
		},
	},
}
</script>

<style lang="scss" scoped>
.content-block {
	color: #1d1e1f;
}
.siema {
	--var-indicator-color: black;
	.right,
	.left {
		top: 10% !important;
	}
}
</style>