<template>
	<div class="count-up row-grid" align-y="center" align-x="center" gutter="5">
		<p class="center rwd-6 rwd-m-12 data-title">{{what}}</p>
		<div class="row rwd-6 rwd-m-12" align-y="baseline" align-x="center" gutter="10">
			<p ref="big-text" class="big-text">0</p>
			<p>{{unit}}</p>
		</div>
	</div>
</template>

<script>
import { CountUp } from "countup.js";
export default {
	props: ["num", "unit", "what"],
	data() {
		return {
			counted: false,
		}
	},
	methods: {
		triggerCountUp() {
			if (this.$refs["big-text"]) {
				const countup = new CountUp(this.$refs["big-text"], this.num);
				if (!countup.error) {
					countup.start();
				} else {
					console.error(countup.error);
				}
				this.counted = true;
			}
		}
	},
	mounted() {
		const { innerHeight } = window;
		window.addEventListener("scroll", e => {
			if (this.$el.getBoundingClientRect().top <= innerHeight * 3 / 4 && this.counted == false) {
				this.triggerCountUp();
			}
		});
	}
}
</script>

<style lang="scss" scoped>
.count-up {
	padding: 1em;
	white-space: nowrap;
}
p {
	margin: 0;
	font-weight: 500;
}
.big-text {
	font-size: 2.5em;
	line-height: 1;
}
.data-title{
	margin-bottom: 0;
	@media screen and (min-width: 768px) {
		margin-bottom: 15px !important;
	}
}
</style>