<template>
	<table>
		<template v-for="(row, i) in chunkedList">
			<tr :key="i">
				<template v-for="(col, j) in row">
					<th v-if="isString(col)" :key="j" style="width: 180px;">
						<div class="col">
							<slot name="head" :title="col">
								<p v-html="col"></p>
							</slot>
							<div :class="color" />
						</div>
					</th>
					<td v-else class="cell" :key="j">
						<div class="row-grid" :gutter-x="padding[0]" :gutter-y="padding[1]">
							<template v-for="item in col">
								<slot :row="item" />
							</template>
						</div>
					</td>
				</template>
			</tr>
		</template>
	</table>
</template>

<script>
export default {
	props: {
		list: Array,
		color: {
			type: String,
			default: "green"
		},
		padding: {
			type: Array,
			default: () => [20, 40],
			validator: val => val.length == 2
				&& val.every(item => typeof item == "number")
		},
	},
	computed: {
		chunkedList() {
			const arr = Array.from(this.list);
			const chunk_size_list = [2, 2, 1];
			const chunk_size = chunk_size_list[this.$root.rwd - 1];
			return arr.chunk(chunk_size);
		}
	},
	methods: {
		isString(val) {
			return typeof val == "string";
		}
	},
}
</script>

<style lang="scss" scoped>
table {
	border-collapse: separate;
	border-spacing: 1em 3em;
	margin: -3em 0;
	width: 100%;

	th {
		white-space: nowrap;
		font-size: 1.375em;
		vertical-align: top;
		text-align: left;
		.green,
		.red {
			width: 2em;
			height: 3px;
		}
		.green {
			background: $green;
		}
		.red {
			background: $red;
		}
		@media screen and (max-width: 425px) {
			.col {
				align-items: center;
				text-align: center;
			}
		}
	}

	td:not(:first-child) {
		border-left: solid 1px #d0d0d0;
		padding-left: 3%;
	}
}
</style>