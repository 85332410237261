import Joi from "joi";
import { cloneDeep } from "lodash";

const schema = Joi.object({
	people: Joi.array().items(Joi.string()).error(new Error("社會進步")),
	prosperity: Joi.array().items(Joi.string()).error(new Error("地方共融")),
	planet: Joi.array().items(Joi.string()).error(new Error("環境保衛")),
});

class Categories {
	constructor(initData = new Object) {
		try {
			const { value, error, warning } = schema.validate(initData, { stripUnknown: true });
			if (error) throw error;
			if (warning) console.warn(warning);
			this.people = value.people || new Array;
			this.prosperity = value.prosperity || new Array;
			this.planet = value.planet || new Array;
		} catch (error) {
			console.log({ error });
			throw error;
		}
	}

	toFirestore() {
		const { value, error, warning } = schema.validate(this, { stripUnknown: true });
		if (error) throw error;
		if (warning) console.warn(warning);
		return Object.keys(value)
			.reduce((obj, k) => {
				obj[k] = value[k];
				return obj;
			}, new Object);
	}

	clone() {
		return cloneDeep(this);
	}

	addCategory(key, label = "") {
		if (this[key]) {
			if (this[key].includes(label)) throw new Error(label + "已存在");
			else this[key].push(label);
		}
		else throw new Error(key + "不存在");
	}

	removeCategory(key, label = "") {
		if (this[key]) {
			this[key].splice(this[key].findIndex(val => val == label), 1);
		} else throw new Error(key + "不存在");
	}

	updateCategory(key, oldVal, newVal) {
		if (this[key]) {
			this[key][this[key].findIndex(val => val == oldVal)] = newVal;
		} else throw new Error(key + "不存在");
	}
}

export default Categories