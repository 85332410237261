<template>
	<ContentBlock clip>
		<h1 class="center">成為全職或實習生夥伴</h1>
		<div id="pad">
			<h3 style="line-height:2;">一起成為「擾動教育現場的一股力量」，培養打破框架的人才</h3>
			<p style="line-height:2;">我們將不定會釋出招募資訊，尋找充滿熱情和同理心、願意溝通及傾聽，擁有不斷嘗試自信心的實踐者，如果你也想要成為 DFC 臺灣團隊的全職夥伴或實習生，我們也希望能夠跟你一起為臺灣的教育共同努力！</p>
			<h2 class="center">業務說明</h2>
			<el-card>
				<tabs :list="list" active-color="green" />
			</el-card>
		</div>
		<br>
		<div class="center">
			<DfcButton class="rwd-s-6 rwd-m-4" theme="darkblue" round arrow href="https://form.jotform.me/90231162925451" target="_blank">成為全職夥伴</DfcButton>
		</div>
	</ContentBlock>
</template>

<script>
import Tabs from "../Tabs.vue";
export default {
	components: {
		Tabs,
	},
	data() {
		return {
			list: [
				{
					title: "總籌行政組",
					text: "負責組織內部營運之人事、財務、法務接洽、國際聯繫、協會行政及會務制度建立及維護，有效管理帳務與符合公益責信；制定人力發展策略與計畫，包含：培訓、職涯發展、績效管理，以提升營運成效，並穩建組織發展。",
				},
				{
					title: "課程服務組",
					text: "依據 DFC 學習法之四步驟、設計思考脈絡 ，提供全面性的教師支持和培訓服務；積極研發各式工作坊、多項 DFC 學習法教材，如結合學科、結合永續發展目標的教材，並提供老師線上和線下的課程服務教學資源，輔助其將融入至教學之中，作為教學鷹架，以協助教師帶領孩子達到教學目標。",
				},
				{
					title: "公共事務組",
					text: "塑造動人的品牌故事，積極推廣組織理念，以獲得各界長期的支持。制定募資策略、開發潛在合作單位及捐款者，維護公共關係並媒合資源。透過舉辦品牌活動以及經營大眾溝通管道，建立與維持品牌形象，擴散影響力。",
				},
			],
		}
	}
}
</script>

<style lang="scss" scoped>
.content-block {
	@media screen and (min-width: 769px) {
		background-image: url("../../assets/join-us/role3.png"),
			url("../../assets/join-us/role4.png");
		background-position: left 40%, right 90%;
		background-repeat: no-repeat;
		background-size: 17%, 15%;
	}
	background: #50c5a4;
}
#pad {
	@media screen and (min-width: 769px) {
		padding: 0 10% 5%;
	}
}
.el-button {
	background: $darkblue;
}
</style>