import emailjs from "emailjs-com";

const USER_ID = process.env.VUE_APP_EMAIL_USER_ID;
const SERVICE_ID = process.env.VUE_APP_EMAIL_SERVICE_ID;

export default function (TEMPLATE_ID, TEMPLATE_OPTIONS) {
	return new Promise((resolve, reject) => {
		emailjs.send(SERVICE_ID, TEMPLATE_ID, TEMPLATE_OPTIONS, USER_ID)
			.then(res => resolve(res))
			.catch(err => {
				console.log({ err });
				reject(err);
			});
	});
}