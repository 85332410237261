<template>
	<section id="speech-workshop">
		<h1 style="margin:1em; text-align:center;">演講/工作坊邀約表單（可選日期）</h1>
		<div class="row-grid" gutter-x="20" gutter-y="30">
			<div class="rwd-m-6 col">
				<h2>演講</h2>
				<el-card>
					<el-form ref="speech" :model="invitationFormOptions">
						<template v-for="(item, i) in invitationFormOptions.speech">
							<el-form-item :key="i" :prop="`speech[${i}]`" :rules="{required:true, message:'請選時段'}">
								<div class="row" align-y="center" gutter="10">
									<el-date-picker v-model="invitationFormOptions.speech[i]" type="daterange" range-separator="至" start-placeholder="開始" end-placeholder="結束" value-format="yyyy-MM-dd" />
									<el-button type="danger" plain icon="el-icon-minus" circle size="mini" @click="handleRemove('speech', i)" />
								</div>
							</el-form-item>
						</template>
						<div class="row" align-x="between" align-y="top">
							<el-button type="success" icon="el-icon-plus" circle size="mini" @click="handleAdd('speech')" />
							<el-button type="primary" size="small" @click="handleSubmit('speech')" :loading="loading">儲存</el-button>
						</div>
					</el-form>
				</el-card>
			</div>
			<div class="rwd-m-6" />
			<div class="rwd-m-6 col">
				<h2>心態工作坊</h2>
				<el-card>
					<el-form ref="mindset_workshop" :model="invitationFormOptions">
						<template v-for="(item, i) in invitationFormOptions.mindset_workshop">
							<el-form-item :key="i" :prop="`mindset_workshop[${i}]`" :rules="{required:true, message:'請選時段'}">
								<div class="row" align-y="center" gutter="10">
									<el-date-picker v-model="invitationFormOptions.mindset_workshop[i]" type="daterange" range-separator="至" start-placeholder="開始" end-placeholder="結束" value-format="yyyy-MM-dd" />
									<el-button type="danger" plain icon="el-icon-minus" circle size="mini" @click="handleRemove('mindset_workshop', i)" />
								</div>
							</el-form-item>
						</template>
						<div class="row" align-x="between" align-y="top">
							<el-button type="success" icon="el-icon-plus" circle size="mini" @click="handleAdd('mindset_workshop')" />
							<el-button type="primary" size="small" @click="handleSubmit('mindset_workshop')" :loading="loading">儲存</el-button>
						</div>
					</el-form>
				</el-card>
			</div>
			<div class="rwd-m-6 col">
				<h2>感受工作坊</h2>
				<el-card>
					<el-form ref="feeling_workshop" :model="invitationFormOptions">
						<template v-for="(item, i) in invitationFormOptions.feeling_workshop">
							<el-form-item :key="i" :prop="`feeling_workshop[${i}]`" :rules="{required:true, message:'請選時段'}">
								<div class="row" align-y="center" gutter="10">
									<el-date-picker v-model="invitationFormOptions.feeling_workshop[i]" type="daterange" range-separator="至" start-placeholder="開始" end-placeholder="結束" value-format="yyyy-MM-dd" />
									<el-button type="danger" plain icon="el-icon-minus" circle size="mini" @click="handleRemove('feeling_workshop', i)" />
								</div>
							</el-form-item>
						</template>
						<div class="row" align-x="between" align-y="top">
							<el-button type="success" icon="el-icon-plus" circle size="mini" @click="handleAdd('feeling_workshop')" />
							<el-button type="primary" size="small" @click="handleSubmit('feeling_workshop')" :loading="loading">儲存</el-button>
						</div>
					</el-form>
				</el-card>
			</div>
			<div class="rwd-m-6 col">
				<h2>想像工作坊</h2>
				<el-card>
					<el-form ref="imagination_workshop" :model="invitationFormOptions">
						<template v-for="(item, i) in invitationFormOptions.imagination_workshop">
							<el-form-item :key="i" :prop="`imagination_workshop[${i}]`" :rules="{required:true, message:'請選時段'}">
								<div class="row" align-y="center" gutter="10">
									<el-date-picker v-model="invitationFormOptions.imagination_workshop[i]" type="daterange" range-separator="至" start-placeholder="開始" end-placeholder="結束" value-format="yyyy-MM-dd" />
									<el-button type="danger" plain icon="el-icon-minus" circle size="mini" @click="handleRemove('imagination_workshop', i)" />
								</div>
							</el-form-item>
						</template>
						<div class="row" align-x="between" align-y="top">
							<el-button type="success" icon="el-icon-plus" circle size="mini" @click="handleAdd('imagination_workshop')" />
							<el-button type="primary" size="small" @click="handleSubmit('imagination_workshop')" :loading="loading">儲存</el-button>
						</div>
					</el-form>
				</el-card>
			</div>
			<div class="rwd-m-6 col">
				<h2>客製化工作坊</h2>
				<el-card>
					<el-form ref="customized_workshop" :model="invitationFormOptions">
						<template v-for="(item, i) in invitationFormOptions.customized_workshop">
							<el-form-item :key="i" :prop="`customized_workshop[${i}]`" :rules="{required:true, message:'請選時段'}">
								<div class="row" align-y="center" gutter="10">
									<el-date-picker v-model="invitationFormOptions.customized_workshop[i]" type="daterange" range-separator="至" start-placeholder="開始" end-placeholder="結束" value-format="yyyy-MM-dd" />
									<el-button type="danger" plain icon="el-icon-minus" circle size="mini" @click="handleRemove('customized_workshop', i)" />
								</div>
							</el-form-item>
						</template>
						<div class="row" align-x="between" align-y="top">
							<el-button type="success" icon="el-icon-plus" circle size="mini" @click="handleAdd('customized_workshop')" />
							<el-button type="primary" size="small" @click="handleSubmit('customized_workshop')" :loading="loading">儲存</el-button>
						</div>
					</el-form>
				</el-card>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
	},
	data() {
		return {
			loading: true,
			invitationFormOptions: {
				speech: new Array,
				mindset_workshop: new Array,
				feeling_workshop: new Array,
				imagination_workshop: new Array,
				customized_workshop: new Array,
			},
			dialogVisible: true,
		}
	},
	computed: {
		...mapState("speech-workshop", [
			"_speechWorkshop",
		]),
	},
	methods: {
		...mapActions("speech-workshop", [
			"_onDataChange",
			"_updateData",
		]),
		handleRemove(key, i) {
			this.invitationFormOptions[key].splice(i, 1);
			this.$refs[key].validate();
		},
		handleAdd(key) {
			if (this.invitationFormOptions[key]) {
				this.invitationFormOptions[key].push(new Array);
			} else {
				this.$set(this.invitationFormOptions, key, [new Array]);
			}
			this.$refs[key].validate();
		},
		handleSubmit(key) {
			this.$refs[key].validate(async valid => {
				if (valid) {
					this.loading = true;
					try {
						await this._updateData({
							doc: "invitation-form-options",
							data: {
								[key]: this.invitationFormOptions[key]
							}
						});
						this.statusChanged = false;
					} catch (error) {
						console.log({ error });
						this.$message.error(error.message);
					}
					this.loading = false;
				}
			})
		}
	},
	created() {
		this._onDataChange("invitation-form-options")
			.then(val => {
				if (val) Object.assign(this.invitationFormOptions, val);
				if (this.loading) this.loading = false;
			});
	}
}
</script>

<style lang="scss" scoped>
h2 {
	color: gray;
	font-weight: lighter;
}
</style>