<template>
	<upload v-model="fileList" :media-folder="mediaFolder" accept="image/*" list-type="picture" drag multiple @complete="handleComplete">
		<template slot="trigger">
			<i class="el-icon-upload" />
			<div class="el-upload__text">
				<small>將圖片拖到此處，或<em>點擊上傳</em>
					<br>只能上傳圖片檔，並且小於５MB</small>
			</div>
		</template>
		<template slot="upload-trigger" slot-scope="{uploader}">
			<el-button size="small" type="info" @click="handleCancel" style="margin-left:10px;">取消</el-button>
			<el-button size="small" type="primary" @click="uploader">上傳至雲端</el-button>
		</template>
	</upload>
</template>

<script>
import Upload from "./Upload.vue";
export default {
	props: ["media-folder"],
	components: {
		Upload
	},
	data() {
		return {
			statusChanged: false,
			fileList: new Array,
		}
	},
	methods: {
		handleCancel() {
			this.fileList = new Array;
			this.$emit("close");
		},
		handleComplete(fileList) {
			setTimeout(() => {
				this.fileList = new Array;
			}, 100);
			this.$emit("change", fileList);
		},
	},
}
</script>