<template>
	<ContentBlock clip style="background: #fff">
		<h1 class="center">挑戰故事擴大影響力歷程</h1>
		<Timeline
				:data="list"
				theme="#738bff"
				text-color="rgb(255,255,255)"
			>
		</Timeline>
	</ContentBlock>
</template>
<script>
import Timeline from "./Timeline.vue";
export default {
	components: {
		Timeline,
	},
	data() {
		return {
			list: [
				{
					id:'01',
					title:'報名資料審查',
					date:'挑戰收件截止時間：2025/03/14（五）15:00',
					content:'<div style="display:flex;"><div style="color:#F46852;margin-right:5px">❯</div><p style="margin-bottom:0;">審查內容為挑戰平台所需資料，包含挑戰學習單、四步驟照片、師生姓名之羅馬拼音。</p></div><div style="display:flex;"><div style="color:#F46852;margin-right:5px">❯</div><p style="margin-bottom:0;">影片為非必要繳交，但影片能輔助評審及大眾更了解挑戰故事喔！</p></div>'
				},
				{
					id:'02',
					title:'第一階段評審',
					date:'第一階段評審結果公告：2025 / 04 / 23（三）',
					content:'<p style="margin-bottom:8px;text-align:start">第一階段評審著重以下部分：</p><div style="display:flex;"><div style="color:#F46852;margin-right:5px">❯</div><p style="margin-bottom:8px;">一、DFC 學習法四步驟完整性</p></div><img style="margin-bottom:15px" src="/img/challenge-story/img1.png" /><div style="display:flex;"><div style="color:#F46852;margin-right:5px">❯</div><p style="margin-bottom:8px;">二、挑戰故事對應 DFC 臺灣四大核心價值</p></div><img style="margin-bottom:15px" src="/img/challenge-story/img2.png" /><div style="display:flex;"><div style="color:#F46852;margin-right:5px">❯</div><p style="margin-bottom:8px;">三、挑戰故事回應 SDGs 相關議題</p></div>'
				},
				{
					id:'03',
					title:'第二階段評審',
					date:'第二階段評審結果公告：2025 /05 / 07（三）',
					content:'<p><span style="color:#F46852">❯ </span>邀請 5 位符合 DFC 臺灣四大核心價值精神代表人物作為當屆評審。藉由不同領域的多元視角，看見與發覺挑戰故事之亮點，回應四大核心價值。</p><p><span style="color:#F46852">❯ </span>四大核心價值代表故事將優先受邀參與挑戰分享季，以及媒合相關活動。更有機會代表臺灣參與 DFC 全球年會！</p>'
				},
				{
					id:'04',
					title:'挑戰故事擴大影響力',
					date: null,
					content:'<p style="text-align:start;">參與當屆挑戰的挑戰故事將全數上傳至<a class="underline" style="color:#f46852;" href="https://tycaa.dfctaiwan.org/story/2024" target="_blank">官網</a>，若有繳交影片則會上傳至<a class="underline" style="color:#f46852;" href="https://www.youtube.com/@dfctaiwan" target="_blank">官方 YouTube 頻道</a>，讓更多人看見您們的創意行動，擴大影響力！</p>'
				},
			],
		};
	},
};
</script>