<template>
	<ContentBlock clip bg-color="white1">
		<h1 style="color: #122e26; text-align: center; margin-bottom: 60px">
			我們想要與你一起改善這樣的教育現況......
		</h1>
		<div
			class="lg"
			gutter="10"
			style="padding: 15px 0px"
			v-for="slide in list"
		>
			<Ratio
				ratio="square"
				style="max-width: 120px; width: 100%; margin: 0 50px"
			>
				<div class="center" style="width: 100%; height: 100%">
					<img :src="slide.img" width="150" />
				</div>
			</Ratio>
			<div>
				<span
					:style="{
						color: slide.color,
						borderBottom: `2px solid ${slide.color}`,
					}"
					style="padding: 10px 0; font-size: 18px"
					>{{ slide.title }}</span
				>
				<p style="margin-top: 30px; font-size: 15px">
					{{ slide.content }}
				</p>
			</div>
		</div>
		<div class="sm">
			<Siema
				:data="list"
				:options="{ loop: true, perPage }"
				:control="$root.rwd > 1"
			>
				<template v-slot="{ slide }">
					<div class="col" gutter="10" style="padding: 0 15px">
						<Ratio
							ratio="square"
							style="max-width: 200px; width: 100%; margin: auto"
						>
							<div
								class="center"
								style="width: 100%; height: 100%"
							>
								<img :src="slide.img" width="150" />
							</div>
						</Ratio>
						<p
							:style="{
								color: slide.color,
								borderTop: `2px solid ${slide.color}`,
								borderBottom: `2px solid ${slide.color}`,
							}"
							style="padding: 10px; text-align: center"
						>
							{{ slide.title }}
						</p>
						<p>{{ slide.content }}</p>
					</div>
				</template>
			</Siema>
		</div>
	</ContentBlock>
</template>

<script>
import Siema from "../Siema.vue";
export default {
	components: {
		Siema,
	},
	data() {
		return {
			perPage: {
				769: 3,
			},
			list: [
				{
					img: require("../../assets/home/question1.png"),
					width: 250,
					color: "#33a283",
					title: "讓現行偏重學業成就的單一評量方式邁向多元",
					content:
						"臺灣整體社會氛圍仍深深被學科測驗成績與升學主義影響，因此慣性以學業成績作為評斷學生學習成果的唯一參考依據，不但忽略了孩童多元潛力，也讓大多數孩童陷入學科框架與名次的迷思，降低學習的自信與動力。",
				},
				{
					img: require("../../assets/home/question2.png"),
					width: 220,
					color: "#f46852",
					title: "改善教學現場的無動力現象",
					content:
						"在傳統升學壓力之下，面對大量的紙筆練習與考試，教師觀察到多數孩童學習動機變低。此外孩子若無法理解生活與學習的連結，將使得課堂習得的知識成為應付考試的工具、造就教學現場的無動力現象。",
				},
				{
					img: require("../../assets/home/question3.png"),
					width: 200,
					color: "#33a283",
					title: "打破害怕失敗的教育氛圍",
					content:
						"2018 年 PISA 國際學生能力評量報告指出，臺灣學生是全世界最害怕失敗的一群。想要確保成功的心理，造成學生很大的壓力，擔心失敗後其他人怎麼看自己、擔心自己其實沒天分，反映了自信心的不足，也侷限了挑戰未知的可能性。",
				},
				{
					img: require("../../assets/home/question4.png"),
					width: 200,
					color: "#f46852",
					title: "因應教育趨勢改變，降低教師與家長焦慮感",
					content:
						"隨著時代變化的教育趨勢，教師面對來自多方的期待，需不斷調整心態與教學方式。但教師工作時間已被各式行政工作、班級經營嚴重擠壓，難以內化、遷移新的教學模式。面對課綱的轉換，家長也無所適從，不清楚孩童學習方向與未來發展。",
				},
			],
		};
	},
};
</script>
<style lang="scss" scoped>
.lg {
	display: none;
	align-items: center;
	@media screen and (min-width: 768px) {
		display: flex;
	}
}
.sm {
	display: block;
	@media screen and (min-width: 768px) {
		display: none;
	}
}
</style>
