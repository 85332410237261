<template>
	<section>
		<a
			href="https://sites.google.com/view/dfc-taiwan/%E9%A6%96%E9%A0%81"
			target="_blank"
			title="2022 第三屆 DFC 種子教師-校園大使計畫學習歷程成果展"
		>
			<img src="../../assets/teacher-list/banner9.png" width="100%" />
		</a>
		<br>
		<br>
		<div class="center">
			<DfcButton
				theme="yellow"
				round
				arrow
				href="https://sites.google.com/view/dfc-taiwan/%E9%A6%96%E9%A0%81"
				target="_blank"
			>
				2022 第三屆 DFC 種子教師-校園大使計畫學習歷程成果展
			</DfcButton>
		</div>
	</section>
	<!-- <section style="line-height:2;">
		<h2>校園大使計畫簡介</h2>
		<p>為期三年，自108年11月1日起至111年10月31日的產學合作案，合作對象為國立臺北教育大學、國立臺北教育大學教育系及其師培學生。DFC 臺灣提供之服務如下：針對國北教大大三以上師培學生，提供 DFC 四步驟課程設計及引導學兩階段培訓，培養師培生因應108課綱素養導向教學之心態與能力；同時 DFC 臺灣亦自辦一場兒童實驗營隊，作為師培生實踐培訓所學之場域。完成二階培訓之師培生可成為 DFC 臺灣校園推廣大使，分享 DFC 實驗營隊經驗並協助更多師培生發展 DFC 四步驟融入營隊之課程設計。</p>
		<br>
		<h2>校園大使課程介紹（以第一屆為例）</h2>
		<h3>．課程規劃</h3>
		<p>為期三年，自108年11月1日起至111年10月31日的產學合作案，合作對象為國立臺北教育大學、國立臺北教育大學教育系及其師培學生。DFC 臺灣提供之服務如下：針對國北教大大三以上師培學生，提供 DFC 四步驟課程設計及引導學兩階段培訓，培養師培生因應108課綱素養導向教學之心態與能力；同時 DFC 臺灣亦自辦一場兒童實驗營隊，作為師培生實踐培訓所學之場域。完成二階培訓之師培生可成為 DFC 臺灣校園推廣大使，分享 DFC 實驗營隊經驗並協助更多師培生發展 DFC 四步驟融入營隊之課程設計。</p>
		<br>
		<table v-if="$root.rwd < 3">
			<tr>
				<th style="border-top-left-radius:6px;">階段</th>
				<th>培訓主題</th>
				<th>培訓內容</th>
				<th style="border-top-right-radius:6px;">講師</th>
			</tr>
			<tr>
				<td rowspan="5">第一階段</td>
				<td rowspan="3">設計思考教學增能</td>
				<td>設計思考基礎概念*6</td>
				<td rowspan="3">DFC 講師</td>
			</tr>
			<tr>
				<td>DFC 四步驟教學心態與技巧*6</td>
			</tr>
			<tr>
				<td>一日 DFC 四步驟心態教案設計*6</td>
			</tr>
			<tr>
				<td>實作</td>
				<td>營隊教案驗收</td>
				<td>-</td>
			</tr>
			<tr>
				<td>實作</td>
				<td>延伸實作坊 - DFC 兒童實驗營隊*6</td>
				<td>-</td>
			</tr>
			<tr>
				<td rowspan="5" style="border-bottom-left-radius:6px;">第二階段</td>
				<td rowspan="2">引導技巧</td>
				<td>1. 認識課綱與素養*2hr</td>
				<td>范信賢老師</td>
			</tr>
			<tr>
				<td>2. 善用文本的教學力*2hr</td>
				<td>黃國珍老師</td>
			</tr>
			<tr>
				<td rowspan="2">組織帶領＆說故事技巧</td>
				<td>1. 打造一個共同學習的社群*2hr</td>
				<td>陳思玎校長</td>
			</tr>
			<tr>
				<td>2. 說好一個故事*3hr</td>
				<td style="border-bottom-right-radius:6px;">葉丙成老師</td>
			</tr>
		</table>
		<template v-else>
			<table>
				<tr>
					<td style="border-top-left-radius:6px; border-top-right-radius:6px;">
						<b>階段</b>
						<p>第一階段</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>培訓主題</b>
						<p>1. 設計思考教學增能
							<br>2. 實作
							<br>3. 實作
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>培訓內容</b>
						<p>1-1. 設計思考基礎概念*6
							<br>1-2. DFC 四步驟教學心態與技巧*6
							<br>1-3. 一日 DFC 四步驟心態教案設計*6
							<br>2. 營隊教案驗收
							<br>3. 延伸實作坊 - DFC 兒童實驗營隊*6
						</p>
					</td>
				</tr>
				<tr>
					<td style="border-bottom-left-radius:6px; border-bottom-right-radius:6px;">
						<b>講師</b>
						<p>1. DFC 講師</p>
					</td>
				</tr>
			</table>
			<br>
			<table>
				<tr>
					<td style="border-top-left-radius:6px; border-top-right-radius:6px;">
						<b>階段</b>
						<p>第二階段</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>培訓主題</b>
						<p>1. 引導技巧
							<br>2. 組織帶領＆說故事技巧
						</p>
					</td>
				</tr>
				<tr>
					<td>
						<b>培訓內容</b>
						<p>1-1. 認識課綱與素養*2hr
							<br>1-2. 善用文本的教學力*2hr
							<br>2-1. 打造一個共同學習的社群*2hr
							<br>2-2. 說好一個故事*3hr
						</p>
					</td>
				</tr>
				<tr>
					<td style="border-bottom-left-radius:6px; border-bottom-right-radius:6px;">
						<b>講師</b>
						<p>1-1. 范信賢老師
							<br>1-2. 黃國珍老師
							<br>2-1. 陳思玎校長
							<br>2-2. 葉丙成老師
						</p>
					</td>
				</tr>
			</table>
		</template>
		<br>
		<h3>．延伸實作坊：DFC 兒童實驗營隊介紹</h3>
		<p>DFC 臺灣相信透過 DFC 四步驟作為課程設計的鷹架，能培養孩子面對未來的綜合能力（同理心、創造力、實踐力、自信心）。此次營隊預計招募30名小學四～六年級的小學生，分為6組，每組由2名參與計畫之師培生擔任輔導員，各自使用獨立設計之教案，帶領參與學生用一天的時間快速體驗 DFC 四步驟（感受、想像、實踐、分享）流程，並在過程中反覆練習各個教案所設定的課程目標，包含以人為本、傾向行動、創意自信、享受過程、團隊合作等。</p>
		<Siema :data="list" :options="{ loop: true  }" indicator>
			<template v-slot="{slide}">
				<img :src="slide" width="100%">
			</template>
		</Siema>
		<br>
		<h3>．第一屆 DFC 校園大使計畫，完成計畫培訓之師培生共12位，分別是</h3>
		<p>教育創新與評鑑碩士班 —— 王雲安、曾桔玲、陳冠伶、鄭惟葶
			<br>教育學系 —— 朱宇捷、林姿儀、張旻芝、張嘉汶、蕭心怡、蕭正宜
			<br>教育經營與管理學系 —— 蕭籽芸
			<br>兒童英語教育學系 —— 忻亮均
		</p>
		<img src="../../assets/teacher-list/img1.jpg" width="100%">
	</section> -->
</template>

<script>
import Siema from "../Siema.vue";
export default {
	components: {
		Siema,
	},
	data() {
		return {
			list: [
				require("@/assets/teacher-list/banner1.jpg"),
				require("@/assets/teacher-list/banner2.jpg"),
				require("@/assets/teacher-list/banner3.jpg"),
				require("@/assets/teacher-list/banner4.jpg"),
				require("@/assets/teacher-list/banner5.jpg"),
				require("@/assets/teacher-list/banner6.jpg"),
				require("@/assets/teacher-list/banner7.jpg"),
				require("@/assets/teacher-list/banner8.jpg"),
			],
		};
	},
};
</script>

<style lang="scss" scoped>
h2 {
	@include left-line-title;
	font-size: 1.25em;
	color: $red;
}

table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 1px;
	background: $red;
	border-radius: 7px;
	th,
	td {
		padding: 10px;
	}
	td {
		background: $background;
	}
	th {
		background: #fcddd3;
	}
	b {
		color: $red;
	}
}
</style>
