<template>
	<div class="tabs" :class="colorType">
		<div class="row-grid" align-x="center">
			<el-button v-for="(item, i) in data" class="" :class="{active: currentIndex == i}" :key="i" @click="handleClick(i)">
				<div class="row" align-y="center" align-x="center">
					<img v-if="item.icon_active && currentIndex == i" :src="item.icon_active | srcFilter" class="icon-active">
					<img v-else :src="item.icon | srcFilter" class="icon">
					{{item.title}}
				</div>
			</el-button>
		</div>
		<br>
		<br>
		<ArticleBlock ref="scale-anime" picture-position="right">
			<template slot="paragraph">
				<div>
					<h2 v-if="$root.rwd < 2">{{currentData.title}}</h2>
					<p>{{currentData.content}}</p>
				</div>
				<template v-if="currentData.button">
					<DfcButton :to="currentData.button.to" :href="currentData.button.href" target="_blank" :theme="colorType" round arrow>{{currentData.button.text}}</DfcButton>
				</template>
			</template>
			<img slot="picture" :src="currentData.img | srcFilter">
		</ArticleBlock>
	</div>
</template>

<script>
import { scaleAnime } from "../../libs/anime";
import ArticleBlock from "../ArticleBlock.vue";
export default {
	props: ["data", "colorType"],
	components: {
		ArticleBlock
	},
	data() {
		return {
			currentIndex: 0,
		}
	},
	computed: {
		currentData() {
			return this.data[this.currentIndex] || new Object;
		}
	},
	filters: {
		srcFilter(name) {
			return name && require("@/assets/service/" + name) || "";
		}
	},
	methods: {
		handleClick(i) {
			this.currentIndex = i;
			scaleAnime(this.$refs["scale-anime"].$el);
		}
	}
}
</script>

<style lang="scss" scoped>
img[class*="icon"] {
	height: 2em;
	margin-right: 5px;
}
.el-button {
	background: transparent;
	flex: 1;
	margin: 0.5em;
	font-size: 1em;
	white-space: nowrap;
	@media screen and (max-width: 768px) {
		min-width: calc(50% - 1em);
	}
	@media screen and (max-width: 425px) {
		font-size: 0.9em;
	}
	@media screen and (max-width: 320px) {
		font-size: 0.8em;
	}
}
.darkblue {
	.el-button {
		border-color: $darkblue;
		color: $darkblue;
		@mixin active-blue {
			border-color: #fff;
			background: #fff;
		}
		@media (hover: hover) {
			&:hover {
				@include active-blue;
				opacity: 0.8;
			}
		}
		&.active {
			@include active-blue;
		}
	}
}
.red {
	.el-button {
		border-color: $red;
		color: $red;
		@mixin active-red {
			color: #fff;
			background: $red;
		}
		@media (hover: hover) {
			&:hover {
				@include active-red;
				opacity: 0.8;
			}
		}
		&.active {
			@include active-red;
		}
	}
}
p {
	margin: 2em 0;
	line-height: 1.8;
}
h2 {
	@include left-line-title;
	+ p {
		margin-top: 0;
	}
}
</style>