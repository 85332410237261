var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContentBlock',{attrs:{"clip":"","bg-color":"white1"}},[_c('h1',{staticStyle:{"color":"#122e26","text-align":"center","margin-bottom":"60px"}},[_vm._v(" 我們想要與你一起改善這樣的教育現況...... ")]),_vm._l((_vm.list),function(slide){return _c('div',{staticClass:"lg",staticStyle:{"padding":"15px 0px"},attrs:{"gutter":"10"}},[_c('Ratio',{staticStyle:{"max-width":"120px","width":"100%","margin":"0 50px"},attrs:{"ratio":"square"}},[_c('div',{staticClass:"center",staticStyle:{"width":"100%","height":"100%"}},[_c('img',{attrs:{"src":slide.img,"width":"150"}})])]),_c('div',[_c('span',{staticStyle:{"padding":"10px 0","font-size":"18px"},style:({
					color: slide.color,
					borderBottom: `2px solid ${slide.color}`,
				})},[_vm._v(_vm._s(slide.title))]),_c('p',{staticStyle:{"margin-top":"30px","font-size":"15px"}},[_vm._v(" "+_vm._s(slide.content)+" ")])])],1)}),_c('div',{staticClass:"sm"},[_c('Siema',{attrs:{"data":_vm.list,"options":{ loop: true, perPage: _vm.perPage },"control":_vm.$root.rwd > 1},scopedSlots:_vm._u([{key:"default",fn:function({ slide }){return [_c('div',{staticClass:"col",staticStyle:{"padding":"0 15px"},attrs:{"gutter":"10"}},[_c('Ratio',{staticStyle:{"max-width":"200px","width":"100%","margin":"auto"},attrs:{"ratio":"square"}},[_c('div',{staticClass:"center",staticStyle:{"width":"100%","height":"100%"}},[_c('img',{attrs:{"src":slide.img,"width":"150"}})])]),_c('p',{staticStyle:{"padding":"10px","text-align":"center"},style:({
							color: slide.color,
							borderTop: `2px solid ${slide.color}`,
							borderBottom: `2px solid ${slide.color}`,
						})},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('p',[_vm._v(_vm._s(slide.content))])],1)]}}])})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }