<template>
	<div class="card row-grid" align-y="center" align-x="center" gutter-x="25" gutter-y="20" style="margin-bottom:1em; font-size:smaller;">
		<img :src="data.img" width="167">
		<div class="rwd-m-8">
			<div class="col" gutter="10" style="padding:1em; border-radius:10px; background:rgba(255,255,255,0.5);">
				<pre>{{data.description}}</pre>
				<pre style="text-align:right;">——&nbsp;{{data.who}}</pre>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["data"],
}
</script>