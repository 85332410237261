<template>
	<div class="col">
		<div id="register-btn" class="row" align-x="right">
			<div />
			<a href="https://www.dfcchallenge.org" target="_blank" class="center">
				<small>DFC 挑戰平台登入/註冊</small>
			</a>
		</div>
		<div
			class="row"
			align-x="between"
			align-y="center"
			style="padding: 0 1em 1em;"
		>
			<router-link to="/">
				<img id="logo" src="../../assets/logo.png" style="height:3.25em;" />
			</router-link>
			<div class="row" align-y="center" gutter="25">
				<template v-for="(item, i) in list">
					<span v-if="i" :key="`|${i}`" style="color:#979797;">|</span>
					<template v-if="item.length">
						<el-dropdown :key="i">
							<anchor :to="item[0]" :class="routerLinkActive(item)" :key="i">
								{{ item[0].label }}
								<i class="el-icon-caret-bottom el-icon--right" />
							</anchor>
							<el-dropdown-menu slot="dropdown">
								<template v-for="(subitem, j) in item">
									<el-dropdown-item v-if="j > 0" :key="j">
										<anchor :to="subitem" :key="i" :exact="subitem.exact">
											{{ subitem.label }}
										</anchor>
									</el-dropdown-item>
								</template>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<template v-else>
						<anchor :to="item" :key="i" :exact="item.exact">
							{{ item.label }}
						</anchor>
					</template>
				</template>
				<DfcButton
					theme="red"
					small
					href="https://dfctaiwan.neticrm.tw/civicrm/contribute/transact?reset=1&id=5&utm_source=officailwebsite&utm_medium=cpc&utm_campaign=2023donation"
					target="_blank"
					style="font-size:1em;"
				>
					捐款支持
				</DfcButton>
			</div>
			<div />
		</div>
	</div>
</template>

<script>
import Anchor from "./Anchor.vue";
export default {
	components: {
		Anchor,
	},
	props: ["list"],
	methods: {
		routerLinkActive(arr) {
			return arr.some(({ path }) => this.$route.path.includes(path))
				? "router-link-active"
				: "";
		},
	},
};
</script>

<style lang="scss" scoped>
.el-dropdown {
	color: inherit;
	font-size: inherit;
}
.el-dropdown-menu__item {
	line-height: 1.5;
	padding: 0;
	a {
		padding: 5px 20px;
		width: 100%;
		display: block;
	}
}
</style>
