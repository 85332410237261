<template>
	<section id="media-center">
		<el-button type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
		<br>
		<br>
		<el-tabs v-model="currentTab" type="border-card">
			<template v-for="(label, category, i) in _categories">
				<el-tab-pane :label="label" :name="category" :key="i" v-loading="!tabs[category].length && loading">
					<el-table :data="tabs[category]" stripe>
						<el-table-column type="index" width="50" />
						<el-table-column label="日期" prop="date">
							<template slot-scope="{row}">{{row.date | dateFilter}}</template>
						</el-table-column>
						<el-table-column label="標題" prop="title" />
						<el-table-column label="出處" prop="publicate_by" />
						<el-table-column>
							<template slot-scope="{row}">
								<el-button @click="handleEdit(row)" type="primary" plain icon="el-icon-edit" size="mini">編輯</el-button>
								<el-button @click="handleDelete(row)" type="danger" plain icon="el-icon-delete" size="mini" :loading="loading">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<br>
					<div class="center" v-if="cursor[category]">
						<el-button type="text" icon="el-icon-arrow-down" @click="handleGetList(category)" :loading="loading">載入更多</el-button>
					</div>
				</el-tab-pane>
			</template>
		</el-tabs>
		<el-dialog :visible="dialogVisible" width="500px" @close="handleClose()">
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="70px">
				<el-form-item prop="category" label="分類">
					<el-select v-model="ruleForm.category">
						<el-option v-for="(label, category, i) in _categories" :key="i" :value="category" :label="label" />
					</el-select>
				</el-form-item>
				<el-form-item prop="date" label="日期">
					<el-date-picker v-model="ruleForm.date" value-format="timestamp" />
				</el-form-item>
				<el-form-item prop="title" label="標題">
					<el-input v-model="ruleForm.title" type="textarea" :autosize="{minRows:2}" />
				</el-form-item>
				<el-form-item prop="publicate_by" label="出處">
					<el-input v-model="ruleForm.publicate_by" />
				</el-form-item>
				<el-form-item prop="link" label="外部連結">
					<el-input v-model="ruleForm.link" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="handleClose()">取 消</el-button>
				<el-button @click="handleSubmit()" type="primary" :loading="loading">確 定</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	data() {
		return {
			loading: false,
			dialogVisible: false,
			ruleForm: new Object,
			currentTab: "",
			rules: {
				category: { required: true, message: "必填", trigger: "blur" },
				date: { required: true, message: "必填", trigger: "blur" },
				title: { required: true, message: "必填", trigger: "blur" },
				publicate_by: { required: true, message: "必填", trigger: "blur" },
			},
			cursor: {
				team_concept: 1,
				teaching_learning: 1,
				direct_activity: 1,
			},
		}
	},
	computed: {
		...mapState("media-center", [
			"_categories",
			"_team_concept_list",
			"_teaching_learning_list",
			"_direct_activity_list",
		]),
		exist() {
			return !!this.ruleForm.id;
		},
		tabs() {
			return {
				team_concept: this._team_concept_list,
				teaching_learning: this._teaching_learning_list,
				direct_activity: this._direct_activity_list,
			}
		},
	},
	watch: {
		async currentTab(val) {
			this.ruleForm.category = val;
			if (!this[`_${val}_list`].length) {
				this.loading = true;
				await this.handleGetList(val);
				this.loading = false;
			};
		},
	},
	methods: {
		...mapActions("media-center", [
			"_createData",
			"_updateData",
			"_deleteDate",
			"_getListByCategory",
		]),
		handleClose() {
			this.ruleForm = {
				category: this.currentTab
			};
			this.$refs["ruleForm"].clearValidate();
			this.dialogVisible = false;
		},
		handleSubmit() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.loading = true;
					if (this.exist) await this._updateData(this.ruleForm);
					else await this._createData(this.ruleForm);
					this.loading = false;
					this.handleClose();
				}
			})
		},
		async handleGetList(category) {
			this.loading = true;
			const { cursor } = await this._getListByCategory({
				category,
				cursor: this.cursor[category],
			});
			this.cursor[category] = cursor;
			this.loading = false;
		},
		handleEdit(row) {
			this.ruleForm = { ...row };
			this.dialogVisible = true;
		},
		handleDelete({ id, title, category }) {
			this.$confirm(`確定要刪除「${title}」？`, { type: "warning" })
				.then(async () => {
					this.loading = true;
					await this._deleteDate({ id, category });
					const { cursor } = await this._getListByCategory({
						category,
						cursor: this.cursor[category],
					});
					this.cursor[category] = cursor;
					this.loading = false;
				});
		}
	},
	async created() {
		this.currentTab = Object.keys(this._categories)[0];
	},
}
</script>