import { render, staticRenderFns } from "./Anchor.vue?vue&type=template&id=7acea85b&scoped=true"
import script from "./Anchor.vue?vue&type=script&lang=js"
export * from "./Anchor.vue?vue&type=script&lang=js"
import style0 from "./Anchor.vue?vue&type=style&index=0&id=7acea85b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7acea85b",
  null
  
)

export default component.exports