import firebase from "../../database/libs/firebase";
import _ from "lodash";
import Story from "../../database/models/story";

const db = firebase.firestore();

const converter = {
	toFirestore: function (data) {
		if (!data.create_time) data.create_time = Date.now();
		data.update_time = Date.now();
		return _.omit(data, ["id"]);
	},
	fromFirestore: function (snapshot, options) {
		const data = snapshot.data(options);
		return {
			...data,
			id: snapshot.id,
		}
	}
}

function mapSearchQuery(ref, queries = []) {
	const q = queries.shift();
	const result = q && ref.where(q[0], q[1], q[2]) || ref;
	if (queries.length > 0) return mapSearchQuery(result, queries);
	return result;
}

export default {
	namespaced: true,
	state: {
		_cursor: null,
		_stories: new Array,
		_story: Story(),
	},
	actions: {
		_createStory({ state }, data = Story()) {
			return new Promise(async (resolve, reject) => {
				const { id, year } = data;
				const _doc = _ref
					.collection(`stories-${year}`)
					.doc(id);
				db.runTransaction(transaction => {
					return transaction
						.get(_doc)
						.then(snapshot => {
							if (snapshot.exists) throw new Error("此故事編號已存在")
							transaction.set(_doc.withConverter(converter), data);
							state._stories.unshift(data);
							state._story = data;
							return id;
						});
				})
					.then(resolve)
					.catch(reject);
			});
		},
		_getStories({ state }, { year }) {
			return new Promise(async (resolve, reject) => {
				const _ref = db.collection(`stories-${year}`);
				_ref
					.orderBy("account")
					.withConverter(converter)
					.get()
					.then(snapshots => {
						state._stories = snapshots.docs.map(doc => doc.data());
						resolve();
					})
					.catch(reject);
			});
		},
		_getStory({ state }, { year, id }) {
			return new Promise(async (resolve, reject) => {
				try {
					const _ref = db.collection(`stories-${year}`);
					let data = _.find(state._stories, ["id", id]);
					if (!data) {
						const doc = await _ref
							.doc(id)
							.withConverter(converter)
							.get();
						if (doc.exists) data = doc.data();
						else data = Story();
					}
					state._story = data;
					resolve(data);
				} catch (error) {
					reject(error)
				}
			});
		},
		_updateStory({ state }, { params, data }) {
			return new Promise(async (resolve, reject) => {
				try {
					const { year, id } = params;
					const _ref = db.collection(`stories-${year}`);
					await _ref
						.doc(id)
						.withConverter(converter)
						.update(data);
					_.assign(state._story, data);
					_.assign(_.find(state._stories, ["id", id]), Story(data));
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},
		// 獲取「分頁＆條件」列表
		_getListByQueriesWithPagination({ state }, { limit = 12, params, queries = new Array } = new Object) {
			return new Promise(async (resolve, reject) => {
				const { year } = params;
				const _ref = db.collection(`stories-${year}`);
				mapSearchQuery(_ref, Array.from(queries))
					.orderBy("account")
					// .orderBy("account", "desc")
					.withConverter(converter)
					.limit(limit)
					.get()
					.then(snapshots => {
						state._stories = snapshots.docs.map(doc => doc.data());
						if (snapshots.size < limit) state._cursor = null;
						else state._cursor = snapshots.docs[snapshots.size - 1];
						resolve(state._stories);
					})
					.catch(reject);
			});
		},
		// 載入更多「分頁＆條件」列表
		_getMoreByQueriesWithPagination({ state }, { limit = 10, params, queries = new Array } = new Object) {
			return new Promise(async (resolve, reject) => {
				const { year } = params;
				const _ref = db.collection(`stories-${year}`);
				mapSearchQuery(_ref, Array.from(queries))
					.orderBy("account")
					// .orderBy("account", "desc")
					.startAfter(state._cursor)
					.withConverter(converter)
					.limit(limit)
					.get()
					.then(snapshots => {
						if (state._cursor && state._cursor.exists) {
							snapshots.forEach(doc => {
								state._stories.push(doc.data());
							});
						}
						if (snapshots.size < limit) state._cursor = null;
						else state._cursor = snapshots.docs[snapshots.size - 1];
						resolve(state._stories);
					})
					.catch(reject);
			});
		},
	},
}