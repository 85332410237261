<template>
	<section id="news" v-loading="loading">
		<TopBanner>
			<img src="../../assets/news/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/news/title.png" class="page-title" width="450">

		<comming-soon v-if="!_newsList.length" />

		<template v-else>
			<ContentBlock>
				<div class="row-grid" align-y="top" gutter-x="20" gutter-y="20">
					<div v-for="(item, i) in _newsList" :key="i" class="rwd-l-12 rwd-s-6">
						<article class="row-grid" align-y="top">
							<template v-if="item.fileList && item.fileList[0]">
								<img class="rwd-l-4" :src="item.fileList[0].url" :alt="item.fileList[0].name">
							</template>
							<div class="col" align-y="between">
								<div>
									<small color="blue1">{{item.date | dateFilter}}</small>
									<h1>{{item.title}}</h1>
									<pre>{{item.brief}}</pre>
								</div>
								<DfcButton theme="red" round arrow align-self="right" @click="handleSelect(item)">閱讀全文</DfcButton>
							</div>
						</article>
					</div>
				</div>
				<br>
				<br>
				<div class="center" v-if="cursor">
					<DfcButton theme="red" round small @click="handleSearch()">載入更多</DfcButton>
				</div>
			</ContentBlock>

			<el-dialog :visible.sync="dialogVisible" :width="dialogWidth">
				<Ratio ratio="70%">
					<template v-if="current.fileList && current.fileList[0]">
						<img :src="current.fileList[0].url" :alt="current.fileList[0].name" width="100%">
					</template>
				</Ratio>
				<br>
				<p color="blue1">{{current.date | dateFilter}}</p>
				<hr>
				<br>
				<div v-html="current.content" />
			</el-dialog>
		</template>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CommingSoon from "../../components/CommingSoon.vue";
export default {
	components: {
		CommingSoon,
	},
	data() {
		return {
			loading: true,
			monthPicked: "",
			dialogVisible: false,
			current: new Object,
			cursor: 1,
		}
	},
	computed: {
		...mapState("news", [
			"_monthPickerOptions",
			"_newsList",
		]),
		dialogWidth() {
			return ["", "80%", "100%"][this.$root.rwd - 1];
		}
	},
	filters: {
		statusTextFilter(val) {
			return val ? "草稿" : "發布";
		},
	},
	methods: {
		...mapActions("news", [
			"_getListByPublishedStatus",
		]),
		async handleSearch() {
			this.loading = true;
			const { cursor } = await this._getListByPublishedStatus({
				cursor: this.cursor,
			});
			this.cursor = cursor;
			this.loading = false;
		},
		handleSelect(data) {
			this.current = data;
			this.dialogVisible = true;
		}
	},
	async created() {
		await this.handleSearch();
	},
}
</script>

<style lang="scss" scoped>
article {
	background: #fff;
	padding: 1em;

	h1 {
		margin: 0;
		font-size: 1.5em;
	}

	pre {
		color: #767676;
	}

	img {
		background: $background;
		+ .col {
			flex: 1;
			padding: 1em 0;
			@media screen and (min-width: 769px) {
				padding: 0 1em;
			}
		}
	}
}
</style>