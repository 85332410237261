<template>
	<section id="privacy" bg-color="white2">
		<TopBanner>
			<img src="../../assets/privacy/banner.jpg" width="100%">
		</TopBanner>
		<img src="../../assets/privacy/title.png" class="page-title" width="600">
		<ContentBlock>
			<h1>認知與接受條款</h1>
			<p>本網站係由社團法人臺灣童心創意行動協會 (下稱「DFC 臺灣」) 依據本服務條款 (下稱「本條款」) 提供本網站，包含但不限於：（<a href="/">https://tycaa.dfctaiwan.org</a>）服務 (下稱「本服務」)。
				<br>當您造訪本服務或註冊為本網站之會員時，即表示您已閱讀、瞭解並同意接受本條款之所有內容。
				<br>本網站有權於任何時間修改或變更本條款之內容，建議您隨時注意該修改或變更。
				<br>若您於任何修改或變更後繼續使用本網站，表示您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意本條款的內容，或者您所屬的國家或地域排除本條款內容之全部或一部份時，您應立即停止使用本服務或立即取消會員註冊並停止使用本網站。
				<br>若您為未滿二十歲，除應符合上述規定外，並應於您的家長（或監護人）閱讀、瞭解並同意本條款之所有內容及其後修改變更後，方得使用或繼續使用本網站。當您使用或繼續使用本網站時，即表示您及您的家長（或監護人）已閱讀、瞭解並同意接受本條款之所有內容及其後修改變更。
			</p>
			<br>
			<h1>本網站個人資料之蒐集、處理及利用與告知係由 DFC 臺灣團隊所經營管理，為確保造訪本服務者或會員之個人資料、隱私權益之保護，若將使用造訪本服務者或會員之個人資料，謹依個人資料保護法規定告知以下事項：</h1>
			<ol upper-alpha>
				<li>非公務機關名稱：社團法人臺灣童心創意行動協會</li>
				<li>蒐集之目的：學術研究、非營利組織業務、調查、統計與研究分析</li>
				<li>蒐集之個人資料類別： 識別類、特徵類、社會情況、教育、考選、技術或其他專業</li>
				<li>利用期間、地區、對象及方式：
					<ol decimal-with-quotes>
						<li>期間：DFC 臺灣營運期間。</li>
						<li>地區：造訪本網站者或會員之個人資料將用於臺灣地區及符合主管機關國際傳輸規定之其他地區。</li>
						<li>利用對象及方式：DFC 臺灣之會員管理、客戶管理，及包括但不限於下列之蒐集、處理及利用：</li>
					</ol>
				</li>
				<li>辨識身份：您提供之辨識個人資料(如：電子郵件住址、身分證字號、電話號碼等)，將使用於辨別您的身分並提供您會員基本服務。 會員依個人資料保護法得對 DFC 臺灣行使以下權利：
					<ol decimal-with-quotes>
						<li>查詢或請求閱覽。</li>
						<li>請求製給複製本。</li>
						<li>請求補充或更正。</li>
						<li>請求停止蒐集、處理或利用。</li>
						<li>請求刪除。</li>
					</ol>
					會員如欲行使上述權利，可來電洽詢 DFC 臺灣客服進行申請。
				</li>
				<li>如會員拒絕提供會員表之必要填載資料，將可能導致無法享受完整服務或完全無法使用該項服務。</li>
			</ol>
			<br>
			<h1>與第三人網站的連結</h1>
			<p>本網站可能會使您連結至第三人經營的網站，但不表示本網站與該第三人有任何關係。第三人經營的網站均由其自行負責，不屬本網站控制及負責範圍之內。</p>
			<br>
			<h1>您的註冊義務</h1>
			<p>為了能使用本服務，您同意以下事項：
				<br>依本服務註冊表之提示提供您本人正確、最新及完整的資料，維持並更新您個人資料，確保其為正確、最新及完整。
				<br>若您提供任何錯誤或不實的資料，本網站有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部份。
			</p>
			<br>
			<h1>本網站隱私權政策</h1>
			<p>本站為了提供您最佳的互動服務，將請您提供個人相關的資訊，其範圍如下：
			<ol decimal-with-quotes>
				<li>在您使用本站時，我們會記錄您的使用時間、瀏覽紀錄等，做為我們提升網站服務的參考依據。</li>
				<li>為提供精確的服務，我們會將蒐集的資料進行統計與分析，做為學術研究之用途，本站絕不會公開您的個人資料與使用紀錄。</li>
				<li>本站致力於保護您的個人資料與紀錄，只有經過授權的人員才能接觸您的個人資料。</li>
			</ol>
			</p>
			<br>
			<h1>使用者的守法義務及承諾</h1>
			<p>完成本服務的註冊程序之後，您將收到一組密碼及帳號。維持密碼及帳號的機密安全，是您的責任。
				<br>利用該密碼及帳號所進行的一切行動，您將負完全的責任。您並同意以下事項：
			<ol lower-alpha-with-quotes>
				<li>您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您將立即通知本網站，且</li>
				<li>每次連線完畢，均結束您的帳號使用。</li>
			</ol>
			</p>
			<br>
			<h1>使用者的守法義務及承諾</h1>
			<p>您承諾絕不為任何非法目的或以任何非法方式使用本網站，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。
				<br>您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務或網站從事侵害他人權益或違法之行為，包括但不限於：
			<ol upper-alpha>
				<li>公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本網站上；</li>
				<li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；</li>
				<li>違反依法律或契約所應負之保密義務；</li>
				<li>冒用他人名義使用本服務；</li>
				<li>傳輸或散佈電腦病毒；</li>
				<li>從事不法交易行為或張貼虛假不實、引人犯罪之訊息；</li>
				<li>販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物；</li>
				<li>提供賭博資訊或以任何方式引誘他人參與賭博；</li>
				<li>其他本網站有正當理由認為不適當之行為。</li>
			</ol>
			</p>
			<br>
			<h1>系統中斷或故障</h1>
			<p>本服務有時可能會出現中斷或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本網站對於您因使用（或無法使用）本服務而造成的損害，不負任何賠償責任。</p>
			<br>
			<h1>資訊或建議</h1>
			<p>本網站對於您使用本服務或經由本網站連結之其他網站而取得之資訊或建議，不擔保其為完全正確無誤。
				<br>本網站對於本服務所提供之資訊或建議有權隨時修改或刪除。
				<br>本網站隨時會與其他公司、廠商等第三人（「內容提供者」）合作，由其提供本網站刊登，本網站於刊登時均將註明內容提供者。
				<br>基於尊重內容提供者之智慧財產權，本網站對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。
				<br>對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見。
			</p>
			<br>
			<h1>免責聲明</h1>
			<p>本網站對本服務不提供任何明示或默示的擔保，包含但不限於商業適售性、特定目的之適用性及未侵害他人權利。
				<br>本網站不保證以下事項：
			<ol lower-roman-with-quotes>
				<li>本服務將符合您的需求，</li>
				<li>本服務不受干擾、及時提供、安全可靠或免於出錯，</li>
				<li>由本服務之使用而取得之結果為正確或可靠。</li>
			</ol>
			您同意 DFC 臺灣以本網站提供服務已善盡您及會員個人資料之權益保障，且 DFC 臺灣僅提供註冊會員「DFC 臺灣團隊各式服務相關細節、DFC 團隊相關資訊、DFC 挑戰相關資源及 DFC 挑戰故事資料上傳、報名、管理」之服務，並無法完全擔保本網站不受任何駭客之入侵，故您同意若您個人資料因使用本網站而有外洩情形，其為駭客入侵或外部架設網站廠商之責任，您同意應向駭客或外部架設網站廠商主張權益，不得向 DFC 臺灣為任何請求或主張，絕無異議。
			<br>是否經由本服務之使用下載或取得任何資料應由您自行考量且自負風險，因前開任何資料之下載而導致您電腦系統之任何損壞或資料流失，您應負完全責任。
			<br>您自本網站或經由本服務取得之建議和資訊，無論其為書面或口頭，均不構成本服務之保證。
			</p>
			<br>
			<h1>智慧財產權的保護</h1>
			<p>本網站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本網站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。
				<br>任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得本網站或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本網站負損害賠償責任 （包括但不限於訴訟費用及律師費用等）。
			</p>
			<br>
			<h1>您對本網站之授權</h1>
			<p>若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本網站。任何資料一經您上載、傳送、輸入或提供至本網站時，表示您已允許本網站可以基於公益之目的，無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，您對此絕無異議。您並應保證本網站使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本網站負損害賠償責任（包括但不限於訴訟費用及律師費用等）。</p>
			<br>
			<h1>拒絕或終止您的使用</h1>
			<p>您同意本網站得基於其自行之考量，因任何理由，包含但不限於缺乏使用，或本網站認為您已經違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。本網站亦得依其自行之考量，於通知或未通知之情形下，隨時終止本服務或其任何部分。您同意依本服務條款任何規定提供之本服務，無需進行事先通知即得終止，您承認並同意，本網站得立即關閉或刪除您的帳號及您帳號中所有相關資料及檔案，及停止本服務之使用。此外，您同意若本服務之使用被終止，本網站對您或任何第三人均不負任何賠償責任。</p>
			<br>
			<h1>準據法與管轄法院</h1>
			<p>本條款之解釋與適用，以及與本條款有關的爭議，均應依照中華民國法律予以處理，並以臺灣臺北地方法院為第一審管轄法院。</p>
		</ContentBlock>
	</section>
</template>

<script>
export default {
	data() {
		return {}
	}
}
</script>

<style lang="scss" scoped>#privacy {
	line-height: 2;
	h1 {
		font-size: 1em;
		color: $red;
		margin: 0;
		display: list-item;
		list-style-type: decimal;
		list-style-position: inside;
	}
	a {
		text-decoration: underline;
		color: $blue;
	}
	ol {
		counter-reset: num;
		&[decimal-with-quotes],
		&[lower-roman-with-quotes],
		&[lower-alpha-with-quotes] {
			list-style: none;
			padding-inline-start: 0;
			li {
				display: flex;
				align-items: baseline;
				counter-increment: num;
				&::before {
					display: block;
					width: 2em;
					margin-right: 5px;
					text-align: right;
					white-space: nowrap;
					flex-shrink: 0;
				}
			}
		}
		&[decimal-with-quotes] {
			li::before {
				content: "(" counter(num) ")";
			}
		}
		&[lower-alpha-with-quotes] {
			li::before {
				content: "(" counter(num, lower-alpha) ")";
			}
		}
		&[lower-roman-with-quotes] {
			li::before {
				content: "(" counter(num, lower-roman) ")";
			}
		}
		&[upper-alpha] {
			list-style-type: upper-alpha;
		}
	}
}
</style>