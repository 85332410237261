<template>
	<section>
		<h1 style="margin:1em; text-align:center;">DFC 臺灣歷年成果報告書</h1>
		<el-form ref="ruleForm" :model="{list}" v-loading="loading">
			<el-form-item>
				<div class="row" align-x="between">
					<el-button icon="el-icon-plus" type="primary" round @click="dialogVisible = true;">新增</el-button>
					<div class="row">
						<el-button @click="handleCancel()" type="info" :disabled="!statusChanged">回 復</el-button>
						<el-button @click="handleSubmit()" type="success" :disabled="!statusChanged">儲 存</el-button>
					</div>
				</div>
			</el-form-item>
			<vue-draggable v-model="list" v-bind="{animation:200}" @start="ondrag = true" @end="ondrag = false" @change="statusChanged = true">
				<transition-group type="transition" :name="!ondrag ? 'flip-list' : null">
					<template v-for="(item, i) in list">
						<el-form-item :prop="`list[${i}].documentURL`" :rules="{required:true,message:'請填入文件網址'}" :class="{ondrag}" :key="i">
							<el-tooltip effect="dark" content="拖拉選項可調整順序" placement="right">
								<el-card>
									<div class="row" gutter="20">
										<el-image style="width:120px;" :src="item.thumbURL" :preview-src-list="[item.thumbURL]" />
										<div class="col" align-y="between" style="flex:1;">
											<small><span style="color:#409EFF;">點擊圖片預覽</span>（滾輪可以縮放）</small>
											<el-input v-model="item.documentURL" placeholder="文件網址" @keyup.native="statusChanged = true" />
										</div>
										<el-button align-self="top" type="danger" icon="el-icon-delete" circle @click="handleDelete(i)" />
									</div>
								</el-card>
							</el-tooltip>
						</el-form-item>
					</template>
				</transition-group>
			</vue-draggable>
		</el-form>

		<!-- Uploader -->
		<el-dialog :visible.sync="dialogVisible" append-to-body>
			<template slot="title">*上傳縮圖</template>
			<div class="center">
				<uploader media-folder="about/歷屆成果報告書" @change="uploadImageSuccess" @close="dialogVisible = false" />
			</div>
		</el-dialog>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Uploader from "../../components/Uploader.vue";
import preventLeave from "../../mixin/prevent-leave.js";
import VueDraggable from "vuedraggable";
export default {
	mixins: [preventLeave],
	components: {
		Uploader,
		VueDraggable,
	},
	data() {
		return {
			loading: true,
			statusChanged: false,
			list: new Array,
			dialogVisible: false,
			ondrag: false,
		}
	},
	computed: {
		...mapState("about", [
			"_achievementReport",
		]),
	},
	methods: {
		...mapActions("about", [
			"_onDataChange",
			"_updateData",
		]),
		uploadImageSuccess(fileList) {
			this.statusChanged = true;
			this.dialogVisible = false;
			fileList.forEach(item => {
				this.list.unshift({
					thumbURL: item.url,
					documentURL: "",
				});
			});
		},
		handleSubmit() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.loading = true;
					try {
						await this._updateData({
							doc: "achievement-report",
							data: {
								list: this.list
							}
						});
						this.statusChanged = false;
					} catch (error) {
						this.$message.error(error.message);
					}
					this.loading = false;
				}
			});
		},
		handleDelete(i) {
			this.list.splice(i, 1);
			this.statusChanged = true;
		},
		handleCancel() {
			this.statusChanged = false;
			this.list = Array.from(this._achievementReport.list);
		},
	},
	created() {
		this._onDataChange("achievement-report")
			.then(val => {
				if (val) this.list = val.list;
				if (this.loading) this.loading = false;
			});
	},
}
</script>

<style lang="scss" scoped>
.el-form-item {
	&:hover {
		cursor: grab;
	}
	&.ondrag {
		cursor: grabbing;
	}
}
.flip-list-move {
	transition: transform 0.5s;
}
</style>